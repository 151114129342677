<template>
    <div>
        <div v-show="addCountryForm" class="uidForm">
            <vue-feather  @click="addCountryForm = false" class="xCircle" type="x-circle"></vue-feather>
            <Form @submit="onSubmit" :key="componentKey" :initial-values="initialData"
                    :validation-schema="schemas" keep-values v-slot="{ handleSubmit, values }">
                <div class="row align-center">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="Country">Country <span class="required"></span></label>
                            <Field name="country_id" id="Country" @change="callForUIDType(values.country_id)"
                                :disabled="countryOptions.length == 0" class="form-control" label="Select Country" as="select">
                                <option :value="null" disabled selected>Select Country</option>
                                <option v-for="item in countryOptions" :value="item.country_id">{{ item.country }}</option>
                            </Field>
                            <ErrorMessage class="error_alert" name="country_id" />
                            <div v-if="isUIDField" class="d-flex align-items-center">
                                <span class="m-1">Waiting for UID Input</span>
                                <vue-feather style="width:17px" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 my-auto" v-show="Object.keys(uidTypeField).length != 0">
                        <div class="form-group" >
                            <label for="title">{{ uidTypeField.description }}<span class="required"></span></label>
                            <Field type="text" class="form-control" name="uid_number" />
                            <ErrorMessage class="error_alert" name="uid_number" />
                        </div>
                    </div>
                    <!-- <div class="col-md-4 my-auto">
                        <div class="form-group">
                            <label for="title">Email</label>
                            <Field type="text" class="form-control" name="email" />
                            <ErrorMessage class="error_alert" name="email" />
                        </div>
                    </div>
                    <div class="col-md-4 my-auto">
                        <div class="form-group">
                            <label for="title">Phone</label>
                            <Field type="text" class="form-control" name="phone_no" />
                            <ErrorMessage class="error_alert" name="phone_no" />
                        </div>
                    </div> -->
                    <div class="col-md-4 my-auto">
                        <div class="form-group">
                            <label for="title">Alt. Phone</label>
                            <Field type="text" class="form-control" name="alternet_phone_no" />
                            <ErrorMessage class="error_alert" name="alternet_phone_no" />
                        </div>
                    </div>
                    <!-- <div class="col-md-4 my-auto" v-if="Object.keys(uidTypeField).length != 0">
                        <button :disabled="isLoading" @click="UpdateUID()" type="submit" class="btn btn-primary mt-3">
                            <span v-if="isLoading"
                                class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                            Save
                        </button>
                    </div> -->
                    <div class="col-md-4 my-auto">
                        <div class="action_btn mb-2">
                            <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                <span v-if="isLoading"
                                    class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                {{ isUpdate?'Update':'Save'}}
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
            <div class="row vl-parent">
                <loading v-model:active="isUIDLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
                <div class="col-md-12">
                    <div class="panel-body p-0">
                    <div class="d-flex justify-content-between align-items-center">
                        <a href="javascript:void(0);" @click="get_member_uid($route.params.id)">
                            <vue-feather type="rotate-cw" size="16"></vue-feather>
                        </a>
                        <span>
                            <button type="button" v-if="$can('uid.create') && !addCountryForm" class="btn btn-primary mb-2" @click="addCountryFunction()">Add Country</button>
                        </span>
                    </div>
                        <div class="table-responsive">
                            <table role="table" aria-busy="false" aria-colcount="5" class="table table-bordered">
                                <thead role="rowgroup">
                                    <tr role="row">
                                        <th>#</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Alt Phone</th>
                                        <th>Country Name</th>
                                        <th>UID Code</th>
                                        <th>UID Number</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup" v-if="member_uid_data.length != 0">
                                    <tr v-for="(item, index) in member_uid_data" :key="item.id" :class="(item.is_register == 'y')?'bg-light':''">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.email?item.email:'N/A' }}</td>
                                        <td>{{ item.phone_no?item.phone_no:'N/A' }}</td>
                                        <td>{{ item.alternet_phone_no?item.alternet_phone_no:'N/A' }}</td>
                                        <td>{{ item.country?item.country:'N/A' }}</td>
                                        <td>{{ item.uid_code?item.uid_code:'N/A' }}</td>
                                        <td>{{ item.uid_number?item.uid_number:'N/A' }}</td>
                                        <td class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <router-link v-if="$can('member.location-list')" :to="{name:'member-multiple-locations', query:{'country':item.country,'country_id':item.country_id,'member_id':item.member_id }}" title="Add Location" class="delete_button m-2">
                                                    <vue-feather type="map-pin" size="18" stroke="orange"></vue-feather>
                                                </router-link>
                                                <a v-if="$can('uid.edit')" href="javascript:void(0);" title="Edit" class="delete_button m-2"
                                                    @click="editUID(item)">
                                                    <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                                                </a>
                                                <a href="javascript:void(0);" v-if="(item.is_register == 'n') && $can('uid.delete')" title="Delete" class="delete_button m-2"
                                                    @click="deleteUID(item.member_uid_id)">
                                                    <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr style="text-align: center;height:50px;">
                                        <td colspan="8">No data found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script setup>
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed, inject } from 'vue';
import { Form, Field, ErrorMessage, useResetForm } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
const store = useStore();
const countryOptions = ref([]);
const isCountry = ref(false);
const isLoading = ref(false);
const uidTypeField = ref({});
const isUIDField = ref(false);
const addCountryForm = ref(false);
const componentKey = ref(0);
const emitter = inject('emitter'); // Inject `emitter`
const param_id = ref('');
const member_uid_data = ref([]);
const isUpdate = ref(true);
const isUIDLoading = ref(false);
const initialData = ref({
    // email: null,
    // phone_no: null,
    country_id: null,
    alternet_phone_no: null,
    uid_number:null,
});
const schemas = yup.object().shape({
    // email: yup.string().nullable().email('This field must be a valid email address.'),
    // phone_no: yup.string().nullable().test('is_phone_valid', 'This field must be a valid phone number.', function(value, textContest) {
    //     if(value != null && value != ''){
    //         if (value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
    //             return true;
    //         } else{
    //             return false;
    //         }
    //     } else{
    //         return true
    //     }
    // }),
    alternet_phone_no: yup.string().nullable().test('is_alternet_phone_no', 'This field must be a valid phone number.', function(value, textContest) {
        if(value != null && value != ''){
            if (value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                return true;
            } else{
                return false;
            }
        } else{
            return true
        }
    }),
    country_id: yup.string().nullable().required('This field is required.'),
    uid_number: yup.string().nullable().required('This field is required.'),
});
onMounted(() => {
    const route = useRoute();
    const queryParam = route.params.id;
    if (queryParam !== undefined) {
        componentKey.value += 1;
        param_id.value = queryParam;
        get_member_uid(queryParam);
    };
    isCountry.value = true;
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        countryOptions.value = res.data.data;
    });
});
const get_member_uid = async (id) => {
    isUIDLoading.value = true;
    store.dispatch('getMemberUID', id).then((response) => {
        isUIDLoading.value = false;
        if (response.status == 200) {
            member_uid_data.value = response.data.data;
        }
    });
};

function callForUIDType(country_id) {
    isUIDField.value = true;
    store.dispatch(`getUIDListStore`, country_id).then((res) => {
        console.log(res.data.data)
        if(res.status == 200){
            if(res.data.data.length != 0){
                uidTypeField.value = res.data.data[0];
                uidTypeField.value['uid_id'] = res.data.data[0].id;
                // initialData.value = res.data.data[0];
                // componentKey.value += 1;
            }
        }
        isUIDField.value = false;
    });
}
function addCountryFunction(){
    isUpdate.value = false;
    addCountryForm.value = true;
    uidTypeField.value = {}
    resetForm();
}
const member_uid_id = ref('')
function editUID(row) {
    addCountryForm.value = true;
    initialData.value.country_id = row.country_id;
    callForUIDType(row.country_id);
    initialData.value.alternet_phone_no = row.alternet_phone_no;
    initialData.value.uid_number = row.uid_number;
    member_uid_id.value = row.member_uid_id;
    isUpdate.value = true;
    componentKey.value +=1;
}
function resetForm(){
    initialData.value.country_id = '';
    initialData.value.alternet_phone_no = '';
    initialData.value.uid_number = '';
    componentKey.value +=1;
}
function onSubmit(uidData) {
    console.log(uidData)
    if(isUpdate.value){
        uidData['member_uid_id'] = member_uid_id.value;
    } else{
        uidData['member_uid_id'] = '';
    }
    uidData['member_id'] = param_id.value;
    uidData['uid_id'] = uidTypeField.value.uid_id;
    isLoading.value = true;
    store.dispatch(`getUIDUpadteStore`, uidData).then((res) => {
        isLoading.value = false;
        addCountryForm.value = false;
        if (res.status == 200) {
            get_member_uid(param_id.value)
            uidTypeField.value = {};
            // initialData.value.email = "";
            initialData.value.country_id = "";
            // initialData.value.phone_no = "";
            initialData.value.alternet_phone_no = "";
            initialData.value.uid_number = "";
            componentKey.value +=1;
            emitter.emit('refreshMemberList');
            window.showAlertMessage(res.data.message, 'success');
        }
    });
}

async function deleteUID(id) {
    new window.Swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        focusCancel: true,
        padding: '2em',
    }).then((result) => {
        if (result.value) {
            store.dispatch(`deleteUIDStore`, id).then((res) => {
                if (res.status == 200) {
                    // emitter.emit('refreshMemberList');
                    get_member_uid(param_id.value);
                    window.showAlertMessage(res.data.message, 'success');
                } else {
                    window.showAlertMessage(res.data.message, 'warning');
                }
            });
        }
    });
};


</script>
<style>
.add-btn1 {
    margin-left: 20px;
    margin-top: 20px;
}

.panel .memberTableButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 13px 15px;
}

.switch.s-icons {
    width: 40px;
    height: unset;
}

.switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
    border: 2px solid #ff5f5f !important;
    background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
    border: 2px solid #1abc9c !important;
    background-color: #1abc9c !important;
}
.uidForm {
    border: 1px dashed #ccc;
    padding: 12px 10px 0px;
    margin-bottom: 20px;
    position: relative;
}
.uidForm .xCircle{
    position: absolute;
    right: -6px;
    height: 24px;
    width: 24px;
    top: -8px;
    color: #fff;
    background: #EAAD20;
    border-radius: 100px;
    padding: 0px;
}
.uidForm .xCircle:hover{
    cursor: pointer;
}
</style> 
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
const app = createApp(App);
import { $config } from '../config'

// API BaseURL
axios.defaults.baseURL = $config.api_base_url;
// custom instance
const customInstance = axios.create({
    baseURL: $config.api_base_url,
});
const logoutInstance = axios.create({
    baseURL: $config.api_base_url,
    headers:{
        'Content-Type':'application/json',
        'Authorization':`Bearer ${localStorage.getItem('token')}`,
        'Accept': "application/json"
    }
});
import showAlertMessage from '@/utils/toast'
window.showAlertMessage = showAlertMessage;

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';
import '@/assets/css/custom.css';
// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;
//interceptor
import interceptor from './interceptor';
interceptor();
// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable, ServerTable, EventBus,__esModule} from 'v-tables-3';
// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();
import Multiselect from '@suadelabs/vue3-multiselect';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import VueFeather from 'vue-feather';
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import mitt from 'mitt'
const emitter = mitt()

import ability from './utils/ability.js';
import { abilitiesPlugin } from '@casl/vue';

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
const VueTelInputOptions = {
    mode: "international",
    // onlyCountries: ['IN','NG', 'GH', "GB", "US", "CA"]
  }
app.config.globalProperties.capitalizeString = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
app.use(store)
.use(VueTelInput,VueTelInputOptions)
.use(mdiVue, { icons: mdijs })
.component(VueFeather.name, VueFeather)
.component('loading',Loading)
.component('Multiselect',Multiselect)
.use(router)
.use(i18n)
.use(PerfectScrollbar)
.use(VueNouislider)
.use(Maska)
.use(ClientTable)
.use(ServerTable)
.use(EventBus)
.use(vue3JsonExcel)
.use(VueFormWizard)
.use(head)
.provide('emitter', emitter)
.use(abilitiesPlugin, ability,{
    useGlobalProperties: true
  })
.mount('#app');

export {
    customInstance,logoutInstance
};
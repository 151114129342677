
<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">ByLaws</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>List</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
  <div class="layout-px-spacing">
    <div class="vl-parent panel">
      <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
        <loading v-model:active="memberTableLoading" color="#515365" :is-full-page="false" loader="spinner" :width="35"
          :z-index="9" />
        <div class="refresh_table m-0">
          <a href="javascript:void(0);" @click="tableUpdate += 1">
            <vue-feather type="rotate-cw" size="16"></vue-feather>
          </a>
          <!-- {{ $store.state.village.villageData }} -->
        </div>
        <div class="memberTableButton">
          <button type="button" v-if="isSelectMemberList" class="btn btn-danger"
            @click="delete_member(member_ids)"><vue-feather size="18" type="trash-2"></vue-feather></button>
          <span>
            <button type="button" @click="resetFormData()" class="btn btn-primary m-1" data-bs-toggle="modal"
              data-bs-target="#addMemberModal">Create Law</button>
          </span>
        </div>
        <div class="modal fade" id="addMemberModal" tabindex="-1" role="dialog" aria-labelledby="addMemberModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl vl-parent" role="document">
            <loading v-model:active="memberEditLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="addMemberModalLabel">By Laws</h5>
                <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" id="modalClose11"
                  class="btn-close"></button>
              </div>
              <div class="modal-body">
                <Form @submit="onSubmit" :key="formUpdate" :initial-values="initialData" :validation-schema="schemas">
                  <div class="row align-center">
                    <TextInput cols="6" :error="errors.title" required="required" name="title" label="Title" type="text"
                      placeholder="" />
                    <div class="col-md-6">
                      <label for="">Tags</label>
                      <vue3-tags-input :tags="tags" placeholder="Enter tags" @on-tags-changed="handleChangeTag" />
                    </div>
                    <TextInput cols="12" :error="errors.description" required="required" name="description"
                      label="Description" type="text" placeholder="" />
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <div class="next_prev">
                        <span v-if="!param_id">
                          <button :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                            Save
                          </button>
                        </span>
                        <span v-else>
                          <button :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                            Update
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <pre>{{ values }}</pre> -->
                  <!-- {{ getVillageDetails }} -->
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-table destination-table">
        <v-server-table :columns="columns" :options="memberOptions" :key="tableUpdate"
          @select="(selectedAddRow = $event), checkAddRow()">
          <template #gender="props">
            <span class="badge badge-light-success">{{ props.row.gender == 'M' ? 'Male' : 'Female' }}</span>
          </template>
          <template #age="props">
            <span class="badge badge-light-success">{{ props.row.age + ' Year' }}</span>
          </template>
          <template #mobile="props">
            <span class="badge badge-light-success">{{ props.row.mobile }}</span>
          </template>
          <template #qualification="props">
            <span>{{ props.row.qualification ? props.row.qualification : '---' }}</span>
          </template>
          <template #occupation="props">
            <span>{{ props.row.occupation ? props.row.occupation : '---' }}</span>
          </template>
          <template #action="props">
            <div class="d-flex align-items-center">
              <router-link title="Edit" @click="editVillageMember(props.row.member_id)" data-bs-toggle="modal"
                data-bs-target="#addMemberModal" class="edit_button my-2" to="" tag="button">
                <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
              </router-link>
              <a href="javascript:void(0);" v-if="!isSelectMemberList" title="Delete" class="delete_button m-2"
                @click="delete_member([props.row.member_id])">
                <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
              </a>
            </div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import Vue3TagsInput from 'vue3-tags-input';

import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { onMounted, ref, onBeforeMount, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from 'vue-i18n';
const selectedAddRow = ref([]);
const member_ids = ref([]);
const memberTableLoading = ref(false);
const isSelectMemberList = ref(false);
const memberEditLoading = ref(false)
const errors = ref({});
const formUpdate = ref(0);
const isLoading = ref(false);
const tags = ref([])
useMeta({ title: 'By Laws' });
const $loading = useLoading({
  // options
});
const param_id = ref('');
const { t } = useI18n();

const getVillageDetails = computed(() => {
  return store.state.village.villageData;
});
watch(() => store.state.village.villageData, () => {
  handleReset();
});
const columns = ref([
  "name",
  "email",
  "Tags",
  "action"
]);
const tableUpdate = ref(0);
const memberOptions = ref({
  headings: {
    name: 'Title',
    email: 'Description',
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    // only: function(row) {
    //   return true // any condition
    // },
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5 },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    memberTableLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    pagination.village_id = store.state.village.villageData.id;
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    if (pagination.village_id) {
      return store.dispatch(`getSingleVillageMemberList`, pagination).then((res) => {
        memberTableLoading.value = false;
        store.dispatch('getVilageMemberList', getVillageDetails.value.id); // update village member list
        return { data: res.data.data, count: res.data.total };
      });
    }
    else {
      memberTableLoading.value = false;
      return { data: [], count: 0 };
    }
  },
});

function handleChangeTag(tags) {
  console.log(tags)
}

//  select multiple member
function checkAddRow() {
  member_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (member_ids.value.length != 0) {
    isSelectMemberList.value = true;
  } else {
    isSelectMemberList.value = false;
  }
}
function delete_member(ids) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteMember', { id: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          isSelectMemberList.value = false;
          handleReset();
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};


// =================== Form script ================================

const regionOption = ref([
  { name: 'Asian', value: 'Asian' },
  { name: 'Europian', value: 'Europian' },
  { name: 'North American', value: 'North American' },
  { name: 'Australian', value: 'Australian' },
  { name: 'African', value: 'African' },
]);
const marriedOption = ref([
  { name: 'Married', value: 'married' },
  { name: 'Single', value: 'single' },
  { name: 'Widow', value: 'widow' },
  { name: 'Living In', value: 'live-in' },
  { name: 'Divorce', value: 'divorce' },
]);
const communityOptions = ref([
  { name: 'Hindu', value: 'Hindu' },
  { name: 'Muslim', value: 'Muslim' },
  { name: 'Sikhism', value: 'Sikhism' },
  { name: 'Christian', value: 'Christian' },
  { name: 'Budhism', value: 'Budhism' },
  { name: 'Jainism', value: 'Jainism' },
  { name: 'Other', value: 'Other' },
]);
const genderOption = ref([
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
]);

const initialData = ref({
  edit_id: null,
  first_name: null,
  last_name: null,
  email: null,
  password: null,
  mobile: null,
  age: null,
  dob: null,
  community: null,
  gender: null,
  married: null,
  region: null,
  qualification: null,
  designation: null,
  occupation: null,
  mother_language: null,
  user_address_details: null
})

const schemas = yup.object().shape({
  first_name: yup.string().nullable().required('This field is required.'),
  last_name: yup.string().nullable().required('This field is required.'),
  email: yup.string().nullable().required('This field is required.').email(),
  password: yup.string().nullable().test('password_edit', 'This field is required.', function (value, textContext) {
    console.log(textContext.parent.edit_id)
    if (value == null) {
      if (textContext.parent.edit_id == '' || textContext.parent.edit_id == undefined) {
        return false;
      } else if (textContext.parent.edit_id) {
        return true;
      }
    } else {
      return true;
    }
    // if(value == null){
    //     if ((textContext.parent.member_id !== '' || textContext.parent.member_id != undefined)) {
    //         return true;
    //     }else {
    //         return false;
    //     }
    // }else{
    //     return true
    // }
  }),
  mobile: yup.string().nullable().required('This field is required.'),
  age: yup.string().nullable().required('This field is required.'),
  community: yup.string().nullable().required('This field is required.'),
  gender: yup.string().nullable().required('This field is required.'),
  // married: yup.string().nullable().required('This field is required.'),
  region: yup.string().nullable().required('This field is required.'),
  // qualification: yup.string().nullable().required('This field is required.'),
  designation: yup.string().nullable().required('This field is required.'),
  // occupation: yup.string().nullable().required('This field is required.'),
  // mother_language: yup.string().nullable().required('This field is required.'),
  user_address_details: yup.string().nullable().required('This field is required.'),
});

function editVillageMember(member_id) {
  formUpdate.value += 1;
  param_id.value = member_id;
  memberEditLoading.value = true;
  // const loader = $loading.show({
  //   loader: 'spinner',
  //   zIndex: 999,
  //   color:'#28a745'
  // });
  store.dispatch('editMember', { id: member_id }).then((response) => {
    if (response.status == 200) {
      memberEditLoading.value = false;
      initialData.value = response.data;
      initialData.value.edit_id = response.data.id;
      initialData.value.password = null;
      setTimeout(() => {
        formUpdate.value += 1;
      }, 100);
      // loader.hide();
    } else {
      // loader.hide();
    }
  });
};

function onSubmit(values) {
  console.log(values)
  isLoading.value = true;
  // if(values.is_primary_address){
  //   values['is_primary_address'] = 1;
  // }else{
  //   values['is_primary_address'] = 0;
  // }
  if (values.mobile) {
    values['mobile'] = parseInt(values.mobile);
  }
  values['village_id'] = store.state.village.villageData.id;
  if (param_id.value) {
    values['member_id'] = param_id.value;
    store.dispatch('updateMember', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  } else {
    values['is_primary_address'] = 1;
    store.dispatch('saveMember', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  }
};
function handleReset() {
  // store.dispatch('getVilageMemberList',getVillageDetails.value.id); // update village member list
  errors.value = [];
  formUpdate.value += 1;
  tableUpdate.value += 1;
  document.getElementById('modalClose11').click();

};
function resetFormData() {
  initialData.value = {
    password: null,
  };
  formUpdate.value += 1;
  param_id.value = '';
}
</script>
<style>
.v3ti .v3ti-tag {
  background: #28a745;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: red;
  transition: color .3s;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: #ffffff !important;
}

.v3ti .v3ti-new-tag--error {
  color: #F56C6C !important;
}

.v3ti {
  border: 1px solid #bfc9d4 !important;
}

.v3ti--focus {
  box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.6196078431);
  border-color: #28a745 !important;
}

.v3ti .v3ti-content {
  align-items: center;
}

.v3ti .v3ti-new-tag {
  height: auto;
  border: none !important;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  line-height: 19px;
}</style>   
  
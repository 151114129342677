import router from '@/router';
import axios from 'axios';
import { customInstance,logoutInstance } from '../../main.js';
import store from '../index.js'
const state = {
};
const getters = {
};
const mutations = {
};
const actions = {
	async getDashboardData({ commit }) {
		return await axios.get(`/dashboard/home`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	}
};
export default {
	state,
	mutations,
	actions,
	getters,
};

<template>
   <div class="panel-body select2">
        <div class="row">
            <div class="col-md-12">
            <i class="search_state_pin">Select State | City by Pincode</i>
            <hr>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Country</label>
                    <multiselect v-model="selectCountry" :options="countryOptions" label="country"
                        :searchable="true" :preselect-first="true" selected-label=""
                        placeholder="Search..." :loading="isCountry" select-label="" deselect-label=""
                        @select="callForState($event)"></multiselect>
                </div>
            </div>
            
            <div class="col-md-4">
                <div class="form-group">
                    <label>Search Pincode</label>
                    <div class="d-flex">
                        <input type="number" :disabled="Object.keys(selectCountry).length == 0" v-model="searchPincode" class="form-control" style="border-radius: 6px 0px 0px 6px;">
                        <button class="btn btn-dark" :disabled="!searchPincode" @click="searchPincodeFun()" style="border-radius: 0px 6px 6px 0px;">
                            <vue-feather v-if="isPostalCodeResponse" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                            <mdicon name="magnify" v-else />
                        </button>
                    </div>
                    <p v-if="isPincodeError" class="text-warning">{{ pincodeErrorMsg.includes('not')?pincodeErrorMsg:'' }}</p>
                
                    <!-- <input type="number" :disabled="Object.keys(selectCountry).length == 0" v-model="searchPincode" class="form-control"> -->
                </div>
            </div>
            <div class="col-md-12">
            <i class="search_state_pin">Select State | City Manually</i>
            <hr>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>State</label>
                    <multiselect v-model="selectState" :disabled="stateOptions.length == 0"
                        :options="stateOptions" label="state" :searchable="true" :preselect-first="true"
                        selected-label="" placeholder="Search..." select-label="" deselect-label=""
                        @select="callForCity($event)" :loading="isState"></multiselect>
                </div>
            </div>
            <div class="col-md-4">
                <label>City</label>
                <multiselect v-model="selectCity" :disabled="cityOptions.length == 0" :options="cityOptions"
                    label="city" :searchable="true" :preselect-first="true" selected-label=""
                    placeholder="Search..." select-label="" :loading="isCity" deselect-label=""
                    @select="callForPincode($event)"></multiselect>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Pincode</label>
                    <multiselect v-model="selectPincode" :disabled="pincodeOptions.length == 0" :options="pincodeOptions" 
                        label="postal_code" :searchable="true" :preselect-first="true" selected-label=""
                        @select="callForVillage($event)" :loading="isPincode" select-label="" deselect-label=""></multiselect>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Village</label>
                    <multiselect v-model="selectVillage" :disabled="villageOptions.length == 0" :options="villageOptions" 
                        label="village" :searchable="true" :preselect-first="true" selected-label=""
                        placeholder="" :loading="isVillage" select-label="" deselect-label=""></multiselect>
                        <!-- @select="setVillageDetails($event)"></multiselect> -->
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-4 col-4 my-auto">
                <label for=""></label>
                <button tyle="button" :disabled="Object.keys(selectVillage).length == 0" @click="selectVillageEvent" class="btn btn-primary mt-3"> SELECT</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const store = useStore();

const selectCountry = ref({});
const selectState = ref({});
const selectCity = ref({});
const selectPincode = ref({})
const selectVillage = ref({})

const router = useRouter();
const route = useRoute();
const countryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);
const villageOptions = ref([]);

const isCountry = ref(false);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);
const isVillage = ref(false);
const isPostalCodeResponse = ref(false);
const searchPincode = ref('');
const pincodeErrorMsg = ref('')
const isPincodeError = ref(false);

onMounted(() => {
    console.log(route.path)
    isCountry.value = true;
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        countryOptions.value = res.data.data;
    });
    globalSettings();
});
const preSelectLocationData = computed(()=>{
    return store.state.settings.savedGlobalSettings;
});
function globalSettings(){
    console.log(preSelectLocationData.value.pincode)
    if(Object.keys(preSelectLocationData.value.country).length != 0){
        selectCountry.value = preSelectLocationData.value.country.country_id;
        stateOptions.value = preSelectLocationData.value.country.stateData;
    }
    if(Object.keys(preSelectLocationData.value.state).length != 0){
        selectState.value = preSelectLocationData.value.state.state_id;
        cityOptions.value = preSelectLocationData.value.state.cityData;
    }
    if(Object.keys(preSelectLocationData.value.city).length != 0){
        selectCity.value = preSelectLocationData.value.city.city_id;
        console.log(preSelectLocationData.value.city.city_id.city_id)
        callForVillage(preSelectLocationData.value.city.city_id)
        pincodeOptions.value = preSelectLocationData.value.city.pincodeData;
    }
    console.log(preSelectLocationData.value)
    if(Object.keys(preSelectLocationData.value.pincode).length != 0){
        selectPincode.value = preSelectLocationData.value.pincode.pincode_id;
    }
};
function searchPincodeFun(){
    console.log(searchPincode.value)
    isPostalCodeResponse.value = true;
    store.dispatch(`getPincodeRelatedData`, {country:selectCountry.value.country_id,postal:searchPincode.value}).then((res) => {
        console.log(res.data)
        isPostalCodeResponse.value = false;
        if(res.data.message){
            isPincodeError.value = true;
            pincodeErrorMsg.value = res.data.message;
            setTimeout(()=>{
            isPincodeError.value = false;
            },3000)
        }
        if(res.status == 200){
            stateOptions.value = res.data.states;
            cityOptions.value = res.data.cities;
            villageOptions.value = res.data.villages;
            pincodeOptions.value = res.data.postalcodes;

            if(Object.keys(res.data.is_state).length !=0){
                selectState.value = res.data.is_state;
            }
            if(Object.keys(res.data.is_city).length !=0){
                selectCity.value = res.data.is_city;
            }
            selectPincode.value = {postal_code:res.data.is_postal_code};
            // selectVillage.value = res.data.is_village;
        } else {
            // window.showAlertMessage(res.data.message, 'warning');
            // stateOptions.value = [];
            // cityOptions.value = [];
            // villageOptions.value = [];

            selectState.value = {};
            selectCity.value = {};
            selectPincode.value = {};
            selectVillage.value = {}
        }
        // stateOptions.value = res.data.data;
        // isState.value = false;
    });
}
function callForState(data) {
    selectState.value = {};
    selectCity.value = {};
    selectPincode.value = {};
    selectVillage.value = {};
    isState.value = true;
    store.dispatch(`getState`, data.country_id).then((res) => {
        stateOptions.value = res.data.data;
        isState.value = false;
    });
}
function callForCity(data) {
    console.log(data)
    selectCity.value = {};
    selectPincode.value = {};
    selectVillage.value = {};
    isCity.value = true;
    store.dispatch(`getcities`, data.state_id).then((res) => {
        cityOptions.value = res.data.data;
        isCity.value = false;
    });
}
const pincodeData = ref({});
function callForPincode(data) {
    isPincode.value = true;
    selectPincode.value = {};
    selectVillage.value = {};
    pincodeData.value['country_id'] = selectCountry.value.country_id;
    pincodeData.value['state_id'] = selectState.value.state_id;
    pincodeData.value['city_id'] = data.city_id;
    store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
        pincodeOptions.value = res.data.data;
        isPincode.value = false;
    });
}
// function callForVillage(data) {
//     selectVillage.value = {};
//     isVillage.value = true;
//     store.dispatch(`getSearchVillage`, data.city_id).then((res) => {
//         villageOptions.value = res.data.data;
//         isVillage.value = false;
//     });
// }
function callForVillage(data) {
    selectVillage.value = {};
    isVillage.value = true;
    store.dispatch(`getVillageOfPincode`, data).then((res) => {
        console.log(res.data)
        villageOptions.value = res.data.data;
        isVillage.value = false;
    });
}
const emit = defineEmits(['filterVillage']);
const emitter = inject('emitter'); // Inject `emitter`
async function selectVillageEvent() {
    console.log(selectVillage.value)
    emit('filterVillage');  // close search component
    await store.dispatch('editVillage',selectVillage.value); // for punchayat create
    // store.commit(`SET_VILLAGE_DETAILS`, selectVillage.value);   // for punchayat create
    await emitter.emit('refreshPanchayatList',selectVillage.value.id);
    if(route.path != '/panchayat/create'){
        router.push({ name: 'village-edit', params: { id: selectVillage.value.id } }); // for village edit
    }
}
</script>

<style>

</style>
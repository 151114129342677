import router from '@/router';
import axios from 'axios';
const state = {
	memberEditData:{},
};
const getters = {
	
};
const mutations = {
	memberEditData(state,context){
		state.memberEditData = context;
	}
};
const actions = {
	// MemberList api
	async getVillageMemberList({ commit, dispatch }, getData) {
		console.log(getData.village_id)
		// if(getData.village_id){
			return await axios.get(`/dashboard/village/members?village_id=${getData.village_id}&earch=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
				console.log(response)
				return (response);
			}).catch(error => {
				return error.response;
			});
		// }
	},
	// async saveMember({ commit, dispatch }, getData) {
	// 	return await axios.post(`/dashboard/member`, getData).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async deleteMember({ commit, dispatch }, member_id) {
	// 	return await axios.delete(`/dashboard/members/delete`,{data:member_id}).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async editMember({ commit, dispatch }, getData) {
	// 	return await axios.get(`/dashboard/member/${getData.id}/edit`).then(response => {
	// 		console.log(response.data)
	// 		commit('memberEditData',response.data);
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async updateMember({ commit, dispatch }, getData) {
	// 	return await axios.put(`/dashboard/member`, getData).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
};
export default {
	state,
	mutations,
	actions,
	getters,
};

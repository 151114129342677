<template>
  <!-- <div class="layout-px-spacing"> -->
  <div class="vl-parent panel">
    <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
      <loading v-model:active="articleTableLoading" color="#515365" :is-full-page="false" loader="spinner"
        :width="35" :z-index="9" />
      <div class="refresh_table m-0">
        <div>Selected Constitution: 
        <b class="badge badge-light-success" v-if="isConstitutionCreated.name">{{ isConstitutionCreated.name }}</b>
        <b v-else>...</b>
        </div>
        <a href="javascript:void(0);" @click="onClickList = tableUpdate += 1">
          <vue-feather type="rotate-cw" size="16"></vue-feather>
        </a>
      </div>
      <div class="memberTableButton">
        <button type="button" v-if="isSelectArticleList" class="btn btn-danger"
          @click="deleteArticle(member_ids)">
          <vue-feather size="18" type="trash-2"></vue-feather>
        </button>
        <span>
          <button v-if="$can('article.store')" type="button" @click="resetFormData()" class="btn btn-primary m-1" data-bs-toggle="modal"
            data-bs-target="#addArticleModal">
            Create Articles
          </button>
        </span>
      </div>
      <!-- create article modal -->
      <div class="modal fade" id="addArticleModal" tabindex="-1" role="dialog"
        aria-labelledby="addArticleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl vl-parent" role="document">
          <loading v-model:active="articleEditLoading" :is-full-page="false" loader="spinner" :width="35"
            :z-index="9" />
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title" id="addArticleModalLabel">
                {{ param_id ? "Update Article" : "Create Article" }}
              </h5>
              <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" id="articleModalCloseEvent"
                class="btn-close"></button>
            </div>
            <div class="modal-body">
              <Form @submit="onSubmit" :key="formUpdate" :initial-values="initialData" :validation-schema="schemas">
                <div class="row align-center">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="constitution_id">Constitution<span class="required"></span></label>
                      <Field id="constitution_id" name="constitution_id" class="form-control" label="Select Constitution"
                        as="select" :disabled="isConstitutionCreated.id">
                        <option :value="null" disabled selected>Select Constitution</option>
                        <option v-for="item in constitutionOptions" :value="item.id">{{ item.name }}</option>
                      </Field>
                      <div v-if="errors.constitution_id">
                          <p class="error_alert" v-for="err in errors.constitution_id" :key="err.id">{{ err }}</p>
                      </div>
                      <ErrorMessage class="error_alert" name="constitution_id" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                    <label for="title">Title<span class="required"></span></label>
                    <Field class="form-control" name="title" type="text" placeholder="" as="textarea"/>
                    <ErrorMessage class="error_alert" name="title" />
                  </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="contents">Content<span class="required"></span></label>
                      <Field id="contents" name="content" class="form-control" label="Content" as="textarea"></Field>
                      <div v-if="errors.content">
                          <p class="error_alert" v-for="err in errors.content" :key="err.id">{{ err }}</p>
                      </div>
                      <ErrorMessage class="error_alert" name="content" />
                    </div>
                  </div>
                  <TextInput cols="4" :error="errors.number" name="number" required="required" label="Number" type="number"
                    placeholder="" />
                  <TextInput cols="4" :error="errors.influence" name="influence"
                    label="influence" type="text" placeholder="" />
                  <TextInput cols="4" :error="errors.scripture_name" name="scripture_name" label="Scripture Name"
                    type="text" placeholder="" />
                  <TextInput cols="4" :error="errors.page_number" name="page_number" label="Page Number"
                    type="number" placeholder="" />
                  <TextInput cols="4" :error="errors.line_number" name="line_number" label="Line Number"
                    type="number" placeholder="" />
                  <TextInput cols="4" :error="errors.written_by" name="written_by" label="Written By"
                    type="text" placeholder="" />
                </div>
                <div class="row mt-2">
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <div class="next_prev">
                      <span v-if="!param_id">
                        <button  v-if="$can('article.store')" :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Save
                        </button>
                      </span>
                      <span v-else>
                        <button v-if="$can('article.update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Update
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <pre>{{ values }}</pre> -->
                <!-- {{ getVillageDetails }} -->
              </Form>
            </div>
          </div>
        </div>
      </div>
      <!-- end create article modal -->
      <!-- create language modal -->
      <div class="modal fade" id="addLanguageModal" tabindex="-1" role="dialog"
        aria-labelledby="addLanguageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl vl-parent" role="document">
          <loading v-model:active="articleLangEditLoading" :is-full-page="false" loader="spinner" :width="35"
            :z-index="9" />
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title" id="addLanguageModalLabel">Article Update Language</h5>
              <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" id="languageModalCloseEvent"
                class="btn-close"></button>
            </div>
            <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="lang_created">
                    <span><b>Created in Languages:</b> </span>
                    <span>
                        <span class="badge badge-light-success" v-for="lang in created_language" :key="lang">{{ lang.language }}</span>
                    </span>
                </div>
              </div>
              <div class="col-md-12">
                    <div class="form-group">
                      <label for="lang">Language<span class="required"></span></label>
                      <multiselect v-model="selectLangCode" :options="store.state.tags.language" label="text"
                        :searchable="true" @select="editArticleLangFun" :preselect-first="true" selected-label="" placeholder="Select village"
                        select-label="" deselect-label="">
                      </multiselect>
                    </div>
                  </div>
              </div>
              <Form @submit="onLanguageSubmit" :key="langFormUpdate" :initial-values="initialLangData" :validation-schema="langSchemas">
                <div class="row align-center">
                  <TextInput cols="6" :error="errorsLang.influence" name="influence"
                    label="influence" type="text" placeholder="" />
                  <TextInput cols="6" :error="errorsLang.scripture_name" name="scripture_name" label="Scripture Name"
                    type="text" placeholder="" />
                  <div class="col-md-6">
                    <div class="form-group">
                    <label for="title">Title<span class="required"></span></label>
                    <Field class="form-control" name="title" type="text" placeholder="" as="textarea"/>
                    <ErrorMessage class="error_alert" name="title" />
                  </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="contents">Content<span class="required"></span></label>
                      <Field id="contents" name="content" class="form-control" label="Content" as="textarea"></Field>
                      <div v-if="errorsLang.content">
                          <p class="error_alert" v-for="err in errorsLang.content" :key="err.id">{{ err }}</p>
                      </div>
                      <ErrorMessage class="error_alert" name="content" />
                    </div>
                  </div>
                  <TextInput cols="6" :error="errors.written_by" name="written_by" label="Written By"
                    type="text" placeholder="" />
                </div>
                <div class="row mt-2">
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <div class="next_prev">
                      <span v-if="!param_id">
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Save
                        </button>
                      </span>
                      <span v-else>
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Update
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <pre>{{ values }}</pre> -->
                <!-- {{ getVillageDetails }} -->
              </Form>
            </div>
          </div>
        </div>
      </div>
      <!-- end create language modal -->
    </div>
    <div class="custom-table destination-table">
      <v-server-table :columns="columns" :options="articleOption" :key="tableUpdate"
        @select="(selectedAddRow = $event), checkAddRow()">
        <template #title="props">
            <span class="badge badge-light-success">{{ props.row.title }}</span>
        </template>
        <template #written_by="props">
            <span class="badge badge-light-success">{{ props.row.written_by }}</span>
        </template>
        <template #page_number="props">
            <span class="badge badge-light-success">{{ props.row.page_number }}</span>
        </template>
        <template #line_number="props">
            <span class="badge badge-light-success">{{ props.row.line_number }}</span>
        </template>
        <template #number="props">
            <span class="badge badge-light-success">{{ props.row.number }}</span>
        </template>
        <template #action="props">
          <div class="d-flex align-items-center action_button" v-if="isArticleSelected.id != props.row.id">
            <router-link title="Add Section" @click="onSubmitCofirmForCreateSection(props.row,'addSection')" class="" to="" tag="button">
              <vue-feather type="plus" size="18" stroke="orange"></vue-feather>
            </router-link>
            <router-link v-if="$can('article.edit')" title="Edit" @click="editArticle(props.row.id)" data-bs-toggle="modal"
              data-bs-target="#addArticleModal" class="" to="" tag="button">
              <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
            </router-link>
            <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
            <router-link title="Edit" data-bs-toggle="modal"
              data-bs-target="#addLanguageModal" @click="editArticleLang(props.row.id)" class="" to="" tag="button">
              <mdicon title="Language" class="text-success cursor-pointer" name="translate-variant" /> 
            </router-link>
            <a href="javascript:void(0);" v-if="!isSelectArticleList && $can('article.delete')" title="Delete" class="delete_button"
              @click="deleteArticle(props.row.id)">
              <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
            </a>
            <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
          </div>
          <div v-else class="badge badge-light-warning">Selected</div>
        </template>
      </v-server-table>
    </div>
  </div>
  <!-- </div>  -->
</template>
<script setup>
import TextInput from "../TextInput.vue";
import "@/assets/sass/scrollspyNav.scss";
import "@/assets/sass/components/tabs-accordian/custom-tabs.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { onMounted, ref, onBeforeMount, computed, inject, watchEffect } from "vue";
import { useStore } from "vuex";
import { useMeta } from "@/composables/use-meta";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from "vue-i18n";
const selectedAddRow = ref([]);
const member_ids = ref([]);
const articleTableLoading = ref(false);
const isSelectArticleList = ref(false);
const articleEditLoading = ref(false);
const articleLangEditLoading = ref(false);
const errors = ref({});
const errorsLang = ref({});
const constitutionOptions = ref([]);
const formUpdate = ref(0);
const langFormUpdate = ref(0);
const isLoading = ref(false);
const selectLangCode = ref({value:'en',text:'English'});
const created_language = ref([]);
useMeta({ title: "Article" });
const $loading = useLoading({
  // options
});

const param_id = ref("");
const { t } = useI18n();
onMounted(()=>{
  updateConstitutionList();
});
function updateConstitutionList(){
  store.dispatch("searchConstitutionList").then((response) => {
      if (response.status == 200) {
        constitutionOptions.value = response.data.data;
      }
    });
};
const isConstitutionCreated = computed(() => {
    return store.state.searchByLaw.is_constitution_select;
});
const isArticleSelected = computed(() => {
    return store.state.articles.is_article_select;
});
const columns = ref([
  "title",
  "scripture_name",
  "influence",
  "page_number",
  "line_number",
  "number",
  "written_by",
  "action"
]);
const tableUpdate = ref(0);
const emitter = inject('emitter'); // Inject `emitter`

emitter.on('refreshArticleList', (value) => {   // *Listen* for event
  tableUpdate.value += 1;
});
watchEffect(() => {
  console.log(store.state.tags.selectedTags)
  tableUpdate.value += 1;
})
// beforeDestroy(()=>{
//   emitter.off('refreshArticleList');
// })
const articleOption = ref({
  highlightMatches: true,
  columnClasses: {
    title: 'highlight',  // Apply the "highlight" class to the "name" column
  },
  headings: {
    scripture_name: "Scripture",
    page_number:"Page",
    line_number:"Line",
    written_by: "Written By",
    created_by:"Created By"
  },
  selectable: {
    mode: "multiple", // or 'multiple'
    // only: function(row) {
    //   return true // any condition
    // },
    selectAllMode: "page", // or 'page',
    programmatic: false,
  },
  columnsClasses: { actions: "actions text-center" },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: "table",
  columnsClasses: { actions: "actions text-center" },
  sortable: ["name", "email"],
  sortIcon: {
    base: "sort-icon-none",
    up: "sort-icon-asc",
    down: "sort-icon-desc",
  },
  pagination: { nav: "scroll", chunk: 5 },
  texts: {
    count: t("Showing") + " {from} " + t("to") + " {to} " + t("of") + " {count}",
    filter: "",
    filterPlaceholder: "Search...",
    limit: "Results:",
  },
  resizableColumns: false,
  async requestFunction(data) {
    articleTableLoading.value = true;
    var pagination = {};
    if(Object.keys(store.state.searchByLaw.is_constitution_select).length !=0){
      pagination['const_id'] = store.state.searchByLaw.is_constitution_select.id;
    }
    if(Object.keys(store.state.tags.selectedTags).length !=0){
       var tagIdParam = store.state.tags.selectedTags.map((tag)=>{
        return tag.id;
      });
      pagination['tag_id'] = tagIdParam.join(',');
    }
    pagination["search"] = data.query;
    pagination["sort_direction"] = data.direction;
    pagination["sort_by"] = data.orderBy ? data.orderBy : "id";
    pagination["page"] = data.page;
    pagination["per_page"] = data.limit;
    console.log(pagination)
    return store.dispatch(`getArticleDataList`, pagination).then((res) => {
      console.log(res);
      articleTableLoading.value = false;
      return { data: res.data.data, count: res.data.total };
    });
  },
});

//  select multiple member
function checkAddRow() {
  member_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (member_ids.value.length != 0) {
    isSelectArticleList.value = true;
  } else {
    isSelectArticleList.value = false;
  }
}
function deleteArticle(ids) {
  new window.Swal({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Delete",
    focusCancel: true,
    padding: "2em",
  }).then((result) => {
    if (result.value) {
        store.dispatch("deleteArticleStore", ids).then((response) => {
          if (response.status == 200) {
            isSelectArticleList.value = false;
            emitter.emit('refreshSearchList'); // refresh search fields
            handleReset();
            window.showAlertMessage(response.data.message, "success");
          } else {
            window.showAlertMessage(response.data.message, "warning");
          }
        });
    }
  });
}

const initialData = ref({
  "title": null,
  "constitution_id": null,
  "number": null,
  "content": null,
  "influence": null,
  "scripture_name": null,
  "page_number": null,
  "line_number": null,
  "written_by": null
});
const initialLangData = ref({
  "lang":"en",
  "title": null,
  "content": null,
  "influence": null,
  "written_by": null,
  "scripture_name": null
});
const langSchemas = yup.object().shape({
  title: yup.string().nullable().required("This field is required."),
  content: yup.string().nullable().required("This field is required."),
});
const schemas = yup.object().shape({
  title: yup.string().nullable().required("This field is required."),
  constitution_id: yup.string().nullable().required("This field is required."),
  number: yup.string().nullable().required("This field is required."),
  content: yup.string().nullable().required("This field is required.")
});
function editArticleLangFun(lang){
  selectLangCode.value = lang;
  editArticleForLang()
}
function editArticleLang(id){
  param_id.value = id;
  selectLangCode.value = {value:'en',text:'English'};
  editArticleForLang()
}
function editArticleForLang() {
  langFormUpdate.value += 1;
  articleLangEditLoading.value = true;
  var editData = {};
  editData['lang'] = selectLangCode.value.value;
  editData['id'] = param_id.value;
  store.dispatch("editArticleLangStore", editData).then((response) => {
    if (response.status == 200) {
      console.log(response.data)
      articleLangEditLoading.value = false;
      initialLangData.value = response.data;
      created_language.value = response.data.translations;
      setTimeout(() => {
        langFormUpdate.value += 1;
      }, 100);
    }
  });
}
function onLanguageSubmit(values) {
  isLoading.value = true;
  values["language_code"] = selectLangCode.value.value;
  values["created_by"] = store.state.auth.auth.users.id;
  values["article_id"] = param_id.value;
  store.dispatch("updateArticleStore", values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        window.showAlertMessage(response.data.message, "success");
        editArticleForLang();
        tableUpdate.value += 1;
      } else {
        errorsLang.value = response.data.errors;
        window.showAlertMessage(response.data.message, "warning");
      }
    });
}
function editArticle(id) {
  formUpdate.value += 1;
  param_id.value = id;
  articleEditLoading.value = true;
  store.dispatch("editArticleStore", id).then((response) => {
    if (response.status == 200) {
      articleEditLoading.value = false;
      initialData.value = response.data;
      setTimeout(() => {
        formUpdate.value += 1;
      }, 100);
    }
  });
}
const isSelectedTags = computed(() => {
    return store.state.tags.selectedTags.map((tag)=>{
        return tag.id;
    });
});
function onSubmit(values) {
  isLoading.value = true;
  values["tag_id"] = isSelectedTags.value;
  values["created_by"] = store.state.auth.auth.users.id;
  if (param_id.value) {
    values["id"] = param_id.value;
    store.dispatch("updateArticleStore", values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, "success");
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, "warning");
      }
    });
  } else {
    store.dispatch("saveArticleStore", values).then((response) => {
      isLoading.value = false;
            if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, "success");
        onSubmitCofirmForCreateSection(response.data.data);
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, "warning");
      }
    });
  }
}

function onSubmitCofirmForCreateSection(sectionData,value) {
  new window.Swal({
    title: "Are you sure?",
    text: "Do you want to select this article to work on article sections.",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Select",
    focusCancel: true,
    padding: "2em",
  }).then((result) => {
    if (result.value) {
        store.commit('IS_ARTICLE_SELECTED',sectionData);
        emitter.emit('refreshSearchList'); // refresh search fields
        } else {
          if(value != 'addSection'){
            store.commit('IS_ARTICLE_SELECTED',{});
            store.commit('IS_SECTION_SELECTED',{});
          }
        }
    });
}
function handleReset() {
  document.getElementById("articleModalCloseEvent").click();
  errors.value = [];
  formUpdate.value += 1;
  tableUpdate.value += 1;
}
function resetFormData() {
  updateConstitutionList();
  initialData.value = {
    "title": null,
    "constitution_id": isConstitutionCreated.value.id,
    "number": null,
    "content": null,
    "influence": null,
    "scripture_name": null,
    "page_number": null,
    "line_number": null,
    "written_by": null
  };
  formUpdate.value += 1;
  param_id.value = "";
}
</script>
<style>
.lang_created {
  border-bottom: 1px dashed #bfc9d4;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.lang_created span {
  margin-right:5px;
}
.action_button a{ 
  margin-right: 12px;
}
</style>

<template>
    <div class="col-md-4">
        <div class="form-group">
            <label :for="name">{{ label }} <span :class="required"></span></label>
            <select class="form-control" :name="name" :error="error" :id="name" :type="type" :value="inputValue" @input="handleChange"
                @blur="handleBlur">
                <!-- <option value="null" selected disabled>{{ label }}</option> -->
                <option v-for="item in options" :key="item.id" :value="item.value">{{ item.name }}</option>
            </select>
            <p class="error_alert" v-show="errorMessage">
                {{ errorMessage }}
            </p>
            <p class="error_alert" v-show="error">
                <span v-for="err in error">{{ err }}</span>
            </p>
        </div>
    </div>
</template>
  
<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    error: {
        type: String,
        default: '',
    },
    options: {
        type: Array,
        default: [],
    },
    required: {
        type: String,
        required: '',
    },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.value,
});
</script>

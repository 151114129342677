import { defineAbility } from '@casl/ability';
import store from '../store/index';
import { ref, onMounted, computed, watch, inject, watchEffect } from 'vue';

if(Object.keys(store.state.auth.meDetails).length!=0){
  var permissions = JSON.parse(JSON.stringify(store.state.auth.meDetails.permissions));
}
const ability = defineAbility((can,cannot) => {
  // console.log(store.state.auth.auth.permissions)
  // const user = JSON.parse(localStorage.getItem('userDetail'));
  // var permissions = store.state.auth.meDetails.permissions;
  // console.log(permissions)
  var permissionItems = [];
  if (permissions != undefined) {
        permissions.push('dashboard.home');
        // permissions.push('dashboard.index2');
        permissions.push('settings.create'); 
        permissions.map((item) => {
          var splitItem = item.split('.')[0]+'_view';
          if(!permissionItems.includes(splitItem)){
            permissionItems.push(splitItem);
          }
            permissionItems.push(item)
          });
        const uniqueSet = new Set(permissionItems);
        console.log(Array.from(uniqueSet))
        can(Array.from(uniqueSet), 'all');
      } else {
          can([]);
          cannot("manage", "all");
      }
  });
export default ability;

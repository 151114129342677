<template>
    <!-- {{ initialData }} -->
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div class="panel p-3">
                <!-- {{ $route.query }} -->
                    <div class="row">
                        <div class="col-md-12 hide-show-location">
                            <div class="mb-0 custom_create_btn justify-content-between">
                                <div class="refresh_table m-0">
                                    <h5 class="mb-0"><b>Step 3/3:- </b> Attach profile for <b>{{ $route.query.name }}</b> member</h5>
                                </div>
                                <div class="villageTableButton mx-0">
                                    <router-link class="btn btn-danger" :to="{ name: 'create-member' }">Go To New Register</router-link>
                                    <!-- <button @click="router.go(-1)" class="btn btn-danger">Back</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form v-show="UpdateShow" @submit="onSubmit" :key="componentKey" :initial-values="initialData"
                        :validation-schema="schemas" keep-values v-slot="{ handleSubmit, values }">
                        <div class="row align-center">
                            <SelectInput :error="errors.married" required="required" name="married" :options="marriedOption" label="Married Status" />
                            <SelectInput :error="errors.community" name="community" required="required" :options="communityOptions"
                                label="Community" />
                            <SelectInput :error="errors.region" name="region" required="required" :options="regionOption"
                                label="Region" />
                            <SelectInput :error="errors.mother_language" name="mother_language" required="required" :options="motherLanguageOption"
                                label="Mother Language" />
                            <SelectInput :error="errors.qualification" name="qualification" required="required" :options="qualificationOption"
                                label="Qualification" />
                            <SelectInput :error="errors.occupation" name="occupation" required="required" :options="occupationOption"
                                label="Occupation" />
                            <TextInput :error="errors.designation" required="required" name="designation" label="Designation"
                                type="text" placeholder="" />
                            <TextInput :error="errors.user_address_details" required="required" name="user_address_details" label="User Address Details"
                                type="text" placeholder="" />
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <div class="action_btn">
                                    <!-- <router-link :to="{ name: 'village-list', params: { params: {} } }" type="button"
                                        class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                                    <span v-if="!$route.params.member_id">
                                        <button v-if="$can('member.location-create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Attach Profile
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button v-if="$can('member.location-update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Update Profile
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- <pre>{{ values }}</pre> -->
                    </Form>
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <div class="alert alert-light-primary alert-dismissible border-0 mb-4" role="alert">
                                <strong>Village successfully created.</strong> Now you can add members into it.
                                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import { ref, reactive, computed, onBeforeMount,onMounted, watch } from 'vue';
import '@/assets/sass/scrollspyNav.scss';
// import highlight from '@/components/plugins/highlight.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useMeta } from '@/composables/use-meta';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const router = useRouter();
const UpdateShow = ref(true)
const $loading = useLoading({
    // options
});
useMeta({ title: 'Forms' });
const isLoading = ref(false);
const param_id = ref('');
const errors = ref({});
const componentKey = ref(0);

const marriedOption = ref([
  { name: 'Married', value: 'married' },
  { name: 'Single', value: 'single' },
  { name: 'Widow', value: 'widow' },
  { name: 'Living In', value: 'live-in' },
  { name: 'Divorce', value: 'divorce' },
]);
const communityOptions = ref([
  { name: 'Hindu', value: 'Hindu' },
  { name: 'Muslim', value: 'Muslim' },
  { name: 'Sikhism', value: 'Sikhism' },
  { name: 'Christian', value: 'Christian' },
  { name: 'Budhism', value: 'Budhism' },
  { name: 'Jainism', value: 'Jainism' },
  { name: 'Other', value: 'Other' },
]);

const motherLanguageOption = ref([
  { name: 'Hindi', value: 'hindi' },
  { name: 'English', value: 'english' },
]);
const qualificationOption = ref([
  { name: '10th', value: '10th' },
  { name: '12th', value: '12th' },
]);
const regionOption = ref([
    { name: 'Asian', value: 'Asian' },
    { name: 'Europian', value: 'Europian' },
    { name: 'North American', value: 'North American' },
    { name: 'Australian', value: 'Australian' },
    { name: 'African', value: 'African' },
]);
const occupationOption = ref([
  { name: 'Housewife', value: 'Housewife' },
  { name: 'Salesman', value: 'Salesman' },
  { name: 'Driver', value: 'Driver' },
  { name: 'Singer', value: 'Singer' },
  { name: 'Postman', value: 'Postman' },
  { name: 'Doctor', value: 'Doctor' },
  { name: 'Teacher', value: 'Teacher' },
]);

const initialData = ref({
    community: null,
    occupation: null,
    married: null,
    region: null,
    qualification: null,
    mother_language: null,
    designation: null,
    user_address_details:null
});
 
const schemas = yup.object().shape({
    community: yup.string().nullable().required('This field is required.'),
    married: yup.string().nullable().required('This field is required.'),
    region: yup.string().nullable().required('This field is required.'),
    qualification: yup.string().nullable().required('This field is required.'),
    mother_language: yup.string().nullable().required('This field is required.'),
    designation: yup.string().nullable().required('This field is required.'),
    occupation: yup.string().nullable().required('This field is required.'),
    user_address_details: yup.string().nullable().required('This field is required.'),
});

const urlData = ref('');
const paramsData = ref('');
onBeforeMount(() => {
    const route = useRoute();
    urlData.value = route.query;
    console.log(urlData.value)
    paramsData.value = route.query
    if (Object.keys(route.params) != 0) {
        edit_member_profile(route.params);
    }
});

const edit_member_profile = async (data) => {
    const loader = $loading.show({
        loader: 'spinner',
        zIndex: 999,
        width:40
    });
    store.dispatch('editMemberProfile', data).then((response) => {
        console.log(response)
        loader.hide();
        if (response.status == 200) {
            console.log(response.data)
            initialData.value = response.data.data;
            // setTimeout(() => {
            //     componentKey.value += 1;
            // }, 1000);
        }
    });
};

function onSubmit(values) {
    isLoading.value = true;
    console.log(urlData.value)
    values['member_id'] = urlData.value.member_id;
    console.log(values)
    store.dispatch('updateMemberProfile', values).then((response) => {
        isLoading.value = false;
        if (response.status == 200) {
            handleReset();
            window.showAlertMessage(response.data.message, 'success');
            setTimeout(()=>{
                router.push({ path: '/member/create'});
            },1000)
        } else {
            errors.value = response.data.errors;
            window.showAlertMessage(response.data.message, 'warning');
        }
    });
    // if (urlData.value.member_id) {
    // } else {
    //     store.dispatch('saveMemberProfile', values).then((response) => {
    //         isLoading.value = false;
    //         console.log(response)
    //         if (response.status == 200) {
    //             handleReset()
    //             window.showAlertMessage(response.data.message, 'success');
    //             optionToCreateProfile(values);
    //         } else {
    //             errors.value = response.data.errors;
    //             window.showAlertMessage(response.data.message, 'warning');
    //         }
    //     });
    // }
};

function handleReset() {
    errors.value = [];
    initialData.value = {
        country_id: null,
        state_id: null,
        city_id: null,
        postal_code: null,
        village: null,
        address_detail: null,
    }
}
</script>
<style scoped>
.action_btn {
    display: flex;
    justify-content: flex-end;
}

.action_btn button {
    margin-left: 10px;
}

.form-part {
    margin: 0px 0px 20px
}

.form-part h6 {
    font-weight: bold;
}

.panel .panel-heading h4 {
    font-size: 21px;
}

.hide-show-location {
    text-align: right;
    margin-bottom: 10px;
}</style>
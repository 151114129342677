import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	// is_section:[],
	is_section_select:{}
};
const getters = {
};
const mutations = {
	// IS_SECTION(state,context){
	// 	state.is_section = context;
	// },
	IS_SECTION_SELECTED(state,context){
		state.is_section_select = context;
	}
};
const actions = {
	async getSectionDataList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/sections`,{
            params:getData
        }).then(response => {
			if(response.data.data.length == 0){
				commit('IS_SECTION_SELECTED',{})
			}
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	// async getTagsDropdownListStore({ commit, dispatch }, getData) {
	// 	return await axios.get(`/dashboard/tag/list`,{
    //         params:getData
    //     }).then(response => {
    //         return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	async saveSectionStore({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/section/create`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateSectionStore({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/section/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},

	async deleteSectionStore({ commit, dispatch }, id) {
		return await axios.delete(`/dashboard/section/trash/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editSectionStore({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/section/edit/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editSectionLangStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/section/edit/${getData.id}?lang=${getData.lang}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	
};
export default {
	state,
	mutations,
	actions,
	getters,
};

<template>
    <div class="layout-px-spacing ">
    <div class="mapHeader">
    <!-- <button class="btn btn-primary" @click="isSearchForm = !isSearchForm">Hide</button> -->
        <div v-if="isSearchForm" class="row panel p-3" style="margin:20px 0px 0px 0px">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Country</label>
                    <multiselect v-model="selectCountry" :disabled="countryOptions.length == 0" :clear-on-select="true" :options="countryOptions" label="country"
                        :searchable="true" :preselect-first="true" selected-label="" placeholder="Search..."
                        :loading="isCountry" select-label="" deselect-label="" @select="callForState($event)"></multiselect>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>State</label>
                    <multiselect v-model="selectState" :disabled="stateOptions.length == 0" :options="stateOptions"
                        label="state" :searchable="true" :preselect-first="true" selected-label="" placeholder="Search..."
                        select-label="" deselect-label="" @select="callForCity($event)" :loading="isState"></multiselect>
                </div>
            </div>
            <div class="col-md-3">
                <label>City</label>
                <multiselect v-model="selectCity" :disabled="cityOptions.length == 0" :options="cityOptions" label="city"
                    :searchable="true" :preselect-first="true" selected-label="" placeholder="Search..." select-label=""
                    :loading="isCity" deselect-label="" @select="callForPincode($event)"></multiselect>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Pincode</label>
                    <multiselect v-model="selectPincode" :disabled="pincodeOptions.length == 0" :options="pincodeOptions"
                        label="postal_code" :searchable="true" :preselect-first="true" selected-label=""
                        @select="callForLocation($event)" :loading="isPincode" select-label="" deselect-label="">
                    </multiselect>
                    <!-- <vue-feather v-if="isMapDataLoad" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div :key="mapUpdate" class="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                <div class="panel p-1" style="position:relative;">
                    <!-- Loading Overlay -->
                    <div  v-if="isMapDataLoad" class="loading-overlay">
                    <vue-feather type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                    Loading Map...
                    </div>
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import leaflet from 'leaflet';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const isSearchForm = ref(true)
const store = useStore();
const mapUpdate = ref(0);

const selectCountry = ref({});
const selectState = ref({});
const selectCity = ref({});
const selectPincode = ref({});

const countryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);

const isMapDataLoad = ref(false);
const isCountry = ref(true);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);

const accessToken = ref('pk.eyJ1Ijoic2hhdWtpbiIsImEiOiJjbHNsbHkwOWgwZThwMmpybml3Ym5oN280In0.z-po1iSbtsRoNtOf6SpP2A')
const locations = ref([
    // { "latitude": 0, "longitude": 0, "members_count": 0 },
    // { latitude: 30.213982, longitude: 78.991699 },
    // { latitude: 24.439648, longitude: 46.757813 },
    // { latitude: 29.298376, longitude: 76.706543 },
    // { latitude: 23.878303, longitude: 73.45459 },
]);
const newLocationsList = ref([{ "latitude": 22.3511, "longitude": 78.6677 }])
const centralLocation = ref(null);
const markers = ref([]);
const selectedView = ref('street'); // Default view is street
let mapInstance = null;
const searchQuery = ref('');
onMounted(() => {
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        countryOptions.value = res.data.data;
    });
    // calculateCenter(locations.value);
    initialize();
});
function initialize() {
    if (!mapInstance) {
        mapInstance = leaflet.map('map').setView([newLocationsList.value[0].latitude, newLocationsList.value[0].longitude], 5);
        console.log('hiii')
        // streetLayer
        const streetLayer = leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
            minZoom: 5, // Adjust minimum zoom level
            maxZoom: 18, // Adjust maximum zoom level
        });
        streetLayer.addTo(mapInstance);
    }
    // Remove existing markers
    markers.value.forEach(marker => {
        marker.removeFrom(mapInstance);
    });

    markers.value = []; // Clear the existing markers array
    // Add markers
    if(newLocationsList.value.length > 1){
        console.log(newLocationsList.value)
        newLocationsList.value.forEach((location,index) => {
            if (index == 0) {
                mapInstance.setView([location.latitude, location.longitude], 5); // Set the zoom level as needed
            }
            if(index != 0){
                const marker = leaflet.marker([location.latitude, location.longitude]).addTo(mapInstance);
                marker.bindPopup(`Member:${location.members_count}<br>Village:${location.village}`);
                markers.value.push(marker);
                const el = marker.getElement();
                if (el) {
                    el.style.filter = "hue-rotate(270deg)";
                }
            }
        });
    };
    // Open popups for all markers
    // markers.value.forEach(marker => {
    //     marker.openPopup();
    // });
    // Listen for zoomend event
    if (mapInstance) {
        mapInstance.on('zoomend', (event) => {
            // Adjust marker positions based on the animation event
            adjustMarkerPositions(event);
        });
    }
}
function adjustMarkerPositions() {
    markers.value.forEach((marker, index) => {
        const location = newLocationsList.value[index];
        if (mapInstance) {
            marker.setLatLng([location.latitude, location.longitude]);
        }
    });
}
function callForState(data) {
    isState.value = true;
    selectState.value = {}
    selectCity.value = {};
    selectPincode.value = {};
    store.dispatch(`getState`, data.country_id).then((res) => {
        stateOptions.value = res.data.data;
        isState.value = false;
    });
}

function callForCity(data) {
    isCity.value = true;
    selectCity.value = {};
    selectPincode.value = {};
    store.dispatch(`getcities`, data.state_id).then((res) => {
        cityOptions.value = res.data.data;
        isCity.value = false;
    });
}
const pincodeData = ref({});
const locationDataForCity = ref({});
function callForPincode(data) {
    isPincode.value = true;
    isMapDataLoad.value = true;
    selectPincode.value = {};
    pincodeData.value['country_id'] = selectCountry.value.country_id;
    pincodeData.value['state_id'] = selectState.value.state_id;
    pincodeData.value['city_id'] = data.city_id;
    store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
        pincodeOptions.value = res.data.data;
        isPincode.value = false;
    });

    locationDataForCity.value['country'] = selectCountry.value.country_id;
    locationDataForCity.value['state'] = selectState.value.state_id;
    locationDataForCity.value['city'] =  data.city_id;
    store.dispatch(`getPincodeLocation`, locationDataForCity.value).then((res) => {
        if(res.data.data.length == 0){
            newLocationsList.value = [{ "latitude": 22.3511, "longitude": 78.6677 }];
            initialize();
        } else {
            calculateCenter(res.data.data);
        }
        isMapDataLoad.value = false;
    });
}

const locationDataForPincode = ref({});
function callForLocation(data) {
    console.log(data)
    isMapDataLoad.value = true;
    locationDataForPincode.value['country'] = selectCountry.value.country_id;
    locationDataForPincode.value['state'] = selectState.value.state_id;
    locationDataForPincode.value['city'] = selectCity.value.city_id;
    locationDataForPincode.value['postal'] = data.postal_code;
    store.dispatch(`getPincodeLocation`, locationDataForPincode.value).then((res) => {
        if(res.data.data.length == 0){
            newLocationsList.value = [{ "latitude": 0, "longitude": 0 }];
            initialize();
        } else {
            calculateCenter(res.data.data);
        }
        isMapDataLoad.value = false;
    });
};

function calculateCenter(locations) {
    const validLocations = locations.filter(location => location.latitude !== "" && location.longitude !== "");

    if (validLocations.length === 0) {
        return;
    }

    const totalValidLocations = validLocations.length;

    // Calculate average latitude and longitude
    const averageLatitude = validLocations.reduce((sum, location) => sum + parseFloat(location.latitude), 0) / totalValidLocations;
    const averageLongitude = validLocations.reduce((sum, location) => sum + parseFloat(location.longitude), 0) / totalValidLocations;

    // Set the centerLocation with the calculated values
    const centerLocation = { latitude: averageLatitude, longitude: averageLongitude };
    console.log(centerLocation);

    newLocationsList.value = [centerLocation].concat(validLocations);
    initialize();
    console.log(newLocationsList.value);
}
</script>

<style>
#map {
    height: 100vh;
    width: 100%;
    z-index: 0;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.mapHeader{
    position: relative;
}
.mapHeader button{
    position: absolute;
    width: 46px;
    top: -10px;
    right: 0px;
    font-size: 12px;
}
</style>
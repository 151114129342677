
<template>
    <div style="padding: 0 20px 0 18px !important">
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing pb-0">
                <div class="vl-parent panel br-6 p-0">
                    <div class="vl-parent panel br-6 p-0">
                        <loading v-model:active="destinationLoading" :is-full-page="false" color="#515365" loader="spinner"
                            :width="40" :z-index="9" />
                        <div class="m-3 mb-0 custom_create_btn justify-content-between">
                            <div class="refresh_table m-0">
                                <h5 class="mb-0">Villages for country: <b>{{ $route.query.country }}</b></h5>
                                <a href="javascript:void(0);" @click="recall += 1">
                                    <vue-feather type="rotate-cw" size="16"></vue-feather>
                                </a>
                            </div>
                            <div class="locationTableButton mx-0">
                                <button type="button" v-if="isSelectMemberLocationList" class="btn btn-danger"
                                    @click="delete_member_location(location_ids)">
                                    <vue-feather type="trash-2" size="18"></vue-feather>
                                </button>
                                <router-link class="btn btn-danger" :to="{ name: 'member-edit',params:{'id':$route.query.member_id} }">Back</router-link>
                                <!-- <button @click="router.go(-1)" class="btn btn-danger">Back</button> -->
                                <router-link :to="{ name: 'create-member-multiple-location',query:{'country':$route.query.country,'country_id':$route.query.country_id,'member_id':$route.query.member_id} }" class="m-1"><button
                                        type="button" class="btn btn-primary"><vue-feather type="plus"
                                            size="18"></vue-feather> &nbsp;
                                        Village</button>
                                </router-link>
                            </div>
                        </div>
                        <div class="custom-table destination-table">
                            <v-server-table :columns="columns" :options="deviceMaster" :key="recall"
                                @select="(selectedAddRow = $event), checkAddRow()">
                                <template #village="props">
                                    <span class="badge badge-light-success" v-if="props.row.village">{{ props.row.village
                                    }}</span>
                                    <span v-else>---</span>
                                </template>
                                <template #address_detail="props">
                                    <span class="badge badge-light-success" v-if="props.row.address_detail">{{ props.row.address_detail}}</span>
                                    <span v-else>---</span>
                                </template>
                                <template #longitude="props">
                                    <span class="badge badge-light-success" v-if="props.row.longitude">{{ props.row.longitude}}</span>
                                    <span v-else>---</span>
                                </template>
                                <template #latitude="props">
                                    <span class="badge badge-light-success" v-if="props.row.latitude">{{ props.row.latitude}}</span>
                                    <span v-else>---</span>
                                </template>
                                <template #postal_code="props">
                                    <span class="badge badge-light-success" v-if="props.row.postal_code">{{ props.row.postal_code}}</span>
                                    <span v-else>---</span>
                                </template>
                                <template #location="props">
                                    <span class="d-block"><b>City:</b> {{ props.row.city }},</span>
                                    <span class="d-block"><b>State:</b> {{ props.row.state }},</span>
                                    <span class="d-block"><b>Country:</b> {{ props.row.country }}</span>
                                </template>

                                <template #action="props">
                                    <div class="d-flex align-items-center">
                                        <router-link v-if="$can('member.location-edit')" title="Edit" class="edit_button my-2"
                                            :to="{ name: 'edit-member-multiple-location',query:{'country':$route.query.country,'country_id':$route.query.country_id}, params: { 'member_id': props.row.member_id, 'village_id': props.row.village_id } }">
                                            <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                                        </router-link>
                                        <span v-if="$can('member.location-delete')">
                                        <a href="javascript:void(0);" v-if="!isSelectMemberLocationList" title="Delete"
                                            class="delete_button m-2" @click="delete_member_location({ 'member_id': props.row.member_id, 'village_id': props.row.village_id })">
                                            <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                                        </a>
                                        </span>
                                    </div>
                                </template>
                            </v-server-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
import { useI18n } from 'vue-i18n';
const store = useStore();
const selectedAddRow = ref([]);
const location_ids = ref([]);
const isSelectMemberLocationList = ref(false)
useMeta({ title: 'Member Location' });
const { t } = useI18n();
const columns = ref([
    "village",
    "address_detail",
    "longitude",
    "latitude",
    "location",
    "postal_code",
    "action",
]);
onBeforeMount(() => {
    const route = useRoute();
    console.log(route.query)
    if(route.query.country){
        localStorage.setItem('memberCreateOrEditStatus','edit');
    }
});
const recall = ref(0);
const destinationLoading = ref(false);
const deviceMaster = ref({
    headings: {
        location: 'Location',
        administrative_block: "Administrative Block",
        sub_administrative_block: "Sub Administrative Block",
        postal_code: 'Postal Code',
        dob:"D.O.B"
    },
    selectable: {
        mode: 'multiple', // or 'multiple'
        // only: function(row) {
        //   console.log(row)
        //   return true // any condition
        // },
        selectAllMode: 'page', // or 'page',
        programmatic: false
    },
    columnsClasses: { actions: 'actions text-center' },
    perPage: 10,
    perPageValues: [10, 25, 50, 100],
    skin: 'table',
    columnsClasses: { actions: 'actions text-center' },
    sortable: ['village'],
    sortIcon: {
        base: 'sort-icon-none',
        up: 'sort-icon-asc',
        down: 'sort-icon-desc',
    },
    pagination: { nav: 'scroll', chunk: 5, edge: true, show: true },
    texts: {
        count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
        filter: '',
        filterPlaceholder: 'Search...',
        limit: 'Results:',
    },
    resizableColumns: false,
    async requestFunction(data) {
        destinationLoading.value = true;
        const pagination = data;
        const direction = data.ascending == 1 ? 'asc' : 'desc';
        data.direction = direction;
        data.orderBy = data.orderBy ? data.orderBy : 'id';
        const route = useRoute();
        console.log(route.query)
        pagination['member_id'] = route.query.member_id;
        pagination['country'] = route.query.country_id;
        return store.dispatch(`getMultiLocationList`, pagination).then((res) => {
            destinationLoading.value = false;
            console.log(res.data)
            return { data: res.data.data, count: res.data.total };
        });

    },
});
//  select multiple village
function checkAddRow() {
    location_ids.value = selectedAddRow.value.map((el) => {
        return el.id;
    });
    if (location_ids.value.length != 0) {
        isSelectMemberLocationList.value = true;
    } else {
        isSelectMemberLocationList.value = false;
    }
}
function delete_member_location(idData) {
    new window.Swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        focusCancel: true,
        padding: '2em',
    }).then((result) => {
        if (result.value) {
            store.dispatch('deleteMemberLocation', idData).then((response) => {
                if (response.status == 200) {
                    isSelectMemberLocationList.value = false;
                    recall.value += 1;
                    window.showAlertMessage(response.data.message, 'success');
                } else {
                    window.showAlertMessage(response.data.message, 'warning');
                }
            });
        }
    });
};
</script>
<style>
.add-btn1 {
    margin-left: 20px;
    margin-top: 20px;
}

.panel .locationTableButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 13px 15px;
}

.switch.s-icons {
    width: 40px;
    height: unset;
}

.switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
    border: 2px solid #ff5f5f !important;
    background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
    border: 2px solid #1abc9c !important;
    background-color: #1abc9c !important;
}
</style>   
    
<template>
    <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Member</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Registration</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 layout-spacing pb-0">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-8 col-md-8 col-sm-8 col-8 step_form">
                                <span v-if="isVisible == 'profile'">
                                    <!-- <button type="button" class="btn btn-primary"> Registration Details</button> -->
                                    <h4 style="font-size: 22px;"><b v-if="!$route.params.id" >Step 1/3:- </b> Member Registration</h4>
                                </span>
                                <h4 v-else-if="isVisible == 'credential' || isVisible == 'uid' || isVisible == 'role'">Update For Member: <i class="search_state_pin">{{ initialData.name }}</i> </h4>
                            </div>
                            <div class="col-xl-4 col-md-4 col-sm-4 col-4 text-end" v-if="$route.params.id">
                                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" class="btn-check" @change="getTabChange('profile')" name="btnradio" id="Profile" autocomplete="off" checked>
                                    <label class="btn btn-outline-primary mb-0" style="border-radius: 4px 0px 0px 4px;" for="Profile">Profile</label>

                                    <input type="radio" class="btn-check" @change="getTabChange('credential')" name="btnradio" id="Creadential" autocomplete="off">
                                    <label class="btn btn-outline-primary mb-0" for="Creadential">Credential</label>

                                    <input type="radio" class="btn-check" @change="getTabChange('uid')" name="btnradio" id="UID" autocomplete="off">
                                    <label v-if="$can('uid.list')" class="btn btn-outline-primary mb-0" for="UID">UID</label>

                                    
                                    <input type="radio" class="btn-check" @change="getTabChange('role')" name="btnradio" id="ROLE" autocomplete="off">
                                    <label v-if="$can('user.assign-role')" class="btn btn-outline-primary mb-0" for="ROLE">ROLE</label>
                                </div>
                                <!-- <button type="button" class="btn btn-primary" @click="isEdit = !isEdit">{{ isEdit?'Credential Update':'Profile Update' }}</button> -->
                            </div>
                        </div>
                    </div>
                    <!-- {{ initialData }} -->
                    <div class="panel-body" v-if="isVisible == 'profile'">
                        <Form @submit="onSubmit" :key="formUpdate" :initial-values="initialData"
                            :validation-schema="schemas" keep-values v-slot="{ handleSubmit, values }">
                            
                                <div class="row align-center">
                                    <SelectInput :error="errors.salutation" style="margin-top:3px;" name="salutation" :options="salutationOption" label="Title" />
                                    <TextInput :error="errors.first_name" name="first_name" label="First Name" type="text"
                                        required="required" placeholder="" />
                                    <TextInput :error="errors.last_name" name="last_name" label="Last Name" type="text"
                                        required="required" placeholder="" />
                                    <TextInput :error="errors.dob" name="dob" required="required" label="DOB" type="text"
                                        v-maska="'##-##-####'" placeholder="DD-MM-YYYY" />
                                    <div class="col-md-4">
                                        <label for="mobile">Mobile <span class="required"></span></label>
                                        <Field class="form-control" name="mobile" v-slot="{ field }">
                                        <vue-tel-input v-model="mobile"  v-bind="field" @input="getPhoneNumber"></vue-tel-input>
                                        </Field>
                                        <ErrorMessage class="error_alert" name="mobile" />
                                    </div>
                                    <SelectInput name="gender" :options="genderOption" required="required" label="Gender" />
                                    <!-- <TextInput :error="errors.mobile" name="mobile" type="tel" required="required"
                                        placeholder="" label="Mobile" /> -->
                                    <TextInput v-if="!$route.params.id" :error="errors.email" name="email" type="email" required="required"
                                        placeholder="" label="Email" />
                                    <TextInput v-if="!$route.params.id" :error="errors.password" name="password" type="password"
                                        :required="$route.params.id ? '' : 'required'" placeholder="*************"
                                        label="Password" />
                                </div>
                            <div class="row mt-2">
                                <div class="col-md-6">
                                    <!-- <router-link
                                        :to="{ name: 'member-list', params: { params: {} } }" type="button"
                                        class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="next_prev">
                                        <span v-if="!$route.params.id">
                                            <button :disabled="isLoading" type="submit"
                                                class="btn btn-primary">
                                                <span v-if="isLoading"
                                                    class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                                Register
                                            </button>
                                        </span>
                                        <span v-else>
                                            <span v-if="$can('member.update')">
                                                <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                                    <span v-if="isLoading" class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                                    Update
                                                </button>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <pre>{{ values }}</pre> -->
                        </Form>
                    </div>
                    <div class="panel-body" v-else-if="isVisible == 'credential'">
                        <Form @submit="onSubmit" :key="formUpdate" :initial-values="initialData"
                            :validation-schema="currentSchema" keep-values v-slot="{ handleSubmit, values }">
                                <div class="row align-center">
                                    <TextInput :error="errors.email" name="email" type="email" required="required"
                                        placeholder="" label="Email" />
                                    <TextInput :error="errors.password" name="password" type="password"
                                        :required="$route.params.id ? '' : 'required'" placeholder="*************"
                                        label="Password" />
                                        <div class="col-md-4">
                                            <label class="d-block">&nbsp;</label>
                                            <button v-if="$can('member.update')" :disabled="isLoading" type="submit" class="btn btn-primary mt-2">
                                                <span v-if="isLoading" class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                                 Update
                                            </button>
                                        </div>
                                </div>

                            <!-- <pre>{{ values }}</pre> -->
                        </Form>
                    </div>
                    <div class="panel-body" v-else-if="isVisible == 'uid'">
                        <uid></uid>
                    </div>
                    <div class="panel-body" v-else-if="isVisible == 'role'">
                        <roles></roles>
                    </div>
                </div>
            </div>

            <memberLatestList></memberLatestList>
        </div>
    </div>
</template>
  
<script setup>
import uid from '../components/uid.vue'
import roles from '../components/roles.vue'
import memberLatestList from '../components/memberLatestList.vue';
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import { ref, reactive, computed, onBeforeMount, onMounted, watch, inject } from 'vue';
import '@/assets/sass/scrollspyNav.scss';
// import highlight from '@/components/plugins/highlight.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useMeta } from '@/composables/use-meta';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import { useStore } from 'vuex';
const emitter = inject('emitter'); // Inject `emitter`
const store = useStore();
const route = useRoute();
const router = useRouter();
const $loading = useLoading({
    // options
});
useMeta({ title: 'Forms' });
const isLoading = ref(false);
const isVisible = ref('profile');
const param_id = ref('');
const errors = ref({});
const formUpdate = ref(0);
function getTabChange(val){
    console.log(val)
    isVisible.value = val;
}
const salutationOption = ref([
    { name: 'Mr.', value: 'Mr' },
    { name: 'Mrs.', value: 'Mrs' },
    { name: 'Miss.', value: 'Miss' },
    { name: 'Sd.', value: 'Sd' },
    { name: 'Sdn.', value: 'Sdn' }
]);

const genderOption = ref([
    { name: 'Male', value: 'M' },
    { name: 'Female', value: 'F' },
]);

const initialData = ref({
    edit_id: route.params.id,
    salutation: null,
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    mobile: null,
    dob: null,
    gender: null,
})

const isMobileValid = ref(null)
function getPhoneNumber(number, isValid, country) {
  if(isValid != undefined){
    isMobileValid.value = isValid.valid;
  };
};

const schemas =
    yup.object({
        first_name: yup.string().nullable().required('This field is required.'),
        last_name: yup.string().nullable().required('This field is required.'),
        email: yup.string().nullable().required('This field is required.').email(),
        gender: yup.string().nullable().required('This field is required.'),
        password: yup.string().nullable().test('password_edit', 'This field is required.', function (value, textContext) {  
            if (value == null || value == '') {
                if ((textContext.parent.id == '' || textContext.parent.id == undefined)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true
            }
        }).min(8, 'Your password is too short.'),
        mobile: yup.string().nullable().test('phone', 'The provided mobile number format is invalid.', function (value, textContext) {
            if((isMobileValid.value == undefined) || isMobileValid.value == true){
                return true;
            } else {
                return false;
            }
        }).required('This field is required.'),
        dob: yup.string().nullable().required('This field is required.'),
    });

onMounted(() => {
    const route = useRoute();
    const queryParam = route.params.id;
    if (queryParam !== undefined) {
        formUpdate.value += 1;
        param_id.value = queryParam;
        get_member();
    };
});
const routeTargetAfterUpdate = ref('');
watch(() => route.params, (newVal, oldVal) => {
    getTabChange('profile'); // Redirect on profile tab
    if(param_id.value){
        document.getElementById('Profile').click();
    }
    if (newVal.id != undefined) {
        param_id.value = newVal.id;
        routeTargetAfterUpdate.value = newVal.target;
        get_member();
    } else {
        resetFormData();
    }
}
);

const get_member = async () => {
    const loader = $loading.show({
        loader: 'spinner',
        width: "35",
        zIndex: 999,
    });
    store.dispatch('editMember', { id: param_id.value }).then((response) => {
        loader.hide();
        if (response.status == 200) {
            initialData.value = response.data;
            initialData.value.password = null;
            console.log(response.data)
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setTimeout(() => {
                formUpdate.value += 1;
            }, 100);
        }
    });
};
function onSubmit(values) {
    isLoading.value = true;
    if (values.mobile) {
        values['mobile'] = values.mobile.replace(/\s/g, '');
    }
    if (param_id.value) {
        values['id'] = param_id.value;
        store.dispatch('updateMember', values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                resetFormData();
                store.commit('memberEditData', {})
                window.showAlertMessage(response.data.message, 'success');
                if (routeTargetAfterUpdate.value == 'self') {
                    router.push('/member/create');
                } else {
                    router.push('/member/list');
                }
                isVisible.value = 'profile';
                errors.value = {}
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('saveMember', values).then((response) => {
            console.log(response.data.data)
            isLoading.value = false;
            if (response.status == 200) {
                handleReset();
                window.showAlertMessage(response.data.message, 'success');
                localStorage.setItem('memberCreateOrEditStatus','register');
                setTimeout(()=>{
                    router.push({path:'/member/multiple-location-create',query:{'member_id':response.data.data.id,'name':response.data.data.name}});
                },1000)
                errors.value = {}
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};

function handleReset() {
    resetFormData();
    errors.value = [];
}
function resetFormData() {
    emitter.emit('memberCreated', 'created');
    initialData.value = {};
    formUpdate.value += 1;
    param_id.value = '';
}
</script>
<style scoped>.next_prev {
    display: flex;
    justify-content: flex-end;
}

.next_prev button {
    margin-left: 10px;
}

.form-part {
    margin: 0px 0px 20px
}

.form-part h6 {
    font-weight: bold;
}

.step_form {
    display: flex;
    align-items: center;
}

.step_form button {
    margin-right: 0px;
    border-radius: 27px;
    font-size: 13px;
    padding: 7px 30px;
}


</style>
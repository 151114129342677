import router from '@/router';
import axios from 'axios';
const state = {
	memberEditData:{},
	// uidData:{
	// 	member_uid:[]
	// }
};
const getters = {
	
};
const mutations = {
	memberEditData(state,context){
		state.memberEditData = context;
	},
	// UID_DATA(state,payload){
	// 	state.uidData = payload;
	// },
	// SET_UID_DATA(state,payload){
	// 	state.uidData.member_uid = payload;
	// },
	// UID_DATA_REMOVE(state,payload){
	// 	state.uidData.member_uid = state.uidData.member_uid.map((el)=>{
	// 		if(el.member_uid_id != payload){
	// 			return el;
    //         }
	// 	}).filter((unused)=> unused != undefined)
	// }
};
const actions = {
	// MemberList api
	async getMemberList({ commit, dispatch,state }, getData) {
		console.log(getData)
		let api_url_country = '';
		if(getData.country_id){
			api_url_country +=`&country=${getData.country_id}`
		}
		let api_url_state = '';
		if(getData.state_id){
			api_url_state +=`&state=${getData.state_id}`
		}
		let api_url_city = '';
		if(getData.city_id){
			api_url_city +=`&city=${getData.city_id}`
		}
		let api_url_postal = '';
		if(getData.postal){
			api_url_postal +=`&postal=${getData.postal}`
		}
		let api_url_village = '';
		if(getData.v_id){
			api_url_village +=`&v_id=${getData.v_id}`
		}
		return await axios.get(`/dashboard/members?search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}${api_url_country}${api_url_state}${api_url_city}${api_url_postal}${api_url_village}`).then(response => {
			console.log(response)
			if(response.status == 200){
				var items = response.data.data.filter((item)=>{
					if(item.id == state.memberEditData.id){
						return item;
					}
				});
				// commit('memberEditData',items[0]);
			}
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async saveMember({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/member/create`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async deleteMember({ commit, dispatch }, member_id) {
		return await axios.delete(`/dashboard/member/trash/${member_id.id[0]}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editMember({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/member/edit/${getData.id}`).then(response => {
			console.log(response.data)
			commit('memberEditData',response.data);
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateMember({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/member/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getUIDListStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/uid/list?country_id=${getData}`).then(response => {
			console.log(response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getUIDUpadteStore({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/member/uid/update`, getData).then(response => {
			console.log(response)
			// commit('SET_UID_DATA',response.data.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getEditUIDStore({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/member/uid/edit/${id}`).then(response => {
			console.log(response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async deleteUIDStore({ commit, dispatch }, member_uid_id) {
		return await axios.delete(`/dashboard/member/uid/delete/${member_uid_id}`).then(response => {
			// commit('UID_DATA_REMOVE',member_uid_id)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async importMembers({ commit, dispatch }, getData) {
		console.log(getData)
		return await axios.post(`/dashboard/import/members-data`,getData,{
		  headers: {
			'Content-Type': 'multipart/form-data',
		  },
		}).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async downloadMemberSampleFile({ commit, dispatch }) {
		return await axios.get(`/dashboard/download/members-sample`).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async exportMemberData({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/download/members-data`,{ 
			params: getData,
		 }).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async getMemberUID({ commit, dispatch }, member_id) {
		return await axios.get(`/dashboard/member/uid/${member_id}`).then(response => {
			console.log(response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateMemberProfile({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/member/profile/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};

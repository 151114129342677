
<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Panchayat</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>List</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
  <div class="layout-px-spacing">
    <div class="panel-body" >
      <Transition name="fade">
        <searchPincode v-if="export_filter"></searchPincode>
      </Transition>
    </div>
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-spacing">
        <div class="layout-spacing">
          <div class="vl-parent panel br-6 p-0">
            <loading v-model:active="destinationLoading" :is-full-page="false" color="#515365" loader="spinner"
              :width="35" :z-index="9" />
            <div class="m-3 mb-0 custom_create_btn justify-content-between">
              <div class="refresh_table m-0">
                <h5 class="mb-0">Panchayat</h5>
                <a href="javascript:void(0);" @click="tableUpdate += 1">
                  <vue-feather type="rotate-cw" size="16"></vue-feather>
                </a>
              </div>
              <div class="panchayatTableButton mx-0">
                <button type="button" v-if="isSelectPanchayatList" class="btn btn-danger"
                  @click="delete_panchayat(panchayat_ids)"><vue-feather size="18" type="trash-2"></vue-feather></button>
                <button type="button" class="btn btn-primary" @click="export_filter =! export_filter">
                  Advance Filter & Export &nbsp;<vue-feather :type="!export_filter?'eye':'eye-off'" size="11"></vue-feather>
                </button>
                <span>
                  <router-link v-if="$can('panchayat.create')" title="Edit" class="btn btn-primary edit_button m-1"
                    :to="{ name: 'create-panchayat', params: {} }">
                    <vue-feather type="plus" size="18"></vue-feather> &nbsp; Panchayat
                  </router-link>
                </span>
                <!-- <button  @click="getMemberOfCurrentVillage(),$store.state.panchayat.panchayat_body_data = {}" type="button" class="btn btn-primary m-1"
                      data-bs-toggle="modal" data-bs-target="#panchayatModal"><vue-feather type="plus"
                        size="20"></vue-feather>&nbsp; Panchayat</button> -->
              </div>
            </div>
            <div class="modal fade" id="panchayatModal" tabindex="-1" role="dialog" aria-labelledby="panchayatModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                  <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="panchayatModalLabel">Update
                      {{ panchayatEditOf.type ? panchayatEditOf.type : '---' }} : <b>{{
                        panchayatEditOf.panchayatOf ? panchayatEditOf.panchayatOf : '---' }}</b></h5>
                    <button type="button" id="panchayatModalClose" data-dismiss="modal" data-bs-dismiss="modal"
                      aria-label="Close" class="btn-close"></button>
                  </div>
                  <div class="modal-body">
                    <edit-panchayat></edit-panchayat>
                    <!-- <div class="addedMemberTitle">
                          <form novalidate @submit.stop.prevent="addPanchayatBodyRow(panchayat_body_formdata)">
                            <div class="row align-center">
                              <div class="col-md-4">
                                <label for="">Select Member</label>
                                <multiselect v-model="panchayat_body_formdata.member" :options="memberOptions" label="name"
                                  :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Member"
                                  select-label="" deselect-label=""
                                  :class="[addPanchayatBody ? (panchayat_body_formdata.member ? '' : 'is-invalid') : '']">
                                </multiselect>
                                <div class="invalid-feedback">Please select the member</div>
                              </div>
                              <div class="col-md-4">
                                <label for="">Panchayat Title</label>
                                <select v-model="panchayat_body_formdata.title"
                                  :class="[addPanchayatBody ? (panchayat_body_formdata.title ? '' : 'is-invalid') : '']"
                                  class="form-control" name="gender" :options="genderOption" label="Select Gender">
                                  <option :value="op.value" v-for="op in panchayatTitleOption">{{ op.name }}</option>
                                </select>
                                <div class="invalid-feedback">Please select the title</div>
                              </div>
                              <div class="col-md-4 mt-auto">
                                <label for=""></label>
                                <button type="submit" class="btn btn-primary">Add</button>
                              </div>
                            </div>
                          </form>
                          <div class="row" v-show="$store.state.panchayat.panchayat_body_data.length > 0">
                            <div class="col-md-12">
                              <div class="mb-4">
                                <table class="table b-table table-hover table-bordered mb-0 mt-3">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Member</th>
                                      <th scope="col">Panchayat Title</th>
                                      <th scope="col" class="text-center">Remove</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, index) in  $store.state.panchayat.panchayat_body_data" :key="index">
                                      <th scope="row">{{ index }}</th>
                                      <td v-if="item.member_name">{{ item.member_name }}</td>
                                      <td>{{ item.title }}</td>
                                      <td class="text-center">
                                        <vue-feather @click="removePanchayatBodyRow(index)" type="trash-2" size="18"
                                          stroke="red"></vue-feather>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
  
                            </div>
                          </div>
                        </div>
                        <Form @submit="onSubmit" :key="componentKey" :initial-values="initialData" :validation-schema="schemas"
                          keep-values v-slot="{ handleSubmit, values }">
                          <div class="row align-center">
                            <TextInput name="period_start" label="Period start" type="date" placeholder="" />
                            <TextInput name="period_end" label="Period end" type="date" placeholder="" />
                            <SelectInput name="status" :options="statusOption" label="Select Status" />
                            <TextInput name="formation_description" cols="12" label="Formation Description" placeholder="" />
                          </div>
  
                          <div class="row mt-2">
                            <div class="col-md-6">
                            <router-link  :to="{ name: 'member-list', params: { params: {} } }" type="button" class="btn btn-danger">{{ $t('Back') }}</router-link>
                            </div>
                            <div class="col-md-6">
                              <div class="next_prev">
                                <span v-if="!param_id">
                                  <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                    <span v-if="isLoading"
                                      class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                    Save
                                  </button>
                                </span>
                                <span v-else>
                                  <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                    <span v-if="isLoading"
                                      class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                    Update
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Form> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-table destination-table">
              <v-server-table :key="tableUpdate" :columns="columns" :options="panchayatList"
                @select="(selectedAddRow = $event), checkAddRow()">
                <template #village="props">
                  <span v-if="props.row.panchayat_type == 'Gram Panchayat'" class="">{{
                    props.row.village ? props.row.village : '---' }}</span>
                  <span v-else-if="props.row.panchayat_type == 'Samiti Panchayat'" class="">{{
                    props.row.postal_code ? props.row.postal_code : '---' }}</span>
                  <span v-else-if="props.row.panchayat_type == 'ZilaParishad Panchayat'" class="">{{
                    props.row.city ? props.row.city : '---' }}</span>
                  <span v-else-if="props.row.panchayat_type == 'State Panchayat'" class="">{{
                    props.row.city ? props.row.city : '---' }}</span>
                </template>
                <template #member_count="props">
                  <span class="badge badge-light-secondary" v-if="props.row.panchayat_type == 'Gram Panchayat'">{{
                    props.row.gram_panchayat_members.length }}</span>
                  <span class="badge badge-light-secondary" v-else-if="props.row.panchayat_type == 'Samiti Panchayat'">{{
                    props.row.samiti_panchayat_members.length }}</span>
                  <span class="badge badge-light-secondary"
                    v-else-if="props.row.panchayat_type == 'ZilaParishad Panchayat'">{{
                      props.row.zila_panchayat_members.length }}</span>
                  <span class="badge badge-light-secondary"
                    v-else-if="props.row.panchayat_type == 'State Panchayat'">{{
                      props.row.state_panchayat_members.length }}</span>
                </template>
                <template #panchayat_type="props">
                  <span class="badge badge-light-secondary">{{ props.row.panchayat_type }}</span>
                </template>
                <template #period_start="props">
                  <span class="badge badge-light-secondary">{{ props.row.period_start }}</span>
                  -
                  <span class="badge badge-light-secondary">{{ props.row.period_end }}</span>
                </template>
                <template #status="props">
                  <span class="badge badge-light-success" v-if="props.row.status == 'active'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-warning" v-if="props.row.status == 'inactive'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-warning" v-if="props.row.status == 'hold'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-danger" v-if="props.row.status == 'expired'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                </template>
                <template #child_row="props" slot="tableHeader">
                  <div class="panel-body p-0">
                    <div class="panchayatBody mt-0">
                      <table class="table b-table table-hover table-bordered">
                        <thead class="bg-light sticky-top">
                          <tr>
                            <th>#</th>
                            <th>Member</th>
                            <th>Village</th>
                            <th>Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in  
                                  (props.row.panchayat_type == 'Samiti Panchayat') ? props.row.samiti_panchayat_members :
                                    (props.row.panchayat_type == 'Gram Panchayat') ? props.row.gram_panchayat_members :
                                      (props.row.panchayat_type == 'ZilaParishad Panchayat') ? props.row.zila_panchayat_members : []"
                            :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.member_name ? item.member_name : '---' }}</td>
                            <td>{{ item.village ? item.village : '---' }}</td>
                            <td>{{ item.title ? item.title : '---' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
                <template #action="props">
                  <div class="">
                    <router-link v-if="$can('panchayat.edit')" title="Edit" data-bs-toggle="modal" data-bs-target="#panchayatModal" @click="editPanchayatType(props.row.id, props.row.postal_code, props.row.panchayat_type,
                      (props.row.panchayat_type == 'Samiti Panchayat') ? props.row.postal_code :
                        (props.row.panchayat_type == 'Gram Panchayat') ? props.row.village :
                          (props.row.panchayat_type == 'ZilaParishad Panchayat') ? props.row.city : '---')"
                      class="edit_button mx-1" to="" tag="button">
                      <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                    </router-link>
                    <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                    <a href="javascript:void(0);" v-if="!isSelectPanchayatList && $can('panchayat.delete')" title="Delete" class="delete_button m-2"
                      @click="delete_panchayat([props.row.id])">
                      <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                    </a>
                  <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                  </div>
                </template>
              </v-server-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import searchPincode from '../components/searchPincode.vue';
import editPanchayat from '../components/editPanchayat.vue';
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed, inject, onBeforeUnmount} from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const store = useStore();
const route = useRoute();
const selectedAddRow = ref([]);
const panchayat_ids = ref([]);
const isSelectPanchayatList = ref(false);
const export_filter = ref(false)

const panchayatEditOf = ref({})
useMeta({ title: 'Village' });
const { t } = useI18n();

const columns = ref([
  'village',
  'member_count',
  'panchayat_type',
  'period_start',
  'status',
  "action"
]);
const tableUpdate = ref(0);
const destinationLoading = ref(false);
const panchayatList = ref({
  headings: {
    'member_count': 'Members Count',
    village: 'Village/Postal/City',
    panchayat_type: 'Panchayat Type',
    period_start: 'Period Date(Start-End)',
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 10,
  perPageValues: [10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['panchayat_type', 'status'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5, edge: true, show: true },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    destinationLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    const requestData = Object.assign({}, filterData.value, pagination);
    return store.dispatch(`getVillagePanchayatLists`, requestData).then((res) => {
      console.log(res)
      destinationLoading.value = false;
      return { data: res.data.data, count: res.data.total };
    });

  },
});
//  select multiple village
function checkAddRow() {
  panchayat_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (panchayat_ids.value.length != 0) {
    isSelectPanchayatList.value = true;
  } else {
    isSelectPanchayatList.value = false;
  }
}
function delete_panchayat(ids) {
  console.log(ids)
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deletePanchayatSamiti', { ids: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          window.showAlertMessage(response.data.message, 'success');
          handleReset();
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};

onBeforeMount(() => {
  store.commit(`SET_VILLAGE_DETAILS`, {});
});

const emitter = inject('emitter'); // Inject `emitter`
function editPanchayatType(panchayat_id, postal_code, panchayat_type, panchayatOf) {
  panchayatEditOf.value = { type: panchayat_type, panchayatOf: panchayatOf };
  emitter.emit('editPanchayat', { "panchayat_id": panchayat_id, "postal_code": postal_code, "panchayat_type": panchayat_type });
};
emitter.on('closePanchayatForm', () => {
  document.getElementById('panchayatModalClose').click();
  tableUpdate.value += 1;
});

const filterData = ref({});
emitter.on('filterList', (data) => {
  console.log(data)
  filterData.value = data;
  tableUpdate.value += 1;
});
// emitter.on('exportList', (data) => {
//   store.dispatch('exportMemberData', data).then((response) => {
//       console.log(response)
//       if (response.status == 200) {
//         // Create a Blob from the response data
//         const blob = new Blob([response.data], {
//           type: "text/csv",
//         });
//         // Create a link element to trigger the download
//         const link = document.createElement("a");
//         link.href = window.URL.createObjectURL(blob);
//         link.download = "MembersData.csv";
//         // Append the link to the document and trigger the download
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link); // Remove the link element
//       } else {
//         window.showAlertMessage(response.data.message, 'warning');
//       }
//     });
// });

onBeforeUnmount(() => {
  emitter.off('closePanchayatForm');
  emitter.off('exportList');
  emitter.off('filterList');
});

function handleReset() {
  tableUpdate.value += 1;
}
</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .panchayatTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.panchayatTableButton span {
  text-align: right;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}

.VueTables__child-row>td {
  padding: 0px !important;
}

.VueTables__child-row>td table {
  margin-bottom: 0px !important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.30);
}</style>   
  

<template>
  <div style="padding: 0 20px 0 18px !important">
    <div class="panel-body">
        <Transition name="fade">
        <searchPincode v-if="export_filter"></searchPincode>
      </Transition>
    </div>
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing pb-0">
        <div class="vl-parent panel br-6 p-0">
          <loading v-model:active="memberTableLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
          <div class="p-3 mb-0 custom_create_btn justify-content-between">
            <div class="refresh_table m-0">
              <h5 class="mb-0">Members</h5>
              <a href="javascript:void(0);" @click="recall += 1">
                <vue-feather type="rotate-cw" size="16"></vue-feather>
              </a>
            </div>
            <div class="memberTableButton mx-0">
              <button type="button" v-if="isSelectMemberList" class="btn btn-danger" @click="delete_member(member_ids)">
                <vue-feather type="trash-2" size="18"></vue-feather>
              </button>
              <button type="button" class="btn btn-primary" @click="export_filter =! export_filter">
                Advance Filter & Export &nbsp;<vue-feather :type="!export_filter?'eye':'eye-off'" size="11"></vue-feather>
              </button>
              <router-link v-if="$can('member.import')" :to="{ name: 'importCsv-member',query:{type:'memberCSV'} }" class="m-1"><button type="button"
                  class="btn btn-primary">Import Members</button> </router-link>
              <router-link v-if="$can('member.create')" :to="{ name: 'create-member' }"><button type="button" class="btn btn-primary m-1">Create</button>
              </router-link>
            </div>
          </div>

          <div class="custom-table destination-table">
            <v-server-table :columns="columns" :options="memberDataTable" :key="recall"
              @select="(selectedAddRow = $event), checkAddRow()">
              <template #name="props">
                <span>{{ props.row.name }}</span>
              </template>
              <template #roles="props">
                  <span v-if="props.row.roles.length != 0" v-for="role in  props.row.roles" :key="role.id">
                      <span class="badge badge-light-success" style="margin:1px;">{{ capitalizeString(role.name) }}</span>
                  </span>
                  <span v-else>N/A</span>
              </template>
              <template #age="props">
                <span class="" v-if="props.row.gender == 'M'">Male</span>
                <span class="" v-else>Female</span>
                <span class="" v-if="props.row.formated_dob">/{{ Math.floor((new Date() - new Date(props.row.formated_dob).getTime()) / 3.15576e+10)}}Y</span>
              </template>
              <template #location="props">
                <div style="width:175px" v-if="props.row.villages.length != 0">
                  <span class="d-block"><b>Village:</b> {{ props.row.villages[0].village }},</span>
                  <span class="d-block"><b>Postal Code:</b> {{ props.row.villages[0].postal_code }},</span>
                  <span class="d-block"><b>City:</b> {{ props.row.villages[0].city }},</span>
                  <span class="d-block"><b>State:</b> {{ props.row.villages[0].state }},</span>
                  <span class="d-block"><b>Country:</b> {{ props.row.villages[0].country }}</span>
                  <span class="d-inline-block badge badge-light-success" v-if="(props.row.villages.length-1) > 0"><b>+{{ props.row.villages.length-1 }} More ...</b></span>
                </div>
                <div style="width:175px" v-else>
                  <span class="d-block">N/A</span>
                </div>
              </template>
              <template #status="props">
                <span class="badge badge-light-success" v-if="props.row.status == 'active'">{{
                  capitalizeString(props.row.status) }}</span>
                <span v-else-if="props.row.status == 'inactive'" class="badge badge-light-danger">{{
                  capitalizeString(props.row.status) }}</span>
                <span v-else class="badge badge-light-secondary">{{ capitalizeString(props.row.status) }}</span>
              </template>
              <template #action="props">
                <div style="width:130px" class="action_button">
                  <a v-if="checkSuperAdminRole" href="javascript:void(0);" title="Switch user" class="delete_button mx-1"
                    @click="switch_user(props.row)">
                    <vue-feather v-if="switchUser[props.row.email]" type="rotate-cw" animation="spin" style="color:green" animation-speed="fast"></vue-feather>
                    <mdicon v-else name="account-switch" style="color:green"/>
                  </a>
                  <router-link v-if="props.row.villages.length == 0" title="Attach Village" class="edit_button mx-1" :to="{ name: 'create-member-multiple-location',query:{'member_id':props.row.id,'name':props.row.name} }">
                      <vue-feather type="map-pin" size="18" stroke="green"></vue-feather>
                  </router-link>
                 
                  <!-- <span class="mdi mdi-account-switch"></span> -->
                  <!-- <a data-bs-toggle="modal" data-bs-target="#addArticleModal"
                    href="javascript:void(0);" title="UID" class="delete_button m-2">
                    <vue-feather type="sidebar" size="18" stroke="orange"></vue-feather>
                  </a> -->
                  <router-link v-if="$can('member.edit')" title="Edit" class="edit_button mx-1"
                    :to="{ name: 'member-edit', params: { id: props.row.id, target: 'fullList' } }">
                    <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                  </router-link>
                  <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                  <a href="javascript:void(0);" v-if="!isSelectMemberList && $can('member.delete')" title="Delete" class="delete_button m-2"
                    @click="delete_member([props.row.id])">
                    <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                  </a>
                  <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                </div>
              </template>
            </v-server-table>
          </div>

          <div class="modal fade" id="addArticleModal" tabindex="-1" role="dialog" aria-labelledby="addArticleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl vl-parent" role="document">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">
                  <h5 class="modal-title" id="addArticleModalLabel">Update UID</h5>
                  <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                    id="articleModalCloseEvent" class="btn-close"></button>
                </div>
                <div class="modal-body">
                  <div>
                    <uid></uid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import uid from '../components/uid.vue'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeUnmount, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import searchPincode from '../components/searchPincode.vue';
import { useI18n } from 'vue-i18n';
const store = useStore();
const selectedAddRow = ref([]);
const member_ids = ref([]);
const memberTableLoading = ref(false);
const isSelectMemberList = ref(false)
const export_filter = ref(false)
useMeta({ title: 'Member' });
const { t } = useI18n();
const columns = ref([
  "name",
  "roles",
  "email",
  "mobile",
  "age",
  "location",
  "status",
  "action"
]);
onMounted(() => {

});
const checkSuperAdminRole = computed(()=>{
    if(store.state.auth.meDetails.roles.includes('super-admin') || store.state.auth.meDetails.roles.includes('admin')){
      return true;
    } else false;
  });
// function openUIDModel(data) {
//   store.commit('UID_DATA', data);
// }

const recall = ref(0);
const emitter = inject('emitter');

emitter.on('refreshMemberList', () => {   // *Listen* for event
  recall.value += 1;
});

emitter.on('exportList', (data) => {
  store.dispatch('exportMemberData', data).then((response) => {
      console.log(response)
      if (response.status == 200) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "text/csv",
        });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "MembersData.csv";
        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the link element
      } else {
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
});

const filterData = ref({});
emitter.on('filterList', (data) => {
  console.log(data)
  filterData.value = data;
  recall.value += 1;
});

onBeforeUnmount(() => {
  emitter.off('refreshMemberList');
  emitter.off('exportList');
  emitter.off('filterList');
});

const memberDataTable = ref({
  headings: {
    location: 'Location',
    age: 'Gender/Age'
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 10,
  perPageValues: [10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email', 'gender', 'mobile', 'age'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5, edge: true, show: true },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    memberTableLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'desc' : 'asc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    const requestData = Object.assign({}, filterData.value, pagination);
    return store.dispatch(`getMemberList`, requestData).then((res) => {
      memberTableLoading.value = false;
      res.data.data.map((row)=>{
        const dateParts = row.dob.split("-");
        row['formated_dob'] = `${dateParts[2]}-${dateParts[1]}-${dateParts[0].slice(-2)}`;
        return row;
      });
      return { data: res.data.data, count: res.data.total };
    });

  },
});
//  select multiple member
function checkAddRow() {
  member_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (member_ids.value.length != 0) {
    isSelectMemberList.value = true;
  } else {
    isSelectMemberList.value = false;
  }
}
function delete_member(ids) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteMember', { id: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          isSelectMemberList.value = false;
          recall.value += 1;
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};

const switchUser = ref({});
function switch_user(item) {
    var roles = item.roles.map((role)=>{
      return role.role;
    });
  new window.Swal({
    title: '',
    html: `Do you want to switch user? with ${(roles.length == 1)?'role':'roles'} <b>${roles}</b>`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Switch',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      switchUser.value[item.email] = true;
      store.dispatch('switchUserApi', { email:item.email }).then((response) => {
        console.log(response)
        switchUser.value[item.email] = true;
          isSelectMemberList.value = false;
          recall.value += 1;
          window.showAlertMessage(response.data.message, 'success');
      }).catch((err)=>{
        isSelectMemberList.value = false;
        window.showAlertMessage(err.response.data.message, 'warning');
      });
    }
  });
};
</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .memberTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}
.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
    color: #fff!important;
    background-color: #EAAD20!important;
    border-color: #EAAD20;
}
</style>   
  
import router from '@/router';
import axios from 'axios';
const state = {
	stateDetail:{},
	pincodeMemberList:[],
	// pincodeVillageList:[],
	is_pincode_panchayat:[],
	panchayats_body:[],
	country_state:{}
};
const getters = {
	
};
const mutations = {
	STATE_DETAIL(state,context){
		state.stateDetail = context;
	},
	COUNTRY_STATE_ID(state,context){
		console.log(context)
		state.country_state = context;
	},
};
// panchayat api
const actions = {
	// async getPanchayatSamitiList({ commit, dispatch }, getData) {
	// 		return await axios.get(`/dashboard/panchayats?postal_code=${getData.postal_code}&search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
	// 			console.log(response.data)
	// 			if(response.data.data.length > 0){
	// 				commit('IS_PINCODE_PANCHYAT',response.data.data[0]);
	// 				commit('ADD_PANCHAYAT_BODY_UPDATE',response.data.data[0].gram_panchayat_members);
	// 			}else{
	// 				commit('IS_PINCODE_PANCHYAT',[]);
	// 				commit('ADD_PANCHAYAT_BODY_UPDATE',[]);
	// 			}
	// 			return (response);
	// 		}).catch(error => {
	// 			return error.response;
	// 		});
	// },
	async getStatePanchayatOfState({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/panchayat/statepanchayat?state=${getData.state_id}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getCityOfSelectStateForStatePanchayat({ commit, dispatch }, getData) {
		console.log(getData)
			return await axios.get(`/dashboard/cities?country_id=${getData.country_id}&state_id=${getData.state_id}`).then(response => {
				console.log(response.data.data)
				return (response);
			}).catch(error => {
				return error.response;
			});
	},
	async getPincodeOfSelectCityForStatePanchayat({ commit, dispatch }, getData) {
		console.log(getData)
			return await axios.get(`/dashboard/postalcodes?country_id=${getData.country_id}&state_id=${getData.state_id}&city_id=${getData.city_id}`).then(response => {
				console.log(response.data.data)
				return (response);
			}).catch(error => {
				return error.response;
			});
	},
	async getMemberOfSelectVillageForStatePanchayat({ commit, dispatch }, village_id) {
		console.log(village_id)
		return await axios.get(`/dashboard/panchayats/members?p_type=zila&v_id=${village_id}`).then(response => {
			// return await axios.get(`/dashboard/panchayats/select/members/${village_id}`).then(response => {
				console.log(response.data.data)
				// commit('PINCODE_VILLAGE_LIST',response.data.data)
				return (response);
			}).catch(error => {
				return error.response;
			});
	},
	// async getMemberOfSelectVillage({ commit, dispatch }, village_id) {
	// 		return await axios.get(`/dashboard/village/select/members/${village_id}`).then(response => {
	// 			return (response);
	// 		}).catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async getSearchPincode({ commit, dispatch }, data) {
	// 	return await axios.get(`dashboard/postalcodes?city_id=${data.city_id}&country_id=${data.country_id}&state_id=${data.state_id}`).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async editPanchayatSamitiRow({ commit, dispatch }, getData) {
	// 	return await axios.get(`/dashboard/panchayat/edit/${getData.id}`).then(response => {
	// 		console.log(response)
    //         if(response.data.panchayat_type == 'Samiti Panchayat'){
    //             commit('PANCHAYATS_BODY',response.data.samiti_panchayat_members)
    //         }
    //         if(response.data.panchayat_type == 'Gram Panchayat'){
    //             commit('PANCHAYATS_BODY',response.data.gram_panchayat_members)
    //         }
    //         if(response.data.panchayat_type == 'ZilaParishad Panchayat'){
    //             commit('PANCHAYATS_BODY',response.data.zila_panchayat_members)
    //         }
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async deletePanchayatSamiti({ commit, dispatch }, data) {
	// 	console.log(data.ids)
	// 	return await axios.delete(`/dashboard/panchayat/trash/${data.ids}`).then(response => {
	// 		return (response);
	// 	}).catch(error => {
	// 		return error.response;
	// 	});
	// },
};
export default {
	state,
	mutations,
	actions,
	getters,
};

import requestService from '../../views/panchayat/request-service/request-service.vue';
import subEducation from '../../views/panchayat/request-service/sub-education.vue';
import educationForm from '../../views/panchayat/request-service/educationForm.vue';

export default [
	{
		path: '/request-service',
		name: 'request-service',
		component: requestService,
		meta: { requiresAuth: true }
	},
	{
		path: '/education',
		name: 'education',
		component: subEducation,
		meta: { requiresAuth: true }
	},
	{
		path: '/educationForm',
		name: 'educationForm',
		component: educationForm,
		meta: { requiresAuth: true }
	},
];

import axios from 'axios';
import store from './store/index.js';
import router from '@/router';
// import { useStore } from 'vuex';
// var store = useStore();

export default function setup() {
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        const switch_user_token = localStorage.getItem('switch_user_token');
        // console.log(token)
        // const token = localStorage.getItem('token');
        
        if (token) {
            config.headers.Authorization = `Bearer ${switch_user_token?switch_user_token:token}`;
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });

    // After response status 401 get user logout
    var isCalled = true;
    axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        if (error.response.status === 401) {
            // store.dispatch('refereshToken',{username: store.getters.getUsername})
            if(localStorage.getItem('token') && isCalled){
                sessionExpired();
                console.log(isCalled)
                isCalled = false;
            }
            // console.log('token removed....')
            // localStorage.removeItem('token');
            // localStorage.removeItem('vuex');
            // store.commit('toggleLoggedStatus', false);
			// router.push('/auth/login');
            // sessionExpired();
        }
        return Promise.reject(error);
    });

    // ================= Auto auto logout if session expired =======================
    // const timeoutInMS = 60*60*1000; // 3 minutes -> 3 * 60 * 1000
    // let timeoutId;
    // console.log(store)

    // function handleInactive() {
    //     // Here you want to logout a user and/or ping your token
    //     sessionExpired();
    // }

    // function startTimer() { 
    //     // setTimeout returns an ID (can be used to start or clear a timer)
    //     timeoutId = setTimeout(handleInactive, timeoutInMS);
    // }

    // function resetTimer() { 
    //     clearTimeout(timeoutId);
    //     startTimer();
    // }

    // setupTimers();
    // function setupTimers () {
    //     console.log('setupTimers')
    //     document.addEventListener("keypress", resetTimer, false);
    //     document.addEventListener("mousemove", resetTimer, false);
    //     document.addEventListener("mousedown", resetTimer, false);
    //     document.addEventListener("touchmove", resetTimer, false);
    //     startTimer();
    // }

    const intervalId = setInterval(()=>{
        const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
        const expires_in = localStorage.getItem('expires_in');
        // console.log('Remaining Time',expires_in-currentTimeInSeconds);
        // console.log('check...')
        if(expires_in <= currentTimeInSeconds){
            clearInterval(intervalId);
            if(localStorage.getItem('token')){
                sessionExpired();
            }
        }
    },1000)
//   setTimeout(()=>{
//     sessionExpired();
//   },10000)
    function sessionExpired(){
        new window.Swal({
            title: 'Your session has been expired.',
            text: "Please goto login page for login again.",
            type: 'warning',
            closeOnClick: true,
            showCancelButton: false,
            confirmButtonText: 'GOTO',
            confirmButtonColor: '#e7515a',
            focusCancel: true,
            allowOutsideClick:false,
            padding: '2em',
          }).then((result) => {
            if (result.value) {
                store.dispatch('signOut').then((res) => {
                    console.log(res.status)
                    localStorage.removeItem('token');
					localStorage.removeItem('vuex');
					window.localStorage.clear();
					store.commit('SET_ME_DETAILS', {});
					store.commit('toggleLoggedStatus', false);
					router.push('/auth/login');
			        location.reload();
                });
            }
          });
    }
    
}
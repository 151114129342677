import memberMultipleLocation from '../../views/panchayat/forms/memberMultipleLocation.vue';
import memberMultipleLocations from '../../views/panchayat/tables/memberMultipleLocations.vue'
import createProfile from '../../views/panchayat/forms/createProfile.vue'
import map from '../../views/panchayat/forms/map.vue'
export default [
    {
		path: '/member/multiple-locations',
		name: 'member-multiple-locations',
		component: memberMultipleLocations,
		meta: { requiresAuth: true },
	},
	{
		path: '/member/multiple-location-create',
		name: 'create-member-multiple-location',
		component: memberMultipleLocation,
		meta: { requiresAuth: true },
	},
	{
		path: '/member/list/edit/:member_id/:village_id',
		name: 'edit-member-multiple-location',
		component: memberMultipleLocation,
		meta: { requiresAuth: true },
	},
	{
		path: '/member/profile',
		name: 'member-profile',
		component: createProfile,
		meta: { requiresAuth: true },
	},
	{
		path: '/map',
		name: 'map',
		component: map,
		meta: { requiresAuth: true },
	},
];

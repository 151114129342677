<template>
    <div class="vl-parent">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="addTagModalLabel">
                    Assign Role
                </h5>
            </div>
            <div class="modal-body">
                <loading v-model:active="memberTableLoading" color="#515365" :is-full-page="false" loader="spinner"
                    :width="35" :z-index="9" />
                <Form @submit="onSubmit" :key="formReset" :initial-values="initialData" :validation-schema="schemas">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label v-for="(item, index) in rolesOptions" :key="index" :for="item.id"
                                    style="font-size: 17px; margin-right: 12px" class="form-check-label">
                                    <Field :id="item.roleId" class="form-check-input m-1" name="role" type="checkbox"
                                        :value="item.roleId" />{{ item.name }}
                                </label>
                                <!-- <ErrorMessage class="error_alert" name="role" /> -->
                                <div class="text-danger" v-for="err in errors.role">{{ err }}</div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="tag_action_btn">
                                <span>
                                    <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                        <span v-if="isLoading"
                                            class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                        Assign
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script setup>
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed, inject } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
const store = useStore();
import { useRoute, useRouter } from 'vue-router';
const rolesOptions = ref([]);
const isLoading = ref(false);
const uidTypeField = ref({});
const memberTableLoading = ref(false);
const componentKey = ref(0);
const emitter = inject('emitter'); // Inject `emitter`

const initialData = ref({
    role: [],
});

const schemas = yup.object().shape({
    // role: yup.array().required('At least one item is required'),
});
const params_id = ref('');
 onMounted( async () => {
    const route = useRoute();
    console.log(route.params.id)
    params_id.value = route.params.id
    memberTableLoading.value = true;
    await store.dispatch('getRoles').then((res) => {
        memberTableLoading.value = false;
        rolesOptions.value = res.data.data;
    });
    initialData.value.role =  store.state.member.memberEditData.roles.map((data) => {
        return data.id;
    });
});
const errors = ref({})
function onSubmit(values) {
    isLoading.value = true;
    values["memberId"] = [params_id.value];
    console.log(values)
    store.dispatch("assignUsersRoles", values).then((response) => {
        console.log(response)
        isLoading.value = false;
        if (response.status == 200) {
            emitter.emit('memberCreated', 'created');
            window.showAlertMessage(response.data.message, "success");
        } else {
            errors.value = response.data.errors
            window.showAlertMessage(response.data.message, "warning");
        }
    });
}


</script>
<style>
.add-btn1 {
    margin-left: 20px;
    margin-top: 20px;
}

.panel .memberTableButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 13px 15px;
}

.switch.s-icons {
    width: 40px;
    height: unset;
}

.switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
    border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
    border: 2px solid #ff5f5f !important;
    background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
    border: 2px solid #1abc9c !important;
    background-color: #1abc9c !important;
}
</style> 
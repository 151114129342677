import byLawsList from '../../views/panchayat/tables/byLawsList.vue';
// import createLaws from '../../views/panchayat/forms/createLaws.vue';

export default [
	{
		path: '/bylaws/list',
		name: 'byLaws-list',
		component: byLawsList,
		meta: { requiresAuth: true }
	},
	// {
	// 	path: '/byLaws/create',
	// 	name: 'create-byLaws',
	// 	component: createLaws,
	// 	meta: { requiresAuth: true },
	// },
	// {
	// 	path: '/village/list/edit/:id',
	// 	name: 'village-edit',
	// 	component: createVillage,
	// 	meta: { requiresAuth: true },
	// },
	// {
	// 	path: '/village/import',
	// 	name: 'importCsv-village',
	// 	component: importCsv,
	// 	meta: { requiresAuth: true },
	// },
];

import router from '@/router';
import axios from 'axios';
const state = {
	panchayatEditData:{},
	villageMemberList:[],
	panchayat_body_data:[],
	is_village_panchayat:{},
	panchayat_type:''
};
const getters = {
	
};
const mutations = {
	panchayatEditData(state,context){
		state.panchayatEditData = context;
	},
	VILLAGE_MEMBER_LIST(state,context){
		state.villageMemberList = context;
	},
	ADD_PANCHAYAT_BODY(state,context){
		if(context !== undefined) {
			state.panchayat_body_data.push(context);
		} else {
			state.panchayat_body_data = [];
		} 
	},
	RESET_PANCHAYAT_BODY(state,context){
		state.panchayat_body_data = context;
	},
	REMOVE_PANCHAYAT_BODY(state,elementIndex){
		state.panchayat_body_data.splice(elementIndex, 1);
	},
	ADD_PANCHAYAT_BODY_UPDATE(state,context){
		state.panchayat_body_data = context;
	},
	SET_PANCHAYAT_TYPE(state,context){
		state.panchayat_type = context;
	},
	IS_VILLAGE_PANCHYAT(state,context){
		state.is_village_panchayat = context;
	}
};
// panchayat api
const actions = {
	async getVilageMemberList({ commit, dispatch }, village_id) {
		if(village_id){
			return await axios.get(`/dashboard/village/select/members/${village_id}`).then(response => {
				commit('VILLAGE_MEMBER_LIST',response.data.data)
				return (response);
			}).catch(error => {
				return error.response;
			});
		} else{
			commit('VILLAGE_MEMBER_LIST',[])
		}
	},
	async getVillagePanchayatLists({ commit, dispatch }, getData) {
		console.log(getData)
		let api_url_country = '';
		if(getData.country_id){
			api_url_country +=`&country=${getData.country_id}`
		}
		let api_url_state = '';
		if(getData.state_id){
			api_url_state +=`&state=${getData.state_id}`
		}
		let api_url_city = '';
		if(getData.city_id){
			api_url_city +=`&city=${getData.city_id}`
		}
		let api_url_postal = '';
		if(getData.postal){
			api_url_postal +=`&postal=${getData.postal}`
		}
		let api_url_village = '';
		if(getData.v_id){
			api_url_village +=`&v_id=${getData.v_id}`
		}
		return await axios.get(`/dashboard/panchayats?search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}${api_url_country}${api_url_state}${api_url_city}${api_url_postal}${api_url_village}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getVillagePanchayatList({ commit, dispatch }, getData) {
		// if(getData.village_id){
			return await axios.get(`/dashboard/panchayats?village_id=${getData.village_id}&search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
				console.log(response.data)
				if(response.data.data.length > 0){
					commit('IS_VILLAGE_PANCHYAT',response.data.data[0]);
					commit('ADD_PANCHAYAT_BODY_UPDATE',response.data.data[0].gram_panchayat_members);
				}else{
					commit('IS_VILLAGE_PANCHYAT',{});
					commit('ADD_PANCHAYAT_BODY_UPDATE',[]);
				}
				return (response);
			}).catch(error => {
				return error.response;
			});
		// }
	},
	async saveVillagePanchayat({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/panchayat`, getData).then(response => {
			console.log(response.data)
			commit('IS_VILLAGE_PANCHYAT',response.data.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async deleteVillagePanchayat({ commit, dispatch }, member_id) {
		console.log(member_id.ids)
		return await axios.delete(`/dashboard/panchayat/trash/${member_id.ids[0]}`,{data:member_id}).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editVillagePanchayats({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/panchayat/edit/${getData.id}`).then(response => {
			// commit('memberEditData',response.data); 
			console.log(response.data)
			commit('ADD_PANCHAYAT_BODY_UPDATE',response.data.gram_panchayat_members);
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateVillagePanchayat({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/panchayat`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getCountries({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/countries`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getState({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/states/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getcities({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/cities/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getSearchVillage({ commit, dispatch }, id) {
		return await axios.get(`dashboard/villages/list?city=${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};

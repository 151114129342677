import location from '../../views/panchayat/settings/location.vue';
import roles from '../../views/panchayat/settings/roles.vue';


export default [
	{
		path: '/settings/location',
		name: 'settings-location',
		component: location,
		meta: { requiresAuth: true }
	},
	{
		path: '/settings/roles',
		name: 'settings-roles',
		component: roles,
		meta: { requiresAuth: true, requiredPermissions:'user.list' }
	}
];

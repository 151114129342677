import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	// is_point_select:{}
};
const getters = {
};
const mutations = {

};
const actions = {
	async getPointDataList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/points`,{
            params:getData
        }).then(response => {
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async savePointStore({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/point/create`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updatePointStore({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/point/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},

	async deletePointStore({ commit, dispatch }, id) {
		return await axios.delete(`/dashboard/point/trash/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editPointStore({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/point/edit/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editPointLangStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/point/edit/${getData.id}?lang=${getData.lang}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	
};
export default {
	state,
	mutations,
	actions,
	getters,
};

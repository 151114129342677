function showAlertMessage(msg, type){
    const toast = window.Swal.mixin(
      {
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    toast.fire({ icon: type, title: msg, padding: '10px 20px' });
  };
  export default showAlertMessage;
<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 layout-spacing">
                <div class="panel lyc">
                    <div class="panel-body">
                        <h4>Import CSV File For Bulk Import</h4>
                        <br />
                        <button v-if="$can('member.sample-download') || $can('village.sample-download')" type="button" class="btn btn-primary" @click="downloadFile()">Download sample file</button><br /><br />
                        <br />
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="custom-file-container" data-upload-id="myFirstImage">
                                        <div class="d-flex justify-content-between">
                                            <label>UPLOAD FILE</label>
                                            <a @click="
                                                fileCsvUpload = '';
                                            errors = [];
                                            " href="javascript:void(0)" class="custom-file-container__image-clear" title="Clear file">Clear</a>
                                        </div>
                                        <label class="custom-file-container__custom-file">
                                            <input type="file" class="custom-file-container__custom-file__custom-file-input"
                                                accept=".csv,.txt" @change="FileUploadCsv($event)" />
                                            <span class="custom-file-container__custom-file__custom-file-control"></span>
                                        </label>
                                        <div class="custom-file-container__image-preview pxl_img"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- {{errors.length}} -->
                            <div v-if="errors.length != 0" class="errors_box col-md-6">
                                <div v-if="errors.file">
                                    <p class="error_alert" v-for="err in errors.file" :key="err.id">{{ err }}</p>
                                </div>
                                <div v-else>
                                    <div v-for="(err, index) in errors" :key="index">
                                        <strong>Error for row {{ err.row }}:</strong>
                                        <p v-for="(error, index) in err.errors" :key="index">{{ error }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <router-link type="button" :to="{ name: 'member-list' }"
                            class="btn btn-danger m-1">BACK</router-link>
                        <button type="button" class="btn btn-primary" @click="savefile()">IMPORT</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue';

import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/forms/file-upload-with-preview.min.css';
import highlight from '@/components/plugins/highlight.vue';
import FileUploadWithPreview from 'file-upload-with-preview';
import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
useMeta({ title: 'File Upload' });
const code_arr = ref([]);
const errors = ref([]);
const csvFile = ref([]);
const fileCsvUpload = ref([]);
const importMembersData = reactive({});
import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const queryParam = route.query;
onMounted(() => {
    console.log(route.query.type)
    new FileUploadWithPreview('myFirstImage', {
        images: {
            baseImage: require('@/assets/images/file-preview.png'),
            backgroundImage: '',
        },
    });
});

const downloadFile = () => {
    if(route.query.type == 'memberCSV'){
        store.dispatch('downloadMemberSampleFile').then((response) => {
            if (response.status == 200) {
                console.log(response);
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'member_sample.csv');
                document.body.appendChild(link);
                link.click();
            } else {
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else{
        store.dispatch('downloadVillageSampleFile').then((response) => {
            if (response.status == 200) {
                console.log(response);
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'village_sample.csv');
                document.body.appendChild(link);
                link.click();
            } else {
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};
const FileUploadCsv = (e) => {
    fileCsvUpload.value = e.target.files[0];
};

const savefile = () => {
    // importMembersData['file'] = fileCsvUpload.value;
    const formData = new FormData();
    formData.append('csv_file', fileCsvUpload.value);
    if(route.query.type == 'memberCSV'){
        store.dispatch('importMembers', formData).then((response) => {
            if (response.data.errors) {
                errors.value = response.data.errors;
            }
            if (response.status == 200) {
                window.showAlertMessage(response.data.message, 'success');
            } else {
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('importVillage', formData).then((response) => {
            if (response.data.errors) {
                errors.value = response.data.errors;
            }
            if (response.status == 200) {
                window.showAlertMessage(response.data.message, 'success');
            } else {
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};
</script>
<style>
.lyc {
    margin: 20px;
}

.errors_box {
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 12%);
}

.errors_box p {
    color: red;
    margin-bottom: 2px;
}

.pxl_img {
    height: 110px;
    width: 30%;
    margin-top: 30px;
}

.error_alert {
    color: red;
}
</style>
  

<template>
    <div class="layout-spacing">
      <div class="vl-parent panel br-6 p-0">
        <div class="p-2">
          <loading v-model:active="destinationLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
          <div class="row">
            <div class="col-md-8 my-auto">
              <ul class="list-group d-flex flex-row" style="float:left;" v-if="$route.name == 'create-panchayat'">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <b>Village:&nbsp;&nbsp;</b>
                  <span class="">{{ $store.state.village.villageData.village?$store.state.village.villageData.village:'...' }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <b>City:&nbsp;&nbsp;</b>
                  <span class="">{{ $store.state.village.villageData.city?$store.state.village.villageData.city:'...' }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <b>State:&nbsp;&nbsp;</b>
                  <span class="">{{ $store.state.village.villageData.state?$store.state.village.villageData.state:'...' }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <b>Country:&nbsp;&nbsp;</b>
                  <span class="">{{ $store.state.village.villageData.country?$store.state.village.villageData.country:'...' }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <b>Postal code:&nbsp;&nbsp;</b>
                  <span class="">{{ $store.state.village.villageData.postal_code?$store.state.village.villageData.postal_code:'...' }}</span>
                </li>
              </ul>
              <a class="mt-2" href="javascript:void(0);" @click="refreshList = tableUpdate += 1">
                <vue-feather type="rotate-cw" size="16"></vue-feather>
              </a>
            </div>
            <div class="col-md-4 text-end">
              <!-- <div class=""> -->
            <!-- <span> -->
              <!-- <span> -->
                <!-- <i v-show="Object.keys(getVillageData).length == 0">For enable button need to select panchayat type and village.</i> -->
                <!-- </span> -->
                <span v-if="(Object.keys(is_village_panchayat).length == 0)" class="d-block">
                  <i v-show="(villageMemberList.length<5)">Insufficient members, need atleast <b><span class="font-weight-bold">{{ 5- villageMemberList.length }}</span></b> members to create Gram panchayat.</i>
                </span>
                <!-- <button v-if="$route.name == 'create-panchayat'" :disabled="Object.keys(is_village_panchayat).length != 0 || villageMemberList.length<=3"
                  @click="getMemberOfCurrentVillage();$store.state.panchayat.panchayat_body_data = []" type="button" class="btn btn-primary m-1"
                  data-bs-toggle="modal" data-bs-target="#villagePanchayatModal">Create Panchayat</button> -->
                <button v-if="$can('panchayat.create')" :disabled="Object.keys(is_village_panchayat).length != 0 || villageMemberList.length<=4" 
                  @click="getMemberOfCurrentVillage();$store.state.panchayat.panchayat_body_data = []" type="button" class="btn btn-primary m-1"
                  data-bs-toggle="modal" data-bs-target="#villagePanchayatModal">Create Panchayat</button>
                <!-- </span> -->
            <!-- </div> -->
            </div>
          </div>
          <!-- <div class="villageTableButton" v-if="$route.name == 'create-panchayat'">
            
          </div> -->
          
        </div>
        <div class="modal fade" id="villagePanchayatModal" tabindex="-1" role="dialog"
          aria-labelledby="villagePanchayatModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl vl-parent" role="document">
            <loading v-model:active="panchayatLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title" v-if="!param_id" id="villagePanchayatModalLabel">Create Gram Panchayat</h5>
                <h5 class="modal-title" v-else id="villagePanchayatModalLabel">Update Gram Panchayat</h5>
                <button type="button" id="panchayatModalClose" data-dismiss="modal" data-bs-dismiss="modal"
                  aria-label="Close" class="btn-close"></button>
              </div>
              <div class="modal-body">
                <div class="addedMemberTitle">
                  <form novalidate @submit.stop.prevent="addPanchayatBodyRow(panchayat_body_formdata)">
                    <div class="row align-center">
                      <div class="col-md-4">
                        <label for="">Select Member</label>
                        <multiselect v-model="panchayat_body_formdata.member" :options="memberOptions" label="name"
                          :searchable="true" :loading="isMemberLoad" :preselect-first="true" selected-label="" placeholder="Select Member"
                          select-label="" deselect-label=""
                          :class="[addPanchayatBody ? (panchayat_body_formdata.member ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div class="invalid-feedback">Please select the member</div>
                      </div>
                      <div class="col-md-4">
                        <label for="">Panchayat Title</label>
                        <multiselect v-model="panchayat_body_formdata.title" :options="panchayatTitleOption" label="title"
                          :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Title"
                          select-label="" deselect-label="" :disabled="panchayatTitleOption.length == 0"
                          :class="[addPanchayatBody ? (panchayat_body_formdata.title ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div class="invalid-feedback">Please select the title</div>
                      </div>
                      <div class="col-md-4">
                        <label class="d-block" for="">&nbsp;</label>
                        <button type="submit" class="btn btn-primary">Add</button>
                      </div>
                    </div>
                  </form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-4">
                        <table class="table b-table table-hover table-bordered mb-0 mt-3">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Member</th>
                              <th scope="col">Panchayat Title</th>
                              <th scope="col" class="text-center">Remove</th>
                            </tr>
                          </thead>
                          <tbody  v-if="$store.state.panchayat.panchayat_body_data.length > 0">
                            <tr v-for="(item, index) in  $store.state.panchayat.panchayat_body_data" :key="index">
                              <th scope="row">{{ index+1 }}</th>
                              <td v-if="item.member_name">{{ item.member_name }}</td>
                              <td>{{ item.title }}</td>
                              <td class="text-center">
                                <vue-feather @click="removePanchayatBodyRow(index)" type="trash-2" size="18"
                                  stroke="red"></vue-feather>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
                <Form @submit="onSubmit" :key="formReset" :initial-values="initialData" :validation-schema="schemas"
                  keep-values v-slot="{ handleSubmit, values }">
                  <div class="row align-center">
                    <TextInput :error="errors.period_start" name="period_start" label="Period start" type="date" placeholder="" />
                    <TextInput :error="errors.period_end" name="period_end" label="Period end" type="date" placeholder="" />
                    <SelectInput name="status" :options="statusOption" label="Select Status" />
                    <TextInput :error="errors.formation_description" name="formation_description" cols="12" label="Formation Description" placeholder="" />
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-6">
                      <!-- <router-link  :to="{ name: 'member-list', params: { params: {} } }" type="button" class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                    </div>
                    <div class="col-md-6">
                      <div class="next_prev">
                        <span v-if="!param_id">
                          <button v-if="$can('panchayat.create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                            Save
                          </button>
                        </span>
                        <span v-else>
                          <button v-if="$can('panchayat.update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                            Update
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-table destination-table">
          <v-server-table :columns="columns" :options="panchayatList" :key="tableUpdate"
            @select="(selectedAddRow = $event), checkAddRow()">
            <template #village="props">
              <span class="badge badge-light-success" v-if="props.row.village">
                {{ props.row.village }}</span>
              <span v-else>---</span>
            </template>
            <template #status="props">
                  <span class="badge badge-light-success" v-if="props.row.status == 'active'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-warning" v-if="props.row.status == 'inactive'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-warning" v-if="props.row.status == 'hold'" style="text-transform: capitalize;">{{ props.row.status }}</span>
                  <span class="badge badge-light-danger" v-if="props.row.status == 'expired'" style="text-transform: capitalize;">{{ props.row.status }}</span>
            </template>
            <template #child_row="props" slot="tableHeader">
              <div class="panel-body p-0">
                <div class="">
                  <table class="table b-table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Member Name</th>
                        <th scope="col">Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in  props.row.gram_panchayat_members" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.member_name }}</td>
                        <td>{{ item.title }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <template #action="props">
              <div class="">
                <router-link v-if="$can('panchayat.edit')" title="Edit" data-bs-toggle="modal" data-bs-target="#villagePanchayatModal"
                  @click="editVillagePanchayat(props.row.id)" class="edit_button mx-1" to="" tag="button">
                  <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                </router-link>
                <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                <a href="javascript:void(0);" v-if="$can('panchayat.delete')" title="Delete" class="delete_button mx-1"
                  @click="delete_panchayat([props.row.id])">
                  <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                </a>
                <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
              </div>
            </template>
          </v-server-table>
        </div>
      </div>
    </div>
 
</template>
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed, inject, watch,onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
const store = useStore();
const route = useRoute();
const selectedAddRow = ref([]);
const isLoading = ref(false);
const panchayatLoading = ref(false);
const isMemberLoad = ref(false);
const addPanchayatBody = ref(false);
const memberOptions = ref([]);
const formReset = ref(0);
const errors= ref({});
useMeta({ title: 'Village' });
const { t } = useI18n();
const emitter = inject('emitter');

emitter.on('refreshPanchayatList',()=>{
  
  console.log('refresh panchayat list')
tableUpdate.value += 1;
});
onBeforeUnmount(() => {
    emitter.off('refreshPanchayatList');
});
// watch(()=>route.params.id, ()=> {
//     console.log(route.params)
//     if(route.params.id != undefined){
//       tableUpdate.value += 1;
//     }
// });
import { useLoading } from 'vue-loading-overlay';
const $loading = useLoading({
// options
});
const columns = ref([
  'village',
  'panchayat_type',
  'period_start',
  'period_end',
  'status',
  "action"
]);
const tableUpdate = ref(0);
const destinationLoading = ref(false);
const panchayatList = ref({
  headings: {
    'panchayat_type':'Panchayat Type',
    'period_start':'Period Start',
    'period_end':'Period End',
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5 },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    destinationLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    // if(route.params.id != undefined){
    //   pagination.village_id = route.params.id;
    // } else {
    //   console.log(store.state.village.villageData.id)
    // }
    pagination.village_id = store.state.village.villageData.id;
    // if(store.state.village.villageData.id != undefined){
      return store.dispatch(`getVillagePanchayatList`, pagination).then((res) => {
        console.log(res.data.data)
        destinationLoading.value = false;
        return { data: res.data.data, count: res.data.total };
      });
    // }

  },
});
const is_village_panchayat = computed(() => {
  return store.state.panchayat.is_village_panchayat;
});
function delete_panchayat(ids) {
console.log(ids)
new window.Swal({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Delete',
  focusCancel: true,
  padding: '2em',
}).then((result) => {
  if (result.value) {
    store.dispatch('deleteVillagePanchayat', { ids: Object.values(ids) }).then((response) => {
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  }
});
};
//// ======================= add panchayat ===============///
const panchayatTitleOption = ref([]);

const statusOption = ref([
{ name: 'Active', value: 'active' },
{ name: 'Inactive', value: 'inactive' },
{ name: 'Hold', value: 'hold' },
{ name: 'Expired', value: 'expired' }
]);

const initialData = ref({
edit_id: route.params.id,
period_start: null,
period_end: null,
status:null,
formation_description: null,
})

const schemas = yup.object().shape({
period_start: yup.string().nullable().required('This field is required.'),
period_end: yup.string().nullable().required('This field is required.'),
status: yup.string().nullable().required('This field is required.'),
formation_description: yup.string().nullable().required('This field is required.'),
});
const getVillageData = computed(() => {
if(store.state.village.villageData.village_id != undefined){
  tableUpdate.value += 1;
}
return store.state.village.villageData;
});
const villageMemberList = computed(() => {
  return store.state.panchayat.villageMemberList;
});

onMounted(() => {
  getMemberOfCurrentVillage();
  store.dispatch(`getPanchayatTitleList`).then((res) => {
      panchayatTitleOption.value = res.data.data;
    });
});
function getMemberOfCurrentVillage(){
  formReset.value += 1;
  initialData.value = {};
  panchayat_body_formdata.value = {
    member: '',
    title: ''
  };
  store.commit('ADD_PANCHAYAT_BODY');
  isMemberLoad.value = true;
  store.dispatch(`getVilageMemberList`, getVillageData.value.id).then((res) => {
    isMemberLoad.value = false;
    memberOptions.value = res.data.data;
  });
}

const panchayat_body_formdata = ref({
member: '',
title: ''
});
function addPanchayatBodyRow(row) {
console.log(row)
if(row.member == '' || row.title == ''){
  addPanchayatBody.value = true;
  return false;
}else{
  var newRow = {
    'member_name':row.member.name,
    'member_id':row.member.member_id,
    'title':row.title.title,
    'panchayat_title_id':row.title.panchayat_title_id
  };
  var checkStatus = store.state.panchayat.panchayat_body_data.map((item) => {
      if(item.member_name == newRow.member_name){  // if need || (item.title == newRow.title)
        return true;
      } return false;
    }).includes(true)
    if(checkStatus){
      window.showAlertMessage('"'+newRow.member_name+'" member already added', 'warning');
      return false;
    }
  store.commit('ADD_PANCHAYAT_BODY', newRow);
  panchayat_body_formdata.value = { member: '', title: '' };
  addPanchayatBody.value = false;
}
}
function removePanchayatBodyRow(id) {
new window.Swal({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  type: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Delete',
  focusCancel: true,
  padding: '2em',
}).then((result) => {
  if (result.value) {
    store.commit('REMOVE_PANCHAYAT_BODY', id);
  }
});
};
const param_id = ref('');
function editVillagePanchayat(panchayat_id) {
  getMemberOfCurrentVillage();
  addPanchayatBody.value = false;
  console.log('foo')
  panchayatLoading.value = true;
  param_id.value = panchayat_id;
  // const loader = $loading.show({
  //   loader: 'spinner',
  //   zIndex: 999,
  //   color: '#28a745'
  // });
  store.dispatch('editVillagePanchayats', { id: panchayat_id }).then((response) => {
    console.log(response)
    panchayatLoading.value = false;
    if (response.status == 200) {
      initialData.value = response.data;
      setTimeout(() => {
        formReset.value += 1;
      }, 100);
      // loader.hide();
    } else {
      // loader.hide();
    }
  });
};
// ================= Add / Update panchayat =======================
function onSubmit(values) {
if (store.state.panchayat.panchayat_body_data.length == 0) {
  window.showAlertMessage('Please fill panchayat body form', 'warning');
  return false
} else {
  values['panchayat_body'] = store.state.panchayat.panchayat_body_data;
}
isLoading.value = true;
if (param_id.value) {
  values['id'] = param_id.value;
  store.dispatch('updateVillagePanchayat', values).then((response) => {
    isLoading.value = false;
    if (response.status == 200) {
      handleReset();
      window.showAlertMessage(response.data.message, 'success');
    } else {
      errors.value = response.data.errors;
      window.showAlertMessage(response.data.message, 'warning');
    }
  });
} else {
  values['formation_type'] = "app-admins";  // Hidden data
  values['village_id'] = store.state.village.villageData.id;
  values['panchayat_type'] = store.state.panchayat.panchayat_type;
  values = {...values,...store.state.village.villageData}
  store.dispatch('saveVillagePanchayat', values).then((response) => {
    isLoading.value = false;
    if (response.status == 200) {
      handleReset();
      window.showAlertMessage(response.data.message, 'success');
    } else {
      errors.value = response.data.errors;
      window.showAlertMessage(response.data.message, 'warning');
    }
  });
}
}
function handleReset() {
errors.value = [];
formReset.value += 1;
tableUpdate.value += 1;
param_id.value = '';
initialData.value = {};
document.getElementById('panchayatModalClose').click();
}
</script>
<style>
.list-group-item {
    padding: 4px 6px!important;
}
.add-btn1 {
margin-left: 20px;
margin-top: 20px;
}

.panel .villageTableButton {
display: flex;
justify-content: space-between;
align-items: center;
margin: 13px 15px;
}
.villageTableButton span{
text-align: right;
}
.switch.s-icons {
width: 40px;
height: unset;
}

.switch.s-outline .slider {
border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
border: 2px solid #ff5f5f !important;
background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
border: 2px solid #1abc9c !important;
background-color: #1abc9c !important;
}</style>   

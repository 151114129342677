
<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Tag</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>List</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
  <div class="layout-px-spacing">
    <div class="vl-parent panel">
      <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
        <loading v-model:active="tableLoading" color="#515365" :is-full-page="false" loader="spinner" :width="35"
          :z-index="9" />
        <div class="refresh_table m-0">
          <a href="javascript:void(0);" @click="listUpdate = tableUpdate += 1">
            <vue-feather type="rotate-cw" size="16"></vue-feather>
          </a>
        </div>
        <div class="memberTableButton">
          <span v-if="$can('tag.create')">
            <button type="button" @click="resetFormData()" class="btn btn-primary m-1" data-bs-toggle="modal"
              data-bs-target="#addTagModal">Create Tag</button>
          </span>
        </div>
        <div class="modal fade" id="addTagModal" tabindex="-1" role="dialog" aria-labelledby="addTagModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl vl-parent" role="document">
            <loading v-model:active="formLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="addTagModalLabel"> {{param_id?'Update Tag':'Create Tag'}}</h5>
                <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" id="tagModalClose"
                  class="btn-close"></button>
              </div>
              <div class="modal-body">
                <Form @submit="onSubmit" :key="formReset" :initial-values="initialData" :validation-schema="schemas">
                  <div class="row">
                    <TextInput name="name" cols="4" required="required" label="Tag" type="text" :error="errors.name"
                      placeholder="" />
                    <div class="col-md-3">
                      <div class="tag_action_btn">
                        <span v-if="!param_id">
                          <button v-if="$can('tag.create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                            Create
                          </button>
                        </span>
                        <span v-else>
                          <button v-if="$can('tag.update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                            <span v-if="isLoading"
                              class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                            Update
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-table destination-table">
        <v-server-table :columns="columns" :options="memberOptions" :key="tableUpdate">
          <template #name="props">
            <span class="badge badge-light-success">{{ props.row.name }}</span>
          </template>
          <template #action="props">
            <div class="d-flex align-items-center">
              <router-link title="Edit" v-if="$can('tag.edit')" @click="editTag(props.row.id)" data-bs-toggle="modal"
                data-bs-target="#addTagModal" class="edit_button my-2" to="" tag="button">
                <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
              </router-link>
              <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
              <span v-if="$can('tag.delete')">
              <a href="javascript:void(0);" v-if="!isSelectMemberList" title="Delete" class="delete_button m-2"
                @click="deleteTag(props.row.id)">
                <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
              </a>
              <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
              </span>
            </div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import TextInput from '../components/TextInput.vue'
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { onMounted, ref, onBeforeMount, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from 'vue-i18n';
const tableLoading = ref(false);
const isSelectMemberList = ref(false);
const formLoading = ref(false)
const errors = ref({});
const formReset = ref(0);
const isLoading = ref(false);
useMeta({ title: 'Tag' });
const $loading = useLoading({
  // options
});
const param_id = ref('');
const { t } = useI18n();

watch(() => store.state.village.villageData, () => {
  handleReset();
});
const columns = ref([
  "name",
  "action"
]);
const tableUpdate = ref(0);
const memberOptions = ref({
  headings: {
    name: 'Tag Name',
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5, edge: true, show: true },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    tableLoading.value = true;
    var pagination = {};
    pagination['search'] = data.query
    pagination['sort_direction'] = data.direction
    pagination['sort_by'] = data.orderBy ? data.orderBy : 'id';
    pagination['page'] = data.page
    pagination['per_page'] = data.limit
    return store.dispatch(`getTagsStore`, pagination).then((res) => {
      tableLoading.value = false;
      return { data: res.data.data, count: res.data.total };
    });
  },
});

const initialData = ref({
  edit_id: null,
  name: null,
})

const schemas = yup.object().shape({
  name: yup.string().nullable().required('This field is required.'),
});

function editTag(tag_id) {
  formReset.value += 1;
  param_id.value = tag_id;
  formLoading.value = true;
  store.dispatch('editTagStore', tag_id).then((response) => {
    if (response.status == 200) {
      formLoading.value = false;
      initialData.value = response.data;
      initialData.value.edit_id = response.data.id;
      setTimeout(() => {
        formReset.value += 1;
      }, 100);
    } else {
    }
  });
};

function onSubmit(values) {
  isLoading.value = true;
  if (param_id.value) {
    values['id'] = param_id.value;
    store.dispatch('updateTagStore', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  } else {
    store.dispatch('saveTagStore', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  }
};

function deleteTag(id) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteTagStore', id).then((response) => {
        if (response.status == 200) {
          isSelectMemberList.value = false;
          handleReset();
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};

function handleReset() {
  errors.value = [];
  formReset.value += 1;
  tableUpdate.value += 1;
  document.getElementById('tagModalClose').click();

};
function resetFormData() {
  initialData.value = {
    name: null,
  };
  formReset.value += 1;
  param_id.value = '';
}
</script>
<style>
.tag_action_btn {
  margin-top: 35px;
}
</style>   
    
<template>
    <div :class="'col-md-'+cols">
        <div class="form-group">
            <label :for="name">{{ label }} <span :class="required"></span></label>
            <input class="form-control" cols="cols" :name="name" :error="error" :id="name" :type="type" :value="inputValue" :placeholder="placeholder"
                @input="handleChange" @blur="handleBlur" />
            <p class="error_alert" v-show="errorMessage">
                {{ errorMessage }}
            </p>
            <p class="error_alert" v-show="error">
                <span v-for="err in error">{{ err }}</span>
            </p>
        </div>
    </div>
</template>
<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    cols: {
        type: String,
        default: '4',
    },
    error: {
        type: String,
        default: '',
    },
    required: {
        type: String,
        required: '',
    },
});

const name = toRef(props, 'name');
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.value,
});
</script>


<style>

</style>

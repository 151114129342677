<template>
    <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Setting</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Roles</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
    <div class="layout-px-spacing">
        <div class="panel-body">
            <Transition name="fade">
                <roles-filter v-if="rolesPermissionFilter"></roles-filter>
            </Transition>
        </div>
        <div class="vl-parent panel">
            <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
                <loading v-model:active="memberTableLoading" color="#515365" :is-full-page="false" loader="spinner"
                    :width="35" :z-index="9" />
                <div class="refresh_table m-0">
                    <a href="javascript:void(0);" @click="listUpdate = tableUpdate += 1">
                        <vue-feather type="rotate-cw" size="16"></vue-feather>
                    </a>
                </div>
                <div class="memberTableButton">
                    <span>
                        <button type="button" v-if="isSelectMemberList" class="btn btn-danger mx-1"
                            @click="delete_member(member_ids)">
                            <vue-feather type="trash-2" size="18"></vue-feather>
                        </button>
                        <button v-if="isSelectMemberList && $can('user.update')" type="button" class="btn btn-primary mx-1" data-bs-toggle="modal"
                            data-bs-target="#addTagModal">
                            Assign Role
                        </button>
                        <button type="button" class="btn btn-primary mx-1"
                            @click="rolesPermissionFilter = !rolesPermissionFilter">
                            Advance Filter &nbsp;<vue-feather :type="!rolesPermissionFilter ? 'eye' : 'eye-off'"
                                size="11"></vue-feather>
                        </button>
                    </span>
                </div>

                <div class="modal fade" id="addTagModal" tabindex="-1" role="dialog" aria-labelledby="addTagModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-xl vl-parent" role="document">
                        <loading v-model:active="formLoading" :is-full-page="false" loader="spinner" :width="35"
                            :z-index="9" />
                        <div class="modal-content">
                            <div class="modal-header border-bottom-0">
                                <h5 class="modal-title" id="addTagModalLabel">
                                    Assign Role
                                </h5>
                                <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                                    id="tagModalClose" class="btn-close"></button>
                            </div>
                            <div class="modal-body">
                                <Form @submit="onSubmit" :key="formReset" :initial-values="initialData"
                                    :validation-schema="schemas">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label v-for="(item, index) in rolesOptions" :key="index" :for="item.id"
                                                    style="font-size: 17px; margin-right: 12px" class="form-check-label">
                                                    <Field :id="item.roleId" class="form-check-input m-1" name="role"
                                                        type="checkbox" :value="item.roleId" />{{ capitalizeString(item.name )}}
                                                </label>
                                                <ErrorMessage class="error_alert" name="role" />
                                            </div>
                                        </div>
                                        <div class="col-md-3" v-if="$can('user.assign-role')">
                                            <div class="tag_action_btn">
                                                <span>
                                                    <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                                        <span v-if="isLoading"
                                                            class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                                        Assign
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-role-table custom-table destination-table">
                <v-server-table :columns="columns" :options="rolesPermissionsOptions" :key="tableUpdate"
                    @select="(selectedAddRow = $event), checkAddRow()">
                    <template #roles="props">
                        <span v-if="props.row.roles.length != 0" v-for="role in  props.row.roles" :key="role.id">
                            <span class="badge badge-light-success" style="margin:1px;">{{ capitalizeString(role.name) }}</span>
                        </span>
                        <span v-else>N/A</span>
                    </template>
                    <template #name="props">
                            <span>{{ props.row.name }}</span>
                    </template>
                    <template #age="props">
                        <span class="" v-if="props.row.gender == 'M'">Male</span>
                        <span class="" v-else>Female</span>
                        <span class="">/{{ props.row.age }}Y</span>
                    </template>
                    <template #location="props">
                    <div style="width:150px">
                        <span class="d-block"><b>Village:</b> {{ props.row.village }},</span>
                        <span class="d-block"><b>Postal Code:</b> {{ props.row.postal_code }},</span>
                        <span class="d-block"><b>City:</b> {{ props.row.city }},</span>
                        <span class="d-block"><b>State:</b> {{ props.row.state }},</span>
                        <span class="d-block"><b>Country:</b> {{ props.row.country }}</span>
                    </div>
                    </template>
                    <template #status="props">
                        <span class="badge badge-light-success" v-if="props.row.status == 'active'">{{
                            capitalizeString(props.row.status) }}</span>
                        <span v-else-if="props.row.status == 'inactive'" class="badge badge-light-danger">{{
                            capitalizeString(props.row.status) }}</span>
                        <span v-else class="badge badge-light-secondary">{{ capitalizeString(props.row.status) }}</span>
                    </template>
                </v-server-table>
            </div>
        </div>
    </div>
</template>
<script setup>
import rolesFilter from '../components/searchPincode.vue';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { onMounted, ref, onBeforeMount, onBeforeUnmount, inject, computed, watch } from "vue";
import { useStore } from "vuex";
import { useMeta } from "@/composables/use-meta";
import { useRouter, useRoute } from "vue-router";
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from "vue-i18n";
const memberTableLoading = ref(false);
const selectedAddRow = ref([]);

const isSelectMemberList = ref(false);
const formLoading = ref(false);
const errors = ref({});
const formReset = ref(0);
const isLoading = ref(false);
const member_ids = ref([]);

const rolesOptions = ref([]);
const membersOptions = ref([]);
const rolesPermissionFilter = ref(false);

useMeta({ title: "Roles" });

const param_id = ref("");
const { t } = useI18n();

// watch(() => store.state.village.villageData, () => {
//     handleReset();
// }
// );
const emitter = inject('emitter');
const filterData = ref({});
emitter.on('rolesfilter', (data) => {
    console.log(data)
    filterData.value = data;
    tableUpdate.value += 1;
});
onMounted(() => {
    store.dispatch('getRoles').then((res) => {
        console.log(res)
        rolesOptions.value = res.data.data;
    });
});
onBeforeUnmount(() => {
    emitter.off('rolesfilter');
});
const columns = ref([
    "roles",
    "name",
    "email",
    "mobile",
    "age",
    "location",
    "status"
]);
const tableUpdate = ref(0);
const rolesPermissionsOptions = ref({
     headings: {
        age: "Gender/Age",
     },
    selectable: {
        mode: "multiple", // or 'multiple'
        selectAllMode: "page", // or 'page',
        programmatic: false,
    },
    columnsClasses: { actions: "actions text-center" },
    perPage: 5,
    perPageValues: [5, 10, 25, 50, 100],
    skin: "table",
    columnsClasses: { actions: "actions text-center" },
    sortable: [],
    sortIcon: {
        base: "sort-icon-none",
        up: "sort-icon-asc",
        down: "sort-icon-desc",
    },
    pagination: { nav: "scroll", chunk: 5, edge: true, show: true },
    texts: {
        count: t("Showing") + " {from} " + t("to") + " {to} " + t("of") + " {count}",
        filter: "",
        filterPlaceholder: "Search...",
        limit: "Results:",
    },
    resizableColumns: false,
    async requestFunction(data) {
        memberTableLoading.value = true;
        const pagination = data;
        const direction = data.ascending == 1 ? 'desc' : 'asc';
        data.direction = direction;
        data.orderBy = data.orderBy ? data.orderBy : 'id';
        const requestData = Object.assign({}, filterData.value, pagination);
        return store.dispatch(`getUsersList`, requestData).then((res) => {
            memberTableLoading.value = false;
            return { data: res.data.data, count: res.data.total };
        });

    },
});

const initialData = ref({
    role: [],
});

const schemas = yup.object().shape({
    role: yup.array().required('At least one item is required'),
});
//  select multiple member
function checkAddRow() {
    member_ids.value = selectedAddRow.value.map((el) => {
        return el.id;
    });
    if (member_ids.value.length != 0) {
        isSelectMemberList.value = true;
    } else {
        isSelectMemberList.value = false;
    }
}


function onSubmit(values) {
    isLoading.value = true;
    values["memberId"] = member_ids.value;
    console.log(values)
    store.dispatch("assignUsersRoles", values).then((response) => {
        console.log(response)
        isLoading.value = false;
        if (response.status == 200) {
            isSelectMemberList.value = false
            handleReset();
            window.showAlertMessage(response.data.message, "success");
        } else {
            errors.value = response.data.errors;
            window.showAlertMessage(response.data.message, "warning");
        }
    });
}

function delete_member(ids) {
    new window.Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      focusCancel: true,
      padding: '2em',
    }).then((result) => {
      if (result.value) {
        store.dispatch('deleteMember', {id:Object.values(ids)}).then((response) => {
          if (response.status == 200) {
            handleReset();
            window.showAlertMessage(response.data.message, 'success');
          } else {
            window.showAlertMessage(response.data.message, 'warning');
          }
        });
      }
    });
  };

function handleReset() {
    errors.value = [];
    formReset.value += 1;
    tableUpdate.value += 1;
    document.getElementById("tagModalClose").click();
}

</script>
<style>
.tag_action_btn {
    margin-top: 35px;
}
.custom-role-table .table td, .custom-role-table .table th {
    padding: 7px 10px;
}
</style>

<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Service</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Education</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 layout-spacing">
                <div class="panel lyc">
                    <div class="panel-body">
                        <h4>Education</h4>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="request_services">
                                    <ul>
                                        <li>
                                            <router-link to="/educationForm">High school</router-link><br>
                                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                                        </li>
                                        <li>
                                            <router-link to="/educationForm">intermediate</router-link><br>
                                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small></li>
                                        <li>
                                            <router-link to="/educationForm">SSC</router-link><br>
                                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small></li>
                                        <li>
                                            <router-link to="/educationForm">UPSEE</router-link><br>
                                            <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue';

import '@/assets/sass/scrollspyNav.scss';

import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
useMeta({ title: 'Request Service' });

import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const queryParam = route.query;
onMounted(() => {
});


</script>
<style>
.request_services{
    margin:20px 0px;
}
.request_services ul li{
    margin: 10px 0px;
    font-size: 16px;
    list-style: circle;
}
</style>
  
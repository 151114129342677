import router from '@/router';
import axios from 'axios';
const state = {

};
const getters = {
	
};
const mutations = {
	
};
const actions = {
	async getMultiLocationList({ commit, dispatch }, getData) {
		console.log(getData)
		return await axios.get(`/dashboard/member/location/list?member_id=${getData.member_id}&country=${getData.country}&search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
			console.log(response)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async saveMemberLocation({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/member/create/step2`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editMemberLocation({ commit, dispatch }, formData) {
		return await axios.get(`/dashboard/member/location/edit/${formData.member_id}/${formData.village_id}`).then(response => {
			console.log(response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateMemberLocation({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/member/location/update`, getData).then(response => {
			console.log(response)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async deleteMemberLocation({ commit, dispatch }, formData) {
		return await axios.delete(`/dashboard/member/location/delete/${formData.member_id}/${formData.village_id}`).then(response => {
			console.log(response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getPincodeLocation({ commit, dispatch }, getData) {
		console.log(getData)
		return await axios.get(`/dashboard/long_lati_location`,{
			params:getData
		}).then(response => {
			console.log(response)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};


<template>
  <div class="vl-parent panel">
    <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
      <loading v-model:active="memberTableLoading" color="#515365" :is-full-page="false" loader="spinner" :width="35"
        :z-index="9" />
      <div class="refresh_table m-0">
        <!-- <h6 class="mb-0">Village: 
              <span class="badge badge-light-success me-1">{{ $store.state.village.villageData.village }}</span>
            </h6> -->

        <div v-if="Object.keys($store.state.village.villageData).length != 0">
          <ul class="list-group d-flex flex-row" >
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <b>Country:&nbsp;&nbsp;</b>
              <span class="">{{ $store.state.village.villageData.country ? $store.state.village.villageData.country :
                '...'
              }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <b>State:&nbsp;&nbsp;</b>
              <span class="">{{ $store.state.village.villageData.state ? $store.state.village.villageData.state : '...'
              }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <b>City:&nbsp;&nbsp;</b>
              <span class="">{{ $store.state.village.villageData.city ? $store.state.village.villageData.city : '...'
              }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <b>Village:&nbsp;&nbsp;</b>
              <span class="">{{ $store.state.village.villageData.village ? $store.state.village.villageData.village :
                '...'
              }}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <b>Postal code:&nbsp;&nbsp;</b>
              <span class="">{{
                $store.state.village.villageData.postal_code ? $store.state.village.villageData.postal_code : '...'
              }}</span>
            </li>
          </ul>
        </div>
        <a href="javascript:void(0);" @click="tableUpdate += 1">
          <vue-feather type="rotate-cw" size="16"></vue-feather>
        </a>
        <!-- {{ $store.state.village.villageData }} -->
      </div>
      <div class="memberTableButton">
        <button type="button" v-if="isSelectMemberList" class="btn btn-danger"
          @click="delete_member(member_ids)"><vue-feather size="18" type="trash-2"></vue-feather></button>
        <span>
          <i v-show="Object.keys(getVillageDetails).length == 0">It takes a village to become a member.</i>
          <button type="button" @click="resetFormData()" :disabled="Object.keys(getVillageDetails).length == 0"
            class="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#addMemberModal">Create Member</button>
        </span>
      </div>
      <div class="modal fade" id="addMemberModal" tabindex="-1" role="dialog" aria-labelledby="addMemberModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl vl-parent" role="document">
          <loading v-model:active="memberEditLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title" id="addMemberModalLabel">{{param_id?'Update Member':'Add Member'}}</h5>
              <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" id="modalClose11"
                class="btn-close"></button>
            </div>
            <div class="modal-body">
              <Form @submit="onSubmit" v-slot="{ values }" :key="formUpdate" :initial-values="initialData" :validation-schema="schemas">
                <div class="row align-center">
                  <TextInput :error="errors.first_name" required="required" name="first_name" label="First Name"
                    type="text" placeholder="" />
                  <TextInput :error="errors.last_name" required="required" name="last_name" label="Last Name" type="text"
                    placeholder="" />
                  <TextInput :error="errors.dob" name="dob" required="required" label="DOB" type="text"
                    v-maska="'##-##-####'" placeholder="DD-MM-YYYY" />
                  <div class="col-md-4">
                    <label for="mobile">Mobile <span class="required"></span></label>
                    <Field class="form-control" name="mobile" v-slot="{ field }">
                      <vue-tel-input v-model="mobile"  v-bind="field" @input="getPhoneNumber"></vue-tel-input>
                    </Field>
                    <ErrorMessage class="error_alert" name="mobile" />
                  </div>
                  <SelectInput :error="errors.gender" required="required" name="gender" :options="genderOption"
                    label="Gender" />
                  <TextInput :error="errors.email" required="required" name="email" type="email" placeholder=""
                    label="Email" />
                  <TextInput :error="errors.password" :required="param_id ? '' : 'required'" name="password"
                    type="password" placeholder="*************" label="Password" />
                  <SelectInput :error="errors.married" name="married" :options="marriedOption" label="Married Status" />
                  <SelectInput :error="errors.community" name="community" required="" :options="communityOptions"
                    label="Community" />
                  <SelectInput :error="errors.region" name="region" required="" :options="regionOption"
                    label="Region" />
                  <SelectInput :error="errors.mother_language" name="mother_language" required="" :options="motherLanguageOption"
                    label="Mother Language" />
                  <SelectInput :error="errors.qualification" name="qualification" required="" :options="qualificationOption"
                    label="Qualification" />
                  <SelectInput :error="errors.occupation" name="occupation" required="" :options="occupationOption"
                    label="Occupation" />
                  <TextInput :error="errors.designation" required="" name="designation" label="Designation"
                    type="text" placeholder="" />
                  <TextInput :error="errors.user_address_details" required="" name="user_address_details"
                    label="User address details" type="text" placeholder="" />
                  <!-- <TextInput :error="errors.dob" name="dob" label="dob" type="number" placeholder="In year" /> -->
                </div>
                <div class="row mt-2">
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <div class="next_prev">
                      <span v-if="!param_id">
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Add Member
                        </button>
                      </span>
                      <span v-else>
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Update Member
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <pre>{{ values }}</pre> -->
                <!-- {{ getVillageDetails }} -->
              </Form>
            </div>
            <!-- <div class="modal-footer">
                          <button type="button" class="btn" data-dismiss="modal" data-bs-dismiss="modal"><i class="flaticon-cancel-12"></i> Discard</button>
                          <button type="button" class="btn btn-primary">Save</button>
                      </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="custom-table destination-table">
      <v-server-table :columns="columns" :options="memberOptions" :key="tableUpdate"
        @select="(selectedAddRow = $event), checkAddRow()">
        <template #gender="props">
          <span class="badge badge-light-success">{{ props.row.gender == 'M' ? 'Male' : 'Female' }}</span>
        </template>
        <template #age="props">
          <span class="badge badge-light-success">{{ props.row.age + ' Year' }}</span>
        </template>
        <template #mobile="props">
          <span class="badge badge-light-success">{{ props.row.mobile }}</span>
        </template>
        <template #qualification="props">
          <span>{{ props.row.qualification ? props.row.qualification : '---' }}</span>
        </template>
        <template #occupation="props">
          <span>{{ props.row.occupation ? props.row.occupation : '---' }}</span>
        </template>
        <template #action="props">
          <div class="d-flex align-items-center">
            <router-link title="Edit" @click="editVillageMember(props.row.member_id)" data-bs-toggle="modal"
              data-bs-target="#addMemberModal" class="edit_button my-2" to="" tag="button">
              <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
            </router-link>
            <a href="javascript:void(0);" v-if="!isSelectMemberList" title="Delete" class="delete_button m-2"
              @click="delete_member([props.row.member_id])">
              <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
            </a>
          </div>
        </template>
      </v-server-table>
    </div>
  </div>
</template>
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { onMounted, ref, onBeforeMount, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from 'vue-i18n';
const selectedAddRow = ref([]);
const member_ids = ref([]);
const memberTableLoading = ref(false);
const isSelectMemberList = ref(false);
const memberEditLoading = ref(false)
const errors = ref({});
const formUpdate = ref(0);
const isLoading = ref(false);
useMeta({ title: 'Village' });
const $loading = useLoading({
  // options
});
const mobile = ref(null);
const param_id = ref('');
const { t } = useI18n();

const getVillageDetails = computed(() => {
  return store.state.village.villageData;
});
watch(() => store.state.village.villageData, () => {
  handleReset();
});
const columns = ref([
  "name",
  "email",
  "mobile",
  'dob',
  'gender',
  'qualification',
  'occupation',
  "action"
]);
const tableUpdate = ref(0);
const memberOptions = ref({
  headings:{
    dob:'DOB',
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    // only: function(row) {
    //   return true // any condition
    // },
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5 },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    memberTableLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    pagination.village_id = store.state.village.villageData.id;
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    if (pagination.village_id) {
      return store.dispatch(`getSingleVillageMemberList`, pagination).then((res) => {
        memberTableLoading.value = false;
        store.dispatch('getVilageMemberList', getVillageDetails.value.id); // update village member list
        return { data: res.data.data, count: res.data.total };
      });
    }
    else {
      memberTableLoading.value = false;
      return { data: [], count: 0 };
    }
  },
});
//  select multiple member
function checkAddRow() {
  member_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (member_ids.value.length != 0) {
    isSelectMemberList.value = true;
  } else {
    isSelectMemberList.value = false;
  }
}
function delete_member(ids) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteMember', { id: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          isSelectMemberList.value = false;
          handleReset();
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};


// =================== Form script ================================

const regionOption = ref([
  { name: 'Asian', value: 'Asian' },
  { name: 'Europian', value: 'Europian' },
  { name: 'North American', value: 'North American' },
  { name: 'Australian', value: 'Australian' },
  { name: 'African', value: 'African' },
]);
const marriedOption = ref([
  { name: 'Married', value: 'married' },
  { name: 'Single', value: 'single' },
  { name: 'Widow', value: 'widow' },
  { name: 'Living In', value: 'live-in' },
  { name: 'Divorce', value: 'divorce' },
]);
const communityOptions = ref([
  { name: 'Hindu', value: 'Hindu' },
  { name: 'Muslim', value: 'Muslim' },
  { name: 'Sikhism', value: 'Sikhism' },
  { name: 'Christian', value: 'Christian' },
  { name: 'Budhism', value: 'Budhism' },
  { name: 'Jainism', value: 'Jainism' },
  { name: 'Other', value: 'Other' },
]);
const genderOption = ref([
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
]);
const motherLanguageOption = ref([
  { name: 'Hindi', value: 'hindi' },
  { name: 'English', value: 'english' },
]);
const qualificationOption = ref([
  { name: '10th', value: '10th' },
  { name: '12th', value: '12th' },
]);
const occupationOption = ref([
  { name: 'Housewife', value: 'Housewife' },
  { name: 'Salesman', value: 'Salesman' },
  { name: 'Driver', value: 'Driver' },
  { name: 'Singer', value: 'Singer' },
  { name: 'Postman', value: 'Postman' },
  { name: 'Doctor', value: 'Doctor' },
  { name: 'Teacher', value: 'Teacher' },
]);
const initialData = ref({
  edit_id: null,
  first_name: null,
  last_name: null,
  email: null,
  password: null,
  mobile: null,
  dob: null,
  community: null,
  gender: null,
  married: null,
  region: null,
  qualification: null,
  designation: null,
  occupation: null,
  mother_language: null,
  user_address_details: null
});
const isMobileValid = ref(null)
function getPhoneNumber(number, isValid, country) {
  if(isValid != undefined){
    isMobileValid.value = isValid.valid;
  };
};
const schemas = yup.object().shape({
  first_name: yup.string().nullable().required('This field is required.'),
  last_name: yup.string().nullable().required('This field is required.'),
  email: yup.string().nullable().required('This field is required.').email(),
  password: yup.string().nullable().test('password_edit', 'This field is required.', function (value, textContext) {
    // console.log(textContext.parent.edit_id)
    if (value == null) {
      if (textContext.parent.edit_id == '' || textContext.parent.edit_id == undefined) {
        return false;
      } else if (textContext.parent.edit_id) {
        return true;
      }
    } else {
      return true;
    }
    // if(value == null){
    //     if ((textContext.parent.member_id !== '' || textContext.parent.member_id != undefined)) {
    //         return true;
    //     }else {
    //         return false;
    //     }
    // }else{
    //     return true
    // }
  }),
  mobile: yup.string().nullable().test('phone', 'The provided mobile number format is invalid.', function (value, textContext) {
    console.log(isMobileValid.value)
    if((isMobileValid.value == undefined) || isMobileValid.value == true){
        return true;
    } else {
        return false;
    }
  }).required('This field is required.'),
  // mobile: yup.string().nullable().matches(/^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/, 'Invalid mobile number format').required('This field is required.'),
  dob: yup.string().nullable().required('This field is required.'),
  gender: yup.string().nullable().required('This field is required.'),
});

function editVillageMember(member_id) {
  formUpdate.value += 1;
  param_id.value = member_id;
  memberEditLoading.value = true;
  // const loader = $loading.show({
  //   loader: 'spinner',
  //   zIndex: 999,
  //   color:'#28a745'
  // });
  store.dispatch('editMember', { id: member_id }).then((response) => {
    if (response.status == 200) {
      memberEditLoading.value = false;
      initialData.value = response.data;
      initialData.value.edit_id = response.data.id;
      initialData.value.password = null;
      setTimeout(() => {
        formUpdate.value += 1;
      }, 100);
      // loader.hide();
    } else {
      // loader.hide();
    }
  });
};

function onSubmit(values) {
  console.log(values)
  isLoading.value = true;
  if (values.mobile) {
    values['mobile'] = values.mobile.replace(/\s/g, '');
  }
  values['village_id'] = store.state.village.villageData.id;
  if (param_id.value) {
    values['member_id'] = param_id.value;
    store.dispatch('updateMember', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  } else {
    store.dispatch('saveMember', values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  }
};
function handleReset() {
  // store.dispatch('getVilageMemberList',getVillageDetails.value.id); // update village member list
  errors.value = [];
  formUpdate.value += 1;
  tableUpdate.value += 1;
  document.getElementById('modalClose11').click();

};
function resetFormData() {
  initialData.value = {
    password: null,
  };
  formUpdate.value += 1;
  param_id.value = '';
}
</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .memberTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}

.custom_create_btn.addMemberInVillage {
  justify-content: space-between;
}

.next_prev {
  display: flex;
  justify-content: flex-end;
}

.next_prev button {
  margin-left: 10px;
}

.list-group-item {
  border: 1px dashed rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px !important;
  margin-right: 4px;
}</style>   
  
import router from '@/router';
import axios from 'axios';
import { customInstance,logoutInstance } from '../../main.js';
import store from '../index.js'
const state = {
	auth: {},
	meDetails:{}
};
const getters = {
	getUsername(state) {
		return state.auth.username;
	},
	// getMeDetails(state) {
	// 	return state.meDetails;
	// },
};
const mutations = {
	SAVE_AUTH(state, payload) {
		state.auth = payload;
	},
	// SAVE_LOGIN_IP(state, payload) {
	// 	state.auth.last_login_ip = payload;
	// },
	// SET_USERNAME(state, payload) {
	// 	state.auth.username = payload;
	// },
	SET_ME_DETAILS(state, payload) {
		state.meDetails = payload;
	},
};
const actions = {
	async signIn({ commit, dispatch }, getData) {
		// localStorage.setItem('token', 'response.data.data.access_token');
		return await customInstance.post(`/member/login`, getData)
			.then(response => {
				console.log(response)
				if(response.status == 200){
					localStorage.setItem('super_admin_email', getData.email);
					commit('SAVE_AUTH', response.data);
    				const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
					// localStorage.setItem('expires_in',currentTimeInSeconds + 60);
					localStorage.setItem('expires_in',currentTimeInSeconds + Math.round(response.data.expires_in/1000));
					localStorage.setItem('token', response.data.access_token);
					dispatch('getUserDetails');
				}
				return (response);
			})
			.catch(error => {
				return error.response;
			});
	},
	async getUserDetails({ commit }) {
		return await axios.get(`/auth/me`).then(response => {
			console.log(response.data)
			commit('SET_ME_DETAILS', response.data);
			setTimeout(()=>{location.reload();},1000)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async signOut({ commit, dispatch }) {
		return await logoutInstance.post(`/member/logout`).then(response => {
			if (response.status == 200) {
					localStorage.removeItem('token');
					localStorage.removeItem('vuex');
					window.localStorage.clear();
					commit('SET_ME_DETAILS', {});
					store.commit('toggleLoggedStatus', false);
					router.push('/auth/login');
				}
				return (response);
			})
			.catch(error => {
				return error.response;
			});
	},
	async switchUserApi({ commit, dispatch }, getData) {
		return await axios.post(`/auth/switchuser`, getData).then(response => {
			console.log(response)
			localStorage.setItem('switch_user_token',response.data.switch_user_token);
			dispatch('getUserDetails');
			return (response);
		})
			.catch(error => {
				throw error;
			});
	},
	async switchToSuperUserApi({ commit, dispatch }, getData) {
		localStorage.setItem('switch_user_token','');
		return await axios.post(`/auth/switchuser`, getData).then(response => {
			console.log(response)
			// localStorage.setItem('switch_user_token',response.data.switch_user_token);
			dispatch('getUserDetails');
			return (response);
		})
			.catch(error => {
				throw error;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};

<template>
    <Transition name="fade">
    <div v-if="tagSectionToggle" class="row layout-top-spacing">
        <div class="col-lg-7">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading">
                    <h4>Select Tags</h4>
                </div>
                <div class="panel-body select2">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Tags</label>
                                <multiselect 
                                    v-model="tags" 
                                    @search-change="searchTags"
                                    :multiple="true"
                                    track-by="name"
                                    label="name"
                                    :options="tagsOption" 
                                    :searchable="true"
                                    :preselect-first="false" 
                                    selected-label="" 
                                    placeholder="Search..." 
                                    :loading="isTags"
                                    select-label="" 
                                    deselect-label="">
                                </multiselect>
                            <p class="error_alert" v-if="is_tag_selected">Please select tag.</p>
                            </div>
                            <div class="">
                                <div v-for="item in isSelectedTags" :key="item.id" class="selected_tags edit badge badge-light-success">{{ item.name }}
                                    <mdicon class="pencil" name="pencil" @click="editTag(item.id)"></mdicon>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="">Module</label>
                                <select name="" v-model="modelValue" class="form-control" id="">
                                    <option value="By Laws">By Laws</option>
                                    <option value="jurisdiction" disabled>Jurisdiction</option>
                                </select>
                                <p class="error_alert" v-if="is_module_selected">Please select module.</p>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button tyle="button" @click="selectTags()" class="btn btn-primary" style="margin-top:35px;">Select</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading d-flex justify-contents-between">
                    <h4>{{!param_id?"Create":"Update"}} Tags</h4>
                    <button v-if="param_id" @click="editToUpdate()" class="badge badge-light-success border-0">Create Tag</button>
                </div>
                <div class="panel-body select2">
                    <Form @submit="onSubmit" :key="formReset" :initial-values="initialData" :validation-schema="schemas" keep-values
                        v-slot="{ handleSubmit, values }">
                        <div class="row">
                            <TextInput name="name" cols="9" required="required" label="Tag" type="text" :error="errors.name" placeholder="" />
                            <div class="col-md-3">
                                <div class="tag_action_btn">
                                    <span v-if="!param_id">
                                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Create
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Update
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <vue-feather v-show="!isTagEdit" type="rotate-cw" style="width:15px" animation="spin" animation-speed="fast"></vue-feather>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
    </Transition>
    <div class="row layout-top-spacing">
        <div class="col-lg-12">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-md-6 my-auto">
                            <div class="selected_tags">
                                <div class="">
                                    <b for="">Selected Tags:&nbsp;&nbsp;</b>
                                    <div v-if="isSelectedTags.length != 0" v-for="item in isSelectedTags" :key="item.id" class="selected_tags edit badge badge-light-success">{{ item.name }}</div>
                                    <span v-else>...</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 my-auto text-end">
                            <div class="tags_btn">
                                <button class="btn btn-primary" @click="tagSectionToggle = !tagSectionToggle">{{tagSectionToggle?'Hide Tags':'Show Tags'}}</button>
                                <button class="btn btn-warning m-1" :disabled="isSelectedTags.length == 0"  @click="resetSelectedData()">Reset Data</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import Vue3TagsInput from 'vue3-tags-input';
import TextInput from '../TextInput.vue'
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { ref, onMounted, computed, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const router = useRouter();
const route = useRoute();
const store = useStore();
const isTags = ref(false);
const tags = ref([]);
const modelValue = ref("");
const tagsOption = ref([]);
const errors = ref({});
const isLoading = ref(false);
const param_id = ref('');
const formReset = ref(0)
const tagSectionToggle = ref(false);
const isTagEdit = ref(true)
const is_tag_selected = ref(false);
const is_module_selected = ref(false);

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const initialData = ref({
    name: null
});
const schemas = yup.object().shape({
    name: yup.string().max(15).nullable().required('This field is required.').label('Name'),
});
// watch(()=>route.params.id, ()=> {   
//     if(route.params.id == undefined){
//         store.commit(`SET_VILLAGE_DETAILS`, {});
//         param_id.value = '';
//         initialData.value = {};
//         componentKey.value += 1;
//     }else{
//         param_id.value = route.params.id;
//         get_village(route.params.id);
//     }
// });
onMounted(() => {

    getTags();
});
const isSelectedTags = computed(() => {
    return store.state.tags.selectedTags;
});
function searchTags(query){
    console.log(query);
    getTags(query);
}
function getTags(query){
    store.dispatch('getTagsDropdownListStore',{'search':query}).then((res) => {
        console.log(res)
        if(res.status == 200){
            tagsOption.value = res.data.data;
            tags.value = isSelectedTags.value;
        }
    });
}
function editTag(id){
    isTagEdit.value = false;
    store.dispatch(`editTagStore`,id).then((res) => {
        isTagEdit.value = true;
        console.log(res.data)
        if(res.status == 200){
            initialData.value.name = res.data.name;
            param_id.value = res.data.id;
            formReset.value+=1;
        }
    });
}
function selectTags(){
    if(tags.value.length == 0){
        is_tag_selected.value = true;
    } else {
        is_tag_selected.value = false;
        if(modelValue.value == ""){
            is_module_selected.value = true;
        } else{
            store.commit('SELECTED_TAGS',tags.value);
            is_tag_selected.value = false;
            is_module_selected.value = false;
            window.showAlertMessage("Tags Selected Successfully.", 'success');
        }
    }
}
function onSubmit(values) {
    isLoading.value = true;
    if (param_id.value) {
        values['id'] = param_id.value;
        console.log(values)
        store.dispatch('updateTagStore', values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                errors.value = {};
                handleReset();
                window.showAlertMessage(response.data.message, 'success');
                // router.push({ path: '/village/list' });
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('saveTagStore', values).then((response) => {
            isLoading.value = false;
            console.log(response)
            if (response.status == 200) {
                errors.value = {};
                handleReset()
                window.showAlertMessage(response.data.message, 'success');
                initialData.value = response.data.data;
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};
function handleReset(){
    getTags();
    tags.value = [];
    initialData.value.name = null;
    formReset.value+=1;
}
function editToUpdate(){
    param_id.value = '';
    initialData.value.name = null;
    formReset.value+=1;
}
function resetSelectedData(){
    tags.value = [];
    modelValue.value = "";
    tagSectionToggle.value = false;
    store.commit('SELECTED_TAGS',[]);
    store.commit('IS_CONSTITUTION_SELECTED',{});
    store.commit('IS_ARTICLE_SELECTED',{});
    store.commit('IS_SECTION_SELECTED',{});
    // store.commit('IS_ARTICLE',[]);
    // store.commit('IS_SECTION',[]);
    window.showAlertMessage("Reset Data Successfully", 'success');
}
</script>
<style scoped>
.selected_tags.edit {
    border: 1px dashed rgba(40, 199, 111, 0.12);
    padding: 5px;
    position: relative;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 4px;
}

.selected_tags.edit .pencil {
    position: absolute;
    right: -11px;
    top: -10px;
    width: 13px;
    height: 13px;
    color: #fff;
    border-radius: 100px;
    background: #28c76f;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px rgba(40, 199, 111, 0.12);
}

.selected_tags.edit .pencil:hover {
    cursor: pointer;
}
.tag_action_btn{
    margin-top:35px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	selectedTags:[],
	language:
	[{value:'en',text : 'English'},
	{value:'es',text : 'Spanish'},
	{value:'ar',text : 'Arabic'},
	{value:'hi',text : 'Hindi'},
	{value:'zh',text : 'Chinese'},
	{value:'bn',text : 'Bengali'},
	{value:'ru',text : 'Russian'},
	{value:'pt',text : 'Portuguese'},
	{value:'ja',text : 'Japanese'},
	{value:'de',text : 'German'},
	{value:'fr',text : 'French'},
	{value:'ko',text : 'Korean'},
	{value:'tr',text : 'Turkish'},
	{value:'it',text : 'Italian'},
	{value:'nl',text : 'Dutch'},
	{value:'pl',text : 'Polish'},
	{value:'uk',text : 'Ukrainian'},
	{value:'vi',text : 'Vietnamese'},
	{value:'ro',text : 'Romanian'},
	{value:'ms',text : 'Malay'},
	{value:'th',text : 'Thai'},
	{value:'hu',text : 'Hungarian'},
	{value:'cs',text : 'Czech'},
	{value:'fi',text : 'Finnish'},
	{value:'el',text : 'Greek'},
	{value:'sv',text : 'Swedish'},
	{value:'da',text : 'Danish'},
	{value:'no',text : 'Norwegian'},
	{value:'he',text : 'Hebrew'},
	{value:'pl',text : 'Polish'},
	{value:'lt',text : 'Lithuanian'},
	{value:'hi',text : 'Hindi'},
	{value:'ur',text : 'Urdu'},
	{value:'pa',text : 'Punjabi'},
	{value:'te',text : 'Telugu'},
	{value:'ta',text : 'Tamil'},
	{value:'mr',text : 'Marathi'},
	{value:'gu',text : 'Gujarati'},
	{value:'kn',text : 'Kannada'},
	{value:'ml',text : 'Malayalam'},
	{value:'sw',text : 'Swahili'},
	{value:'af',text : 'Afrikaans'}]
};
const getters = {
	
};
const mutations = {
	SELECTED_TAGS(state,context){
		state.selectedTags = context;
	}
};
const actions = {
	async getTagsStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/tags`,{
            params:getData
        }).then(response => {
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getTagsDropdownListStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/tag/list`,{
            params:getData
        }).then(response => {
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async saveTagStore({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/tag/create`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateTagStore({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/tag/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},

	async deleteTagStore({ commit, dispatch }, id) {
		return await axios.delete(`/dashboard/tag/trash/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editTagStore({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/tag/edit/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	
};
export default {
	state,
	mutations,
	actions,
	getters,
};

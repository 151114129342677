<template>
    <!-- <div class="layout-px-spacing"> -->
    <div class="vl-parent panel">
        <div class="m-3 mb-0 custom_create_btn addMemberInVillage">
            <loading v-model:active="pointTableLoading" color="#515365" :is-full-page="false" loader="spinner" :width="35"
                :z-index="9" />
            <div class="refresh_table m-0">
                <div>Constitution: 
                    <b class="badge badge-light-success" v-if="isConstitutionCreated.name">{{ isConstitutionCreated.name }}</b>
                    <b v-else class="badge badge-light-success">...</b>
                </div>
                <div class="mx-2">Article: 
                    <b class="badge badge-light-success" v-if="isArticleCreated.title">{{ isArticleCreated.title }}</b>
                    <b v-else class="badge badge-light-success">...</b>
                </div>
                <div>Section: 
                <b class="badge badge-light-success" v-if="isSectionCreated.title">{{ isSectionCreated.title }}</b>
                <b v-else class="badge badge-light-success">...</b>
                </div>
                <a href="javascript:void(0);" @click="onClickList = tableUpdate += 1">
                    <vue-feather type="rotate-cw" size="16"></vue-feather>
                </a>
            </div>
            <div class="memberTableButton">
                <button type="button" v-if="isSelectPointList" class="btn btn-danger" @click="deletePoint(member_ids)">
                    <vue-feather size="18" type="trash-2"></vue-feather>
                </button>
                <span v-if="$can('point.create')">
                    <button type="button" @click="resetFormData()" class="btn btn-primary m-1" data-bs-toggle="modal"
                        data-bs-target="#addPointModal">
                        Create Points
                    </button>
                </span>
            </div>
            <div class="modal fade" id="addPointModal" tabindex="-1" role="dialog" aria-labelledby="addPointModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-xl vl-parent" role="document">
                    <loading v-model:active="pointEditLoading" :is-full-page="false" loader="spinner" :width="35"
                        :z-index="9" />
                    <div class="modal-content">
                        <div class="modal-header border-bottom-0">
                            <h5 class="modal-title" id="addPointModalLabel">
                                {{ param_id ? "Update Point" : "Create Point" }}
                            </h5>
                            <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                                id="pointModalCloseEvent" class="btn-close"></button>
                        </div>
                        <div class="modal-body">
                            <Form @submit="onSubmit" :key="formUpdate" :initial-values="initialData"
                                :validation-schema="schemas">
                                <div class="row align-center">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="section_id">Section<span class="required"></span></label>
                                            <Field id="section_id" :disabled="isSectionCreated.id" name="section_id"
                                                class="form-control" label="Select Article" as="select">
                                                <option :value="null" disabled selected>Select Article</option>
                                                <option v-for="item in pointOptions" :value="item.id">{{ item.title }}
                                                </option>
                                            </Field>
                                            <div v-if="errors.section_id">
                                                <p class="error_alert" v-for="err in errors.section_id" :key="err.id">
                                                    {{ err }}</p>
                                            </div>
                                            <ErrorMessage class="error_alert" name="section_id" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="title">Title<span class="required"></span></label>
                                            <Field class="form-control" name="title" type="text" placeholder="" as="textarea"/>
                                            <ErrorMessage class="error_alert" name="title" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                        <label for="contents">Content<span class="required"></span></label>
                                        <Field id="contents" name="content" class="form-control" label="Content" as="textarea"></Field>
                                        <div v-if="errors.content">
                                            <p class="error_alert" v-for="err in errors.content" :key="err.id">{{ err }}</p>
                                        </div>
                                        <ErrorMessage class="error_alert" name="content" />
                                        </div>
                                    </div>
                                    <TextInput cols="4" :error="errors.number" name="number" required="required"
                                        label="Number" type="number" placeholder="" />
                                    <TextInput cols="4" :error="errors.influence" name="influence" label="influence"
                                        type="text" placeholder="" />
                                    <TextInput cols="4" :error="errors.scripture_name" name="scripture_name"
                                        label="Scripture Name" type="text" placeholder="" />
                                    <TextInput cols="4" :error="errors.page_number" name="page_number" label="Page Number"
                                        type="number" placeholder="" />
                                    <TextInput cols="4" :error="errors.line_number" name="line_number" label="Line Number"
                                        type="number" placeholder="" />
                                    <TextInput cols="4" :error="errors.written_by" name="written_by" label="Written By"
                                        type="text" placeholder="" />
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <div class="next_prev">
                                            <span v-if="!param_id">
                                                <button v-if="$can('point.create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                                    <span v-if="isLoading"
                                                        class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                                    Save
                                                </button>
                                            </span>
                                            <span v-else>
                                                <button v-if="$can('point.update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                                    <span v-if="isLoading"
                                                        class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                                    Update
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <pre>{{ values }}</pre> -->
                                <!-- {{ getVillageDetails }} -->
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- create language modal -->
            <div class="modal fade" id="addPointLanguageModal" tabindex="-1" role="dialog" aria-labelledby="addLanguageModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl vl-parent" role="document">
                <loading v-model:active="articleLangEditLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
                <div class="modal-content">
                    <div class="modal-header border-bottom-0">
                        <h5 class="modal-title" id="addLanguageModalLabel">Point Update Language</h5>
                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="lang_created">
                                    <span><b>Created in Languages:</b> </span>
                                    <span>
                                        <span class="badge badge-light-success" v-for="lang in created_language" :key="lang">{{ lang.language }}</span>
                                    </span>
                                </div>
                            </div>
                        <div class="col-md-12">
                                <div class="form-group">
                                <label for="lang">Language<span class="required"></span></label>
                                <multiselect v-model="selectLangCode" :options="store.state.tags.language" label="text"
                                    :searchable="true" @select="editSectionLangFun" :preselect-first="true" selected-label="" placeholder="Select village"
                                    select-label="" deselect-label="">
                                </multiselect>
                                </div>
                            </div>
                        </div>
                        <Form @submit="onLanguageSubmit" :key="langFormUpdate" :initial-values="initialLangData" :validation-schema="langSchemas">
                            <div class="row align-center">
                            <TextInput cols="6" :error="errorsLang.influence" name="influence"
                                label="influence" type="text" placeholder="" />
                            <TextInput cols="6" :error="errorsLang.scripture_name" name="scripture_name" label="Scripture Name"
                                type="text" placeholder="" />
                            <div class="col-md-6">
                                <div class="form-group">
                                <label for="title">Title<span class="required"></span></label>
                                <Field class="form-control" name="title" type="text" placeholder="" as="textarea"/>
                                <ErrorMessage class="error_alert" name="title" />
                            </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <label for="contents">Content<span class="required"></span></label>
                                <Field id="contents" name="content" class="form-control" label="Content" as="textarea"></Field>
                                <div v-if="errorsLang.content">
                                    <p class="error_alert" v-for="err in errorsLang.content" :key="err.id">{{ err }}</p>
                                </div>
                                <ErrorMessage class="error_alert" name="content" />
                                </div>
                            </div>
                            <TextInput cols="6" :error="errors.written_by" name="written_by" label="Written By"
                                type="text" placeholder="" />
                            </div>
                            <div class="row mt-2">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <div class="next_prev">
                                <span v-if="!param_id">
                                    <button v-if="$can('point.edit')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                    <span v-if="isLoading"
                                        class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                    Save
                                    </button>
                                </span>
                                <span v-else>
                                    <button v-if="$can('point.delete')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                    <span v-if="isLoading"
                                        class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                    Update
                                    </button>
                                </span>
                                </div>
                            </div>
                            </div>
                            <!-- <pre>{{ values }}</pre> -->
                            <!-- {{ getVillageDetails }} -->
                        </Form>
                    </div>
                </div>
                </div>
            </div>
            <!-- end create language modal -->
        </div>
        <div class="custom-table destination-table">
            <v-server-table :columns="columns" :options="pointOption" :key="tableUpdate"
                @select="(selectedAddRow = $event), checkAddRow()">
                <template #title="props">
                    <span class="badge badge-light-success">{{ props.row.title }}</span>
                </template>
                <template #written_by="props">
                    <span class="badge badge-light-success">{{ props.row.written_by }}</span>
                </template>
                <template #page_number="props">
                    <span class="badge badge-light-success">{{ props.row.page_number }}</span>
                </template>
                <template #line_number="props">
                    <span class="badge badge-light-success">{{ props.row.line_number }}</span>
                </template>
                <template #number="props">
                    <span class="badge badge-light-success">{{ props.row.number }}</span>
                </template>
                <template #action="props">
                    <div class="d-flex align-items-center action_button">
                        <router-link v-if="$can('section.edit')" title="Edit" @click="editPoint(props.row.id)" data-bs-toggle="modal"
                            data-bs-target="#addPointModal" class="my-2" to="" tag="button">
                            <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                        </router-link>
                        <router-link title="Edit" data-bs-toggle="modal"
                            data-bs-target="#addPointLanguageModal" @click="editSectionLang(props.row.id)" class="" to="" tag="button">
                            <mdicon title="Language" class="text-success cursor-pointer" name="translate-variant" /> 
                        </router-link>
                        <a href="javascript:void(0);" v-if="!isSelectPointList && $can('section.delete')" title="Delete" class="delete_button"
                            @click="deletePoint(props.row.id)">
                            <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                        </a>
                    </div>
                </template>
            </v-server-table>
        </div>
    </div>
    <!-- </div>  -->
</template>
<script setup>
import TextInput from "../TextInput.vue";
import "@/assets/sass/scrollspyNav.scss";
import "@/assets/sass/components/tabs-accordian/custom-tabs.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { onMounted, ref, onBeforeMount, computed, watchEffect,inject } from "vue";
import { useStore } from "vuex";
import { useMeta } from "@/composables/use-meta";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "vue-loading-overlay";
const store = useStore();
const route = useRoute();
const router = useRouter();
import { useI18n } from "vue-i18n";
const selectedAddRow = ref([]);
const member_ids = ref([]);
const pointTableLoading = ref(false);
const isSelectPointList = ref(false);
const pointEditLoading = ref(false);
const errors = ref({});
const pointOptions = ref([]);
const formUpdate = ref(0);
const isLoading = ref(false);
const langFormUpdate = ref(0);
const selectLangCode = ref({value:'en',text:'English'});
const errorsLang = ref({});
const articleLangEditLoading = ref(false);
const created_language = ref([]);

useMeta({ title: "Point" });
const $loading = useLoading({
    // options
});

const param_id = ref("");
const { t } = useI18n();
onMounted(() => {
    updateSectionList();
});

function updateSectionList() {
    store.dispatch("getSectionDataList", {
        "search": "",
        "sort_by": "id",
        "page": 1,
        "per_page": 5
    }).then((response) => {
        if (response.status == 200) {
            pointOptions.value = response.data.data;
    }
    });
}
const isArticleCreated = computed(() => {
    return store.state.articles.is_article_select;
});
const isConstitutionCreated = computed(() => {
    return store.state.searchByLaw.is_constitution_select;
});
const isSectionCreated = computed(() => {
    return store.state.section.is_section_select;
});

const columns = ref([
    "title",
    "influence",
    "page_number",
    "line_number",
    "number",
    "scripture_name",
    "written_by",
    "action"
]);
const tableUpdate = ref(0);
const emitter = inject('emitter'); // Inject `emitter`

emitter.on('refreshPointList', (value) => {   // *Listen* for event
  tableUpdate.value += 1;
});
const pointOption = ref({
    headings: {
        scripture_name: "Scripture",
        page_number:"Page",
        line_number:"Line",
        written_by: "Written By",
        created_by:"Created By"
    },
    selectable: {
        mode: "multiple", // or 'multiple'
        // only: function(row) {
        //   return true // any condition
        // },
        selectAllMode: "page", // or 'page',
        programmatic: false,
    },
    columnsClasses: { actions: "actions text-center" },
    perPage: 5,
    perPageValues: [5, 10, 25, 50, 100],
    skin: "table",
    columnsClasses: { actions: "actions text-center" },
    sortable: ["title","section"],
    sortIcon: {
        base: "sort-icon-none",
        up: "sort-icon-asc",
        down: "sort-icon-desc",
    },
    pagination: { nav: "scroll", chunk: 5 },
    texts: {
        count: t("Showing") + " {from} " + t("to") + " {to} " + t("of") + " {count}",
        filter: "",
        filterPlaceholder: "Search...",
        limit: "Results:",
    },
    resizableColumns: false,
    async requestFunction(data) {
        console.log('foo1')
        pointTableLoading.value = true;
        var pagination = {};
        if(Object.keys(store.state.section.is_section_select).length !=0){
            pagination['sec_id'] = store.state.section.is_section_select.id;
        }
        pagination["search"] = data.query;
        pagination["sort_direction"] = data.direction;
        pagination["sort_by"] = data.orderBy ? data.orderBy : "id";
        pagination["page"] = data.page;
        pagination["per_page"] = data.limit;
        return store.dispatch(`getPointDataList`, pagination).then((res) => {
            console.log(res);
            pointTableLoading.value = false;
            return { data: res.data.data, count: res.data.total };
        });
    },
});
watchEffect(() => {
  console.log(store.state.section.is_section_select)
  tableUpdate.value += 1; // reset table on create new article
})
//  select multiple member
function checkAddRow() {
    member_ids.value = selectedAddRow.value.map((el) => {
        return el.id;
    });
    if (member_ids.value.length != 0) {
        isSelectPointList.value = true;
    } else {
        isSelectPointList.value = false;
    }
}
function deletePoint(ids) {
    new window.Swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        focusCancel: true,
        padding: "2em",
    }).then((result) => {
        if (result.value) {
            store.dispatch("deletePointStore", ids).then((response) => {
                if (response.status == 200) {
                    isSelectPointList.value = false;
                    emitter.emit('refreshSearchList'); // refresh search fields
                    handleReset();
                    window.showAlertMessage(response.data.message, "success");
                } else {
                    window.showAlertMessage(response.data.message, "warning");
                }
            });
        }
    });
}

const initialData = ref({
    "title": null,
    "section_id": null,
    "number": null,
    "content": null,
    "influence": null,
    "scripture_name": null,
    "page_number": null,
    "line_number": null,
    "written_by": null
});

const schemas = yup.object().shape({
    title: yup.string().nullable().required("This field is required."),
    section_id: yup.string().nullable().required("This field is required."),
    number: yup.string().nullable().required("This field is required."),
    content: yup.string().nullable().required("This field is required."),
});

const initialLangData = ref({
  "lang":"en",
  "title": null,
  "content": null,
  "influence": null,
  "written_by": null,
  "scripture_name": null
});

const langSchemas = yup.object().shape({
  title: yup.string().nullable().required("This field is required."),
  content: yup.string().nullable().required("This field is required."),
});

function editSectionLangFun(lang){
  selectLangCode.value = lang;
  editSectionForLang()
}

function editSectionLang(id){
  param_id.value = id;
  selectLangCode.value = {value:'en',text:'English'};
  editSectionForLang()
}

function editSectionForLang() {
  langFormUpdate.value += 1;
  articleLangEditLoading.value = true;
  var editData = {};
  editData['lang'] = selectLangCode.value.value;
  editData['id'] = param_id.value;
  store.dispatch("editPointLangStore", editData).then((response) => {
    if (response.status == 200) {
      articleLangEditLoading.value = false;
      initialLangData.value = response.data;
      created_language.value = response.data.translations;
      setTimeout(() => {
        langFormUpdate.value += 1;
      }, 100);
    }
  });
}

function onLanguageSubmit(values) {
  isLoading.value = true;
  values["language_code"] = selectLangCode.value.value;
  values["created_by"] = store.state.auth.auth.users.id;
  values["point_id"] = param_id.value;
  values["point_translation_id"] = param_id.value;
  values["tag_id"] = isSelectedTags.value;
  store.dispatch("updatePointStore", values).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        window.showAlertMessage(response.data.message, "success");
        editSectionForLang();
        tableUpdate.value += 1;
      } else {
        errorsLang.value = response.data.errors;
        window.showAlertMessage(response.data.message, "warning");
      }
    });
}

function editPoint(id) {
    formUpdate.value += 1;
    param_id.value = id;
    pointEditLoading.value = true;
    store.dispatch("editPointStore", id).then((response) => {
        if (response.status == 200) {
            pointEditLoading.value = false;
            initialData.value = response.data;
            setTimeout(() => {
                formUpdate.value += 1;
            }, 100);
        }
    });
}
const isSelectedTags = computed(() => {
    return store.state.tags.selectedTags.map((tag)=>{
        return tag.id;
    });
});
function onSubmit(values) {
    console.log(values);
    isLoading.value = true;
    values["created_by"] = store.state.auth.auth.users.id;
    values["tag_id"] = isSelectedTags.value;
    console.log(values)
    if (param_id.value) {
        values["id"] = param_id.value;
        store.dispatch("updatePointStore", values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                handleReset();
                window.showAlertMessage(response.data.message, "success");
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, "warning");
            }
        });
    } else {
        store.dispatch("savePointStore", values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                window.showAlertMessage(response.data.message, "success");
                handleReset();
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, "warning");
            }
        });
    }
}

function handleReset() {
    document.getElementById("pointModalCloseEvent").click();
    errors.value = [];
    formUpdate.value += 1;
    tableUpdate.value += 1;
}
function resetFormData() {
    updateSectionList();
    initialData.value = {
        "section_id": isSectionCreated.value.id,
        "title": null,
        "number": null,
        "content": null,
        "influence": null,
        "scripture_name": null,
        "page_number": null,
        "line_number": null,
        "written_by": null
    };
    formUpdate.value += 1;
    param_id.value = "";
}
</script>
<style>
</style>
  
<template>
    <div class="row layout-top-spacing mb-0">
        <div class="col-lg-12">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading">
                    <h4>Search</h4>
                </div>
                <div class="panel-body select2">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Constitution</label>
                                <multiselect v-model="selectConstitution" :options="constitutionOption" label="name"
                                    :searchable="true" :preselect-first="true" selected-label=""
                                    placeholder="Search..." :loading="isConstitution" select-label="" deselect-label=""
                                    @select="callForArticle($event)"></multiselect>
                                <!-- <vue-feather v-if="isConstitution" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather> -->
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Article</label>
                                <multiselect v-model="selectArticle"
                                    :options="articleOption" label="title" :searchable="true" :preselect-first="true"
                                    selected-label="" placeholder="Search..." select-label="" deselect-label=""
                                    @select="callForSection($event)" :loading="isArticle"></multiselect>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>Section</label>
                            <multiselect v-model="selectSection"
                                    :options="sectionOption" label="title" :searchable="true" :preselect-first="true"
                                    selected-label="" placeholder="Search..." select-label="" deselect-label=""
                                    @select="callForPoint($event)" :loading="isSection"></multiselect>
                        </div>
                        <div class="col-md-3">
                            <label for="" class="d-block">.</label>
                                <button :disabled="isEmpty(selectConstitution)" @click="selectSearchData" type="submit" class="btn btn-primary">
                                    <span v-if="isSearchLoading" class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                    SELECT
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed,inject,watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const store = useStore();
const selectConstitution = ref({});
const selectArticle = ref({});
const selectSection = ref({});
const isSearchLoading = ref(false)
const router = useRouter();
const route = useRoute();
const constitutionOption = ref([]);
const articleOption = ref([]);
const sectionOption = ref([]);
const pointOption = ref([]);

const isConstitution = ref(false);
const isArticle = ref(false);
const isSection = ref(false);
const isPoint = ref(false);

const emitter = inject('emitter'); // Inject `emitter`

onMounted(() => {
    // console.log(route.path.split('/')[2])
    isConstitution.value = true;
    store.dispatch('searchConstitutionList').then((res) => {
        constitutionOption.value = res.data.data;
        isConstitution.value = false;
    });

    callOnLoad();
});

watchEffect(() => {
    if(isEmpty(selectConstitution.value) && (!isEmpty(selectArticle.value) || !isEmpty(selectSection.value))){
        selectArticle.value = {};
        selectSection.value = {};
    }
}); // wacher check if constitution value empty then article and section value will empty

function callOnLoad(){
    selectConstitution.value = store.state.searchByLaw.is_constitution_select;
    if(!isEmpty(selectConstitution.value)){
        callForArticle(selectConstitution.value.id)
    }

    selectArticle.value = store.state.articles.is_article_select;
    if(!isEmpty(selectArticle.value)){
        callForSection(selectArticle.value.id)
    }

    selectSection.value = store.state.section.is_section_select;
    if(!isEmpty(selectSection.value)){
        callForPoint(selectSection.value.id)
    }
}

function isEmpty(n){
    return !(!!n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
}

function callForArticle(data) {
    console.log(data)
    selectArticle.value = {};
    selectSection.value = {};
    isArticle.value = true;
    store.dispatch(`searchArticleList`, {const_id:data.id}).then((res) => {
        articleOption.value = res.data.data;
        isArticle.value = false;
    });
}
function callForSection(data) {
    isSection.value = true;
    selectSection.value = {};
    store.dispatch(`searchSectionList`, {art_id:data.id}).then((res) => {
        sectionOption.value = res.data.data;
        isSection.value = false;
    });
}

function callForPoint(data) {
    isPoint.value = true;
    store.dispatch(`searchPointList`, {sec_id:data.id}).then((res) => {
        pointOption.value = res.data.data;
        isPoint.value = false;
    });
}
// const apiComonParams = ref({
//       "search": "",
//       "sort_by": "id",
//       "page": 1,
//       "per_page": 5
//   })
function selectSearchData() {
    isSearchLoading.value = true;
    setTimeout(()=>{
        isSearchLoading.value = false;
    },2000)
    console.log(selectConstitution.value);
    store.commit('IS_CONSTITUTION_SELECTED',selectConstitution.value);
    store.commit('IS_ARTICLE_SELECTED',selectArticle.value);
    store.commit('IS_SECTION_SELECTED',selectSection.value);

    // callArticleGetApi();
    // callSectionGetApi();
    
    if(!isEmpty(selectConstitution.value)){
        console.log('const')
        emitter.emit('refreshArticleList',selectConstitution.value.id);
    }
    if(!isEmpty(selectArticle.value)){
        emitter.emit('refreshSectionList',selectArticle.value.id);
    }
    if(!isEmpty(selectSection.value)){
        emitter.emit('refreshPointList',selectSection.value.id);
    }
}

emitter.on('refreshSearchList', (value) => {   // Reset serach field on delete data from any table
    console.log('foo')
    callOnLoad();
    //  selectConstitution.value = {};
    //  selectArticle.value = {};
    //  selectSection.value = {};
});
</script>
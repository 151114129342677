
<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Panchayat</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Create</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-md-12 col-12">
        <div class="panel br-4">
          <div class="panel-title">
            <h5 class="p-3">Panchayat Type</h5>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-8">
                <div class="input-group mb-4">
                  <div class="input-group-text">
                    <label class="switch s-primary mb-0">
                      <input type="checkbox" @change="getLockedStatus" v-model="lockPanchayatType" checked />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <select v-model="selectPanchayatType" @change="getSelectedPanchyatType()" :disabled="lockPanchayatType" class="form-control" placeholder="Switch">
                      <option disabled :value="null">Reset None</option>
                      <option v-if="getRoles == 'all' || getRoles == 'regional-admin' || getRoles == 'state-admin' || getRoles == 'area-admin' || getRoles == 'sub-area-admin'" value="Gram Panchayat">Gram Panchayat</option>
                      <option v-if="getRoles == 'all' || getRoles == 'regional-admin' || getRoles == 'state-admin' || getRoles == 'area-admin'" value="Samiti Panchayat">Samiti Panchayat</option>
                      <option v-if="getRoles == 'all' || getRoles == 'regional-admin' || getRoles == 'state-admin'" value="ZilaParishad Panchayat">ZilaParishad Panchayat</option>
                      <option v-if="getRoles == 'all' || getRoles == 'regional-admin'" value="State Panchayat">State Panchayat</option>
                      <option v-if="getRoles == 'all'" value="Regional Panchayat" disabled>Regional Panchayat</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <!-- :disabled="!selectPanchayatType"  -->
                <button class="btn btn-warning" @click="handleReset()">Reset
                  Data</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row layout-top-spacing mt-0" v-show="hideShowSearchFilter">
      <div class="col-xl-12 col-lg-12 col-md-12 col-12">
        <div class="panel br-4">
          <!-- Gram Panchayat search -->
          <Search-Village v-if="selectPanchayatType == 'Gram Panchayat'"
            @filter-village="callToHideFilter"></Search-Village>
          <!-- Samiti Panchayat search -->
          <Search-pincode v-if="selectPanchayatType == 'Samiti Panchayat'"
          @filter-samiti="callToHideFilter"></Search-pincode>
          <!-- ZilaParishad Panchayat search -->
          <Search-zila v-if="selectPanchayatType == 'ZilaParishad Panchayat'"
          @filter-zilaParishad="callToHideFilter"></Search-zila>
          <!-- State Panchayat search -->
          <SearchState v-if="selectPanchayatType == 'State Panchayat'"
          @filter-statePanchayat="callToHideFilter"></SearchState>
        </div>
      </div>
    </div>
    <!-- Gram panchayat section -->
    <div class="row layout-top-spacing" v-if="Object.keys(getVillageDetails).length != 0">
      <!-- <div class="row layout-top-spacing" > -->
      <div class="col-lg-12 layout-spacing">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="my-3 mb-0 custom_create_btn">
                  <loading v-model:active="memberTableLoading" :is-full-page="false" color="#28a745" loader="spinner"
                    :width="35" :z-index="9" />
                </div>
                <div class="panel-body simple-pills tabs p-0">
                  <div class="top_action">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-all-tab" data-bs-toggle="pill" href="#pills-all" role="tab"
                          aria-controls="pills-all" aria-selected="true">ALL</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#pills-app_admins" role="button"
                          aria-haspopup="true" aria-expanded="false">APP ADMINS</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-private_meeting-tab" data-bs-toggle="pill"
                          href="#pills-private_meeting" role="tab" aria-controls="pills-private_meeting"
                          aria-selected="false">PRIVATE MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-public_meeting-tab" data-bs-toggle="pill"
                          href="#pills-public_meeting" role="tab" aria-controls="pills-public_meeting"
                          aria-selected="false">PUBLIC MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-electrol-tab" data-bs-toggle="pill" href="#pills-electrol"
                          role="tab" aria-controls="pills-electrol" aria-selected="false">ELECTROL</a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                      All Details
                    </div>
                    <div class="tab-pane fade show active" id="pills-app_admins" role="tabpanel"
                      aria-labelledby="pills-app_admins-tab">
                      <div class="media">
                        <div class="media-body">
                          <panchayat-village></panchayat-village>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-private_meeting" role="tabpanel"
                      aria-labelledby="pills-private_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        private meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-public_meeting" role="tabpanel"
                      aria-labelledby="pills-public_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        public meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-electrol" role="tabpanel" aria-labelledby="pills-electrol-tab">
                      <p class="dropcap dc-outline-primary">
                        electrol form
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Samiti Panchayat section -->
    <div class="row layout-top-spacing" v-if="Object.keys(getPostalCode).length != 0">
      <div class="col-lg-12 layout-spacing">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="my-3 mb-0 custom_create_btn">
                  <loading v-model:active="memberTableLoading" :is-full-page="false" color="#28a745" loader="spinner"
                    :width="35" :z-index="9" />
                </div>
                <div class="panel-body simple-pills tabs p-0">
                  <div class="top_action">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-all-tab" data-bs-toggle="pill" href="#pills-all" role="tab"
                          aria-controls="pills-all" aria-selected="true">ALL</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#pills-app_admins" role="button"
                          aria-haspopup="true" aria-expanded="false">APP ADMINS</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-private_meeting-tab" data-bs-toggle="pill"
                          href="#pills-private_meeting" role="tab" aria-controls="pills-private_meeting"
                          aria-selected="false">PRIVATE MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-public_meeting-tab" data-bs-toggle="pill"
                          href="#pills-public_meeting" role="tab" aria-controls="pills-public_meeting"
                          aria-selected="false">PUBLIC MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-electrol-tab" data-bs-toggle="pill" href="#pills-electrol"
                          role="tab" aria-controls="pills-electrol" aria-selected="false">ELECTROL</a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                      All Details
                    </div>
                    <div class="tab-pane fade show active" id="pills-app_admins" role="tabpanel"
                      aria-labelledby="pills-app_admins-tab">
                      <div class="media">
                        <div class="media-body">
                          <panchayat-simiti></panchayat-simiti>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-private_meeting" role="tabpanel"
                      aria-labelledby="pills-private_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        private meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-public_meeting" role="tabpanel"
                      aria-labelledby="pills-public_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        public meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-electrol" role="tabpanel" aria-labelledby="pills-electrol-tab">
                      <p class="dropcap dc-outline-primary">
                        electrol form
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ZilaParishad Panchayat section -->
    <div class="row layout-top-spacing" v-if="Object.keys(getCityDetail).length != 0">
      <div class="col-lg-12 layout-spacing">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="my-3 mb-0 custom_create_btn">
                  <loading v-model:active="memberTableLoading" :is-full-page="false" color="#28a745" loader="spinner"
                    :width="35" :z-index="9" />
                </div>
                <div class="panel-body simple-pills tabs p-0">
                  <div class="top_action">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-all-tab" data-bs-toggle="pill" href="#pills-all" role="tab"
                          aria-controls="pills-all" aria-selected="true">ALL</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#pills-app_admins" role="button"
                          aria-haspopup="true" aria-expanded="false">APP ADMINS</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-private_meeting-tab" data-bs-toggle="pill"
                          href="#pills-private_meeting" role="tab" aria-controls="pills-private_meeting"
                          aria-selected="false">PRIVATE MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-public_meeting-tab" data-bs-toggle="pill"
                          href="#pills-public_meeting" role="tab" aria-controls="pills-public_meeting"
                          aria-selected="false">PUBLIC MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-electrol-tab" data-bs-toggle="pill" href="#pills-electrol"
                          role="tab" aria-controls="pills-electrol" aria-selected="false">ELECTROL</a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                      All Details
                    </div>
                    <div class="tab-pane fade show active" id="pills-app_admins" role="tabpanel"
                      aria-labelledby="pills-app_admins-tab">
                      <div class="media">
                        <div class="media-body">
                          <zila-panchayat></zila-panchayat>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-private_meeting" role="tabpanel"
                      aria-labelledby="pills-private_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        private meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-public_meeting" role="tabpanel"
                      aria-labelledby="pills-public_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        public meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-electrol" role="tabpanel" aria-labelledby="pills-electrol-tab">
                      <p class="dropcap dc-outline-primary">
                        electrol form
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div><!-- State Panchayat section -->
    <div class="row layout-top-spacing" v-if="Object.keys(getStateDetail).length != 0">
    <!-- <div class="row layout-top-spacing"> -->
      <div class="col-lg-12 layout-spacing">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="my-3 mb-0 custom_create_btn">
                  <loading v-model:active="memberTableLoading" :is-full-page="false" color="#28a745" loader="spinner"
                    :width="35" :z-index="9" />
                </div>
                <div class="panel-body simple-pills tabs p-0">
                  <div class="top_action">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-all-tab" data-bs-toggle="pill" href="#pills-all" role="tab"
                          aria-controls="pills-all" aria-selected="true">ALL</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="pill" href="#pills-app_admins" role="button"
                          aria-haspopup="true" aria-expanded="false">APP ADMINS</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-private_meeting-tab" data-bs-toggle="pill"
                          href="#pills-private_meeting" role="tab" aria-controls="pills-private_meeting"
                          aria-selected="false">PRIVATE MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-public_meeting-tab" data-bs-toggle="pill"
                          href="#pills-public_meeting" role="tab" aria-controls="pills-public_meeting"
                          aria-selected="false">PUBLIC MEETING</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link disabled" id="pills-electrol-tab" data-bs-toggle="pill" href="#pills-electrol"
                          role="tab" aria-controls="pills-electrol" aria-selected="false">ELECTROL</a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
                      All Details
                    </div>
                    <div class="tab-pane fade show active" id="pills-app_admins" role="tabpanel"
                      aria-labelledby="pills-app_admins-tab">
                      <div class="media">
                        <div class="media-body">
                          <state-panchayat></state-panchayat>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-private_meeting" role="tabpanel"
                      aria-labelledby="pills-private_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        private meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-public_meeting" role="tabpanel"
                      aria-labelledby="pills-public_meeting-tab">
                      <p class="dropcap dc-outline-primary">
                        public meeting form
                      </p>
                    </div>
                    <div class="tab-pane fade" id="pills-electrol" role="tabpanel" aria-labelledby="pills-electrol-tab">
                      <p class="dropcap dc-outline-primary">
                        electrol form
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
import panchayatVillage from '../components/panchayatVillage.vue'
import panchayatSimiti from '../components/panchayatSimiti.vue'
import zilaPanchayat from '../components/panchayatZilaparishad.vue'
import statePanchayat from '../components/panchayatState.vue'
import SearchVillage from '../components/searchVillage.vue'
import SearchPincode from '../components/searchPincode.vue'
import SearchZila from '../components/searchZila.vue'
import SearchState from '../components/searchState.vue'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useI18n } from 'vue-i18n';
import { useAbility } from '@casl/vue';
const { can } = useAbility();
const store = useStore();
const memberOptions = ref('');
const lockPanchayatType = ref(false)
const selectPanchayatType = ref(null)
const hideShowSearchFilter = ref(false);
const hideSearchFilter = ref(true);
useMeta({ title: 'Panchayat' });
const { t } = useI18n();

function getLockedStatus() {
  if (!lockPanchayatType.value) {
    hideShowSearchFilter.value = false;
    selectPanchayatType.value = null;
    handleReset();
    window.showAlertMessage('Data reset successfully.', 'success');
  }
}
function handleReset() {
  store.commit('SET_PANCHAYAT_TYPE', '');
  store.commit(`SET_VILLAGE_DETAILS`, {});
  store.commit(`POSTAL_CODE`, {});  // Reset postal code by city
  store.commit(`CITY_DETAIL`, {}); // Reset city details
  store.commit(`STATE_DETAIL`, {}); // Reset state details
  store.commit('ADD_PANCHAYATS_BODY');  // Reset panchayat body
  lockPanchayatType.value = false;
  selectPanchayatType.value = null;
}
function getSelectedPanchyatType() {
  store.commit('SET_PANCHAYAT_TYPE', selectPanchayatType.value);
  hideShowSearchFilter.value = true;
}

const panchayatTypeOption = ref([
  { name: 'Gram Panchayat', value: 'Gram Panchayat' },
  { name: 'Samiti Panchayat', value: 'Samiti Panchayat' },
  { name: 'ZilaParishad Panchayat', value: 'ZilaParishad Panchayat' },
  { name: 'State Panchayat', value: 'State Panchayat' },
  { name: 'Regional Panchayat', value: 'Regional Panchayat' }
]);

const getRoles = computed(() => {
  var roles = store.state.auth.meDetails.roles;
  if (Object.keys(roles).length != 0) {
    if(roles.includes('regional-admin')){
      return 'regional-admin';
    }
    else if(roles.includes('state-admin')){
      return 'state-admin';
    }
    else if(roles.includes('area-admin')){
      return 'area-admin';
    }
    else if(roles.includes('sub-area-admin')){
      return 'sub-area-admin';
    }
    else if(roles.includes('sarpanch')){
      return 'sarpanch';
    } 
    else if(roles.includes('panch')){
      return 'panch';
    } 
    else {
      return 'all';
    }
  }
});

const getVillageDetails = computed(() => {
  if (Object.keys(store.state.village.villageData).length != 0) {
    lockPanchayatType.value = true;
  }
  return store.state.village.villageData;
});

const getPostalCode = computed(() => {
  if (Object.keys(store.state.panchayatSamiti.postalCode).length != 0) {
    lockPanchayatType.value = true;
  }
  return store.state.panchayatSamiti.postalCode;
});

const getCityDetail = computed(() => {
  if (Object.keys(store.state.zilaPanchayat.cityDetail).length != 0) {
    lockPanchayatType.value = true;
  }
  return store.state.zilaPanchayat.cityDetail;
});

const getStateDetail = computed(() => {
  if (Object.keys(store.state.statePanchayat.stateDetail).length != 0) {
    lockPanchayatType.value = true;
  }
  return store.state.statePanchayat.stateDetail;
});

onBeforeMount(() => {
  console.log(can('uid.create'))
  handleReset();
  // store.commit('POSTAL_CODE', {});
  if (getVillageDetails.value.id != undefined) {
    store.dispatch(`getVilageMemberList`, getVillageDetails.value.id).then((res) => {
      memberOptions.value = res.data;
    });
  }
});

function callToHideFilter(val) {
  hideShowSearchFilter.value = false;
  // hideSearchFilter.value = false;
  // store.dispatch('getVilageMemberList', getVillageDetails.value.id)
}

</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .villageTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}
</style>   
  
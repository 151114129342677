import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	globalConfig:{},
    savedGlobalSettings:{
        country:{},
        state:{},
        city:{},
        pincode:{},
    }
};
const getters = {
	getGlobalSetting(state){
        return state.globalConfig;
    }
};
const mutations = {
	SET_GLOBAL_CONFIG_SETTING(state,context){
        console.log(context)
        if(context != undefined){
            if(context.type == 'country'){
                state.globalConfig.country = context.data;
            } else if(context.type == 'state'){
                state.globalConfig.state = context.data;
            } else if(context.type == 'city'){
                state.globalConfig.city = context.data;
            } 
            else if(context.type == 'pincode'){
                state.globalConfig.pincode = context.data;
            }
        } else {
            state.globalConfig = {};
        }
	},
	SAVE_GLOBAL_CONFIG_SETTING(state){
        state.savedGlobalSettings = state.globalConfig;
    },
    RESET_GLOBAL_CONFIG_SETTING(state){
        state.globalConfig = {
            country:{},
            state:{},
            city:{},
            pincode:{},
        };
        state.savedGlobalSettings = {
            country:{},
            state:{},
            city:{},
            pincode:{},
        };
    }
};
const actions = {
    async getUsersList({ commit, dispatch }, getData) {
		console.log(getData)
		let api_url_country = '';
		if(getData.country_id){
			api_url_country +=`&country=${getData.country_id}`
		}
		let api_url_state = '';
		if(getData.state_id){
			api_url_state +=`&state=${getData.state_id}`
		}
		let api_url_city = '';
		if(getData.city_id){
			api_url_city +=`&city=${getData.city_id}`
		}
		let api_url_postal = '';
		if(getData.postal){
			api_url_postal +=`&postal=${getData.postal}`
		}
		let api_url_village = '';
		if(getData.v_id){
			api_url_village +=`&v_id=${getData.v_id}`
		}
		let api_url_panch_type = '';
		if(getData.panch_type){
			api_url_panch_type +=`&panch_type=${getData.panch_type}`
		}
		let api_url_role = [];
		if(getData.roles && getData.roles.length != 0){
			api_url_role +=`&role=${getData.roles}`
		}
		return await axios.get(`/dashboard/users?search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}${api_url_country}${api_url_state}${api_url_city}${api_url_postal}${api_url_village}${api_url_panch_type}${api_url_role}`).then(response => {
			console.log(response)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
    async getRoles({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/roles/dropdown`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
    async assignUsersRoles({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/user/role/assign`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};
<template>
    <div class="vl-parent layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Dashboard</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>

            <!-- <ul class="navbar-nav flex-row ms-auto">
                <li class="nav-item more-dropdown">
                    <div class="dropdown custom-dropdown-icon">
                        <a href="javascript:;" class="nav-link dropdown-toggle" id="ddlSettings" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>Settings</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlSettings">
                            <li><a class="dropdown-item" data-value="Settings" href="javascript:void(0);">Settings</a></li>
                            <li><a class="dropdown-item" data-value="Mail" href="javascript:void(0);">Mail</a></li>
                            <li><a class="dropdown-item" data-value="Print" href="javascript:void(0);">Print</a></li>
                            <li><a class="dropdown-item" data-value="Download" href="javascript:void(0);">Download</a></li>
                            <li><a class="dropdown-item" data-value="Share" href="javascript:void(0);">Share</a></li>
                        </ul>
                    </div>
                </li>
            </ul> -->
        </teleport>
        <loading v-model:active="homeDataLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
        <div class="row layout-top-spacing" :key="dashReload">
        
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>All Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Registred Member</h6>
                                    <p class="summary-count">{{all.registred_member?all.registred_member:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{all.village_count?all.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-supervisor" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{all.village_member_count?all.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{all.panachyat_count?all.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>India Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{india.village_count?india.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{india.village_member_count?india.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{india.panachyat_count?india.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>Australia Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{aus.village_count?aus.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{aus.village_member_count?aus.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{aus.panachyat_count?aus.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>Canada Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{canada.village_count?canada.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{canada.village_member_count?canada.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{canada.panachyat_count?canada.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>Newzealand Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{newzealand.village_count?newzealand.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{newzealand.village_member_count?newzealand.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{newzealand.panachyat_count?newzealand.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>United Kingdom Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{uk.village_count?uk.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{uk.village_member_count?uk.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{uk.panachyat_count?uk.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>United States Summary</h5>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="home-group" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Count</h6>
                                    <p class="summary-count">{{us.village_count?us.village_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Village Member Count</h6>
                                    <p class="summary-count">{{us.village_member_count?us.village_member_count:0}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <mdicon name="account-group-outline" /> 
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Panachyat Count</h6>
                                    <p class="summary-count">{{us.panachyat_count?us.panachyat_count:0}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script setup>
    import '@/assets/sass/widgets/widgets.scss';
    import { computed, ref,onMounted } from 'vue';
    import { useStore } from 'vuex';

    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Admin' });
    const dashReload = ref(0)
    const homeDataLoading = ref(false);
   
    const store = useStore();
    const dashData = ref({});
    const all = ref({});
    const aus = ref({});
    const canada = ref({});
    const india = ref({});
    const newzealand = ref({});
    const uk = ref({});
    const us = ref({});
    onMounted(()=>{
        get_dashboard_data();
    })
    function get_dashboard_data(){
        homeDataLoading.value = true;
        store.dispatch('getDashboardData').then((response) => {
            // dashReload.value+=1;
            homeDataLoading.value = false;
            if(response.status == 200){
                dashData.value =  response.data;
                all.value = response.data.all;
                aus.value = response.data.aus;
                canada.value = response.data.canada;
                india.value = response.data.india;
                newzealand.value = response.data.newzealand;
                uk.value = response.data.uk;
                us.value = response.data.us;
            }
            console.log(dashData.value)
            // if (response.status == 200) {
            //     initialData.value = response.data;
            //     initialData.value.password = null;
            //     window.scrollTo({
            //         top: 0,
            //         behavior: 'smooth',
            //     });
            //     setTimeout(() => {
            //         formUpdate.value += 1;
            //     }, 100);
            // }
        });
    };

    
</script>
<style>
.w-summary-info h6{
    margin:0px;
}
.w-summary-info p{
    margin:0px;
}
.widget.widget-summary .widget-content .summary-list {
    display: flex;
    align-items: center;
}
.widget.widget-summary .widget-content .summary-list:nth-child(4) .w-icon {
    background: rgb(255 203 252 / 51%);
    color: #d728a6;
}
</style>

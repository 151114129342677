import villageList from '../../views/panchayat/tables/villageList.vue';
import createVillage from '../../views/panchayat/forms/createVillage.vue';
import importCsv from '../../views/panchayat/forms/importCsv.vue'

export default [
	{
		path: '/village/list',
		name: 'village-list',
		component: villageList,
		meta: { requiresAuth: true, requiredPermissions:'village.list' }
	},
	{
		path: '/village/create',
		name: 'create-village',
		component: createVillage,
		meta: { requiresAuth: true, requiredPermissions:'village.create' },
	},
	{
		path: '/village/list/edit/:id',
		name: 'village-edit',
		component: createVillage,
		meta: { requiresAuth: true },
	},
	{
		path: '/village/import',
		name: 'importCsv-village',
		component: importCsv,
		meta: { requiresAuth: true },
	},
];

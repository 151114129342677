import PanchayatList from '../../views/panchayat/tables/panchayatList.vue';
import Panchayat from '../../views/panchayat/forms/panchayat.vue';
// import createPanchayat from '../../views/panchayat/forms/createPanchayat.vue';

export default [
	{
		path: '/panchayat/list',
		name: 'panchayat-list',
		component: PanchayatList,
		meta: { requiresAuth: true,requiredPermissions:'panchayat.list' }
	},
	{
		path: '/panchayat/create',
		name: 'create-panchayat',
		component: Panchayat,
		meta: { requiresAuth: true,requiredPermissions:'panchayat.create' },
	},
	{
		path: '/panchayat/list/edit/:id',
		name: 'panchayat-edit',
		component: Panchayat,
		meta: { requiresAuth: true },
	},
];

<template>
    <div class="row vl-parent">
        <loading v-model:active="editPanchayatLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
        <div class="col-lg-12">
            <div class="statbox panel box box-shadow">
                <div class="panel-body">
                    <div class="addedMemberTitle">
                        <form novalidate @submit.stop.prevent="addPanchayatBodyRow(panchayat_body_formdata)">
                            <div class="row align-center" >
                            <div class="col-md-4" v-show="editPanchayatData.panchayat_type == 'ZilaParishad Panchayat'">
                                <div class="form-group">
                                    <label for="">Pincode</label>
                                    <multiselect v-model="panchayat_body_formdata.pincode" :options="pincodeOfCityOptions"
                                    label="title" :disabled="pincodeOfCityOptions.length == 0" :searchable="true" @select="getVillageOfCurrentPincode" :preselect-first="true"
                                    selected-label="" :loading="pincodeInputLoad" placeholder="Select Pincode" select-label="" deselect-label=""
                                    :class="[panchayatBodyError ? (panchayat_body_formdata.pincode ? '' : 'is-invalid') : '']">
                                    </multiselect>
                                <div v-if="isPincodeOptions" class="text-danger">Pincode Not Found.</div>
                                <div v-if="!isPincodeOptions" class="invalid-feedback">Please select the pincode</div>
                                </div>
                            </div>
                            <div class="col-md-4" v-show="(editPanchayatData.panchayat_type == 'Samiti Panchayat') || editPanchayatData.panchayat_type == 'ZilaParishad Panchayat'">
                                <label for="">Village</label>
                                <multiselect v-model="panchayat_body_formdata.village" :options="pincodeVillageOptions" label="village"
                                :searchable="true" :disabled="pincodeVillageOptions.length == 0" :loading="villageInputLoad" @select="callVillageForMember" :preselect-first="true" selected-label="" placeholder="Select village"
                                select-label="" deselect-label=""
                                :class="[panchayatBodyError ? (panchayat_body_formdata.village ? '' : 'is-invalid') : '']">
                                </multiselect>
                                <div v-if="isVillageOptions" class="text-danger">Village Not Found.</div>
                                <div v-if="!isVillageOptions" class="invalid-feedback">Please select the village</div>
                            </div>
                            <div class="col-md-4">
                                <label for="">Member</label>
                                <multiselect v-model="panchayat_body_formdata.member" :options="memberOptions" label="name"
                                :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Member"
                                select-label="" deselect-label="" :loading="memberInputLoad" :disabled="memberOptions.length == 0"
                                :class="[panchayatBodyError ? (panchayat_body_formdata.member ? '' : 'is-invalid') : '']">
                                </multiselect>
                                <div v-if="isMemberOptions" class="text-danger">Member Not Found.</div>
                                <div v-if="!isMemberOptions" class="invalid-feedback">Please select the member</div>
                            </div>
                            <div class="col-md-4">
                                <label for="">Title</label>
                                <multiselect v-model="panchayat_body_formdata.title" :options="panchayatTitleOption" label="title"
                                :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Title"
                                select-label="" deselect-label="" :disabled="panchayatTitleOption.length == 0"
                                :class="[panchayatBodyError ? (panchayat_body_formdata.title ? '' : 'is-invalid') : '']">
                                </multiselect>
                                <div class="invalid-feedback">Please select the title</div>
                            </div>
                            <div class="col-md-4 mt-auto">
                                <label for=""></label>
                                <button type="submit" class="btn btn-primary my-2">Add</button>
                            </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="panchayatBody">
                                    <table class="table b-table table-hover table-bordered mb-0">
                                        <thead class="bg-light sticky-top top-0">
                                            <tr>
                                                <th>#</th>
                                                <th>Member</th>
                                                <th>Village</th>
                                                <th>Panchayat Title</th>
                                                <th class="text-center">Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody  v-show="$store.state.panchayatSamiti.panchayats_body.length > 0">
                                            <tr v-for="(item, index) in  $store.state.panchayatSamiti.panchayats_body" :key="index">
                                            <th scope="row">{{ index+1 }}</th>
                                            <td>{{ item.member_name?item.member_name:'---' }}</td>
                                            <td>{{ item.village?item.village:'---' }}</td>
                                            <td>{{ item.title?item.title:'---' }}</td>
                                            <td class="text-center">
                                                <vue-feather @click="removePanchayatBodyRow(index)" type="trash-2" size="18"
                                                stroke="red"></vue-feather>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Form @submit="onSubmit" :key="formReset" :initial-values="initialData" :validation-schema="schemas"
                        keep-values v-slot="{ handleSubmit, values }">
                        <div class="row align-center">
                            <TextInput name="period_start" label="Period start" type="date" placeholder="" />
                            <TextInput name="period_end" label="Period end" type="date" placeholder="" />
                            <SelectInput name="status" :options="statusOption" label="Select Status" />
                            <TextInput name="formation_description" cols="12" label="Formation Description" placeholder="" />
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <div class="next_prev">
                                    <span>
                                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                                            Update
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import { ref, reactive, computed, onBeforeMount, watch, inject, onBeforeUnmount } from 'vue';
import '@/assets/sass/scrollspyNav.scss';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useMeta } from '@/composables/use-meta';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const router = useRouter();
const isLoading = ref(false);
const isMemberLoading = ref(true);
const errors = ref({});
const formReset = ref(0);
const memberOptions = ref([]);
const selectMemebr = ref('');
const panchayat_body = ref([]);
const panchayatBodyError = ref(false);
const memberInputLoad = ref(false);
const villageInputLoad = ref(false);
const editPanchayatLoading = ref(false);
const pincodeVillageOptions = ref([]);
const pincodeOfCityOptions = ref([]);
const pincodeInputLoad = ref(false);
const isPincodeOptions = ref(false);
const isVillageOptions = ref(false);
const isMemberOptions = ref(false);

const $loading = useLoading({
    // options
});
const emit = defineEmits(['toggleSeason'])
const props = defineProps(['village_name'])
console.log(props)
const panchayatTitleOption = ref([]);
const statusOption = ref([
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'Hold', value: 'hold' },
  { name: 'Expired', value: 'expired' }
]);
const panchayat_body_formdata = ref({
    pincode:'',
    village:'',
    member: '',
    title: ''
});
const initialData = ref({
    period_start: null,
    period_end: null,
    formation_description: null,
    status:null
})

const schemas = yup.object().shape({
    period_start: yup.string().nullable().required('This field is required.'),
    period_end: yup.string().nullable().required('This field is required.'),
    status: yup.string().nullable().required('This field is required.'),
    formation_description: yup.string().nullable().required('This field is required.'),
});
// const getVillageDetails = computed(() => {
//     return store.state.village.villageData;
// });

onBeforeMount(() => {
    store.dispatch(`getPanchayatTitleList`).then((res) => {
    panchayatTitleOption.value = res.data.data;
    });
});

const editID = ref('');
const editPanchayatData = ref({});

const emitter = inject('emitter'); // Inject `emitter`
emitter.on('editPanchayat', (panchayatData) => {   // *Listen* for event
    editPanchayatData.value = panchayatData;
    resetDataOnEdit();
    store.commit('ADD_PANCHAYATS_BODY');
    editID.value = panchayatData.panchayat_id;
    // const loader = $loading.show({
    //     loader: 'spinner',
    //     zIndex: 999,
            // width:40
    // });
    editPanchayatLoading.value = true;
    store.dispatch(`editPanchayatSamitiRow`, { id: panchayatData.panchayat_id }).then((res) => {
        initialData.value = res.data;
        editPanchayatLoading.value = false;
        panchayatBodyError.value = false;
        if(panchayatData.panchayat_type == 'Gram Panchayat'){
            callVillageForMember({"village_id":res.data.village_id});
        }
        if(panchayatData.panchayat_type == 'ZilaParishad Panchayat'){
            getPincodeOfCurrentCity();
        }
        // loader.hide();
    });
    if(panchayatData.postal_code){
        getVillageOfCurrentPincode({value:panchayatData.postal_code});
    }
});

onBeforeUnmount(() => {
    emitter.off('editPanchayat');
});

function resetDataOnEdit(){
    memberOptions.value = [];
    panchayat_body_formdata.value = {
        pincode:'',
        village:'',
        member: '',
        title: ''
    };
    initialData.value = {};
    formReset.value+=1;
};
const getCountryStateCity = computed(() => {
  return store.state.zilaPanchayat.country_state_city;
});

function getPincodeOfCurrentCity() {
  pincodeInputLoad.value = true;
//   store.commit('ADD_PANCHAYATS_BODY');
  store.dispatch(`getPincodeOfSelectCity`, getCountryStateCity.value).then((res) => {
    pincodeInputLoad.value = false;
    pincodeOfCityOptions.value = res.data.data.map((code)=>{
      return {title:code.postal_code,value:code.postal_code}
    });
    if(pincodeOfCityOptions.value.length != 0 ){
        isPincodeOptions.value = false;
    } else{
        isPincodeOptions.value = true;
    }
  });
}

function getVillageOfCurrentPincode(postal_code){
    console.log(postal_code)
    villageInputLoad.value = true;
    store.dispatch(`getVillageOfSelectPincode`, postal_code.value).then((res) => {
        villageInputLoad.value = false;
        pincodeVillageOptions.value = res.data.data;
        if(pincodeVillageOptions.value.length != 0 ){
            isVillageOptions.value = false;
        } else{
            isVillageOptions.value = true;
        }
    });
}
// function getMemberOfCurrentVillage(village_id){
//   store.dispatch(`getVilageMemberList`, village_id).then((res) => {
//     memberOptions.value = res.data.data;
//   });
// }

function callVillageForMember(selectedValue){
    console.log(selectedValue)
  memberInputLoad.value = true;
  store.dispatch(`getMemberOfSelectVillageForGramAndSamiti`, selectedValue.village_id).then((res) => {
    memberInputLoad.value = false;
    memberOptions.value = res.data.data;
    if(memberOptions.value.length != 0 ){
        isMemberOptions.value = false;
    } else{
        isMemberOptions.value = true;
    }
  });
}
function addPanchayatBodyRow(row) {
    console.log(row)
    // if(editPanchayatData.value.panchayat_type == 'Gram Panchayat'){
    //     console.log('gram')
    //     if(row.member == '' || row.title == ''){
    //         panchayatBodyError.value = true;
    //         return false;
    //     }
    // } else if(editPanchayatData.value.panchayat_type == 'Samiti Panchayat'){
    //     console.log('samiti')
    //     if(row.village == '' || row.member == '' || row.title == ''){
    //         panchayatBodyError.value = true;
    //         return false;
    //     }
    // } else if(editPanchayatData.value.panchayat_type == 'ZilaParishad Panchayat'){
    //     console.log('ZilaParishad')
    //     if(row.village == '' || row.member == '' || row.title == ''){
    //         panchayatBodyError.value = true;
    //         return false;
    //     }
    // }
    if((row.member == '' || row.title == '') && 
    (row.village == '' || row.member == '' || row.title == '') &&
    (row.pincode == '' || row.village == '' || row.member == '' || row.title == '')){
        panchayatBodyError.value = true;
        console.log('foo')
        // return false;
    }
    else {
        console.log('foo2')
        var newRow = {
            'village':row.village.village,
            'village_id':row.village.village_id,
            'member_name':row.member.name,
            'member_id':row.member.member_id,
            'title':row.title.title,
            'panchayat_title_id':row.title.panchayat_title_id
        };
        var checkStatus = store.state.panchayatSamiti.panchayats_body.map((item) => {
            if(item.member_name == newRow.member_name){  // if need || (item.title == newRow.title)
                return true;
            } return false;
            }).includes(true)
            if(checkStatus){
            window.showAlertMessage('"'+newRow.member_name+'" member already added', 'warning');
            return false;
            }
        store.commit('ADD_PANCHAYATS_BODY', newRow);
        panchayat_body_formdata.value = { 
            pincode:'',
            village:'',
            member: '',
            title: '' 
        };
        panchayatBodyError.value = false;
    } 
}

function removePanchayatBodyRow(id) {
    new window.Swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        focusCancel: true,
        padding: '2em',
    }).then((result) => {
        if (result.value) {
            console.log(id)
            store.commit('REMOVE_PANCHAYATS_BODY', id);
        }
    });
};

function onSubmit(values) {
    console.log(values)
    if (store.state.panchayatSamiti.panchayats_body.length == 0) {
        window.showAlertMessage('Please fill panchayat body form', 'warning');
        return false
    } else {
        values['panchayat_body'] = store.state.panchayatSamiti.panchayats_body;
    }
    console.log(store.state.panchayatSamiti.pincode_country_state_city)
    console.log(values['panchayat_body'] )
    isLoading.value = true;
    // values['id'] = initialData.value.id;
    // values['village_id'] = initialData.value.village_id;
    // values['postalcode_id'] = initialData.value.postalcode_id;
    // values['panchayat_type'] = initialData.value.panchayat_type;
    // values['formation_type'] = initialData.value.formation_type; 
    // values['postal_code'] = initialData.value.postal_code; 
    // values['city_id'] = initialData.value.city_id; 
    // values['state_id'] = initialData.value.state_id; 
    // values['country_id'] = initialData.value.country_id;
    var updateData = {...initialData.value,...values}
    store.dispatch('updateVillagePanchayat', updateData).then((response) => {
        isLoading.value = false;
        if (response.status == 200) {
            handleReset();
            window.showAlertMessage(response.data.message, 'success');
        } else {
            errors.value = response.data.errors;
            window.showAlertMessage(response.data.message, 'warning');
        }
    });
}
function handleReset() {
    errors.value = [];
    editID.value = '';
    formReset.value += 1;
    initialData.value = {};
    store.commit('ADD_PANCHAYAT_BODY_UPDATE',[]);
    emitter.emit('closePanchayatForm');
}
</script>
<style scoped>
.next_prev {
    display: flex;
    justify-content: flex-end;
}

.next_prev button {
    margin-left: 10px;
}

.form-part {
    margin: 0px 0px 20px
}

.form-part h6 {
    font-weight: bold;
}

.panel .panel-heading h4 {
    font-size: 21px;
}

.step_form {
    display: flex;
    align-items: center;
}

.step_form button {
    margin-right: 0px;
    border-radius: 27px;
    font-size: 13px;
    padding: 7px 30px;
}

.step_form .btn-outline-primary:hover {
    color: #28a745 !important;
    background-color: #fff;
    box-shadow: 0 10px 20px -10px #fff;
}

.addedMemberTitle {
    /* border: 1px dashed #d3d3d3; */
    margin: 0px 0px 15px;
    padding: 10px 10px 10px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #bfc9d4;
}</style>

import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	// is_article:{},
	is_article_select:{}
};
const getters = {
};
const mutations = {
	// IS_ARTICLE(state,context){
	// 	state.is_article = context;
	// },
	IS_ARTICLE_SELECTED(state,context){
		state.is_article_select = context;
	}
};
const actions = {
	async getArticleDataList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/articles`,{
            params:getData
        }).then(response => {
			if(response.data.data.length == 0){
				commit('IS_ARTICLE_SELECTED',{})
			}
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	// async getTagsDropdownListStore({ commit, dispatch }, getData) {
	// 	return await axios.get(`/dashboard/tag/list`,{
    //         params:getData
    //     }).then(response => {
    //         return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	async saveArticleStore({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/article/create`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateArticleStore({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/article/update`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},

	async deleteArticleStore({ commit, dispatch }, id) {
		return await axios.delete(`/dashboard/article/trash/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editArticleStore({ commit, dispatch }, id) {
		return await axios.get(`/dashboard/article/edit/${id}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editArticleLangStore({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/article/edit/${getData.id}?lang=${getData.lang}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	
};
export default {
	state,
	mutations,
	actions,
	getters,
};

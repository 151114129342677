<template>
    <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Tag</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Create</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
    <div class="layout-px-spacing">
        <createTags></createTags>
        <Transition  name="fade" v-if="!isEmpty(isSelectedTags)">
            <searchByLaw></searchByLaw>
        </Transition>
        <div class="row layout-top-spacing mt-0">
            <div class="col-lg-12">
                <!-- <div class="">
                    <constitution></constitution>
                </div> -->
                <!-- <div class="mt-3" v-if="!isEmpty(isConstitutionsCreated)"> -->
                <div class="mt-3" v-if="!isEmpty(isSelectedTags) && $can('article.list')">
                    <articles></articles>
                </div>
                <div class="mt-3" v-if="!isEmpty(isArticleCreated) && $can('section.list')">
                    <sections></sections>
                </div>
                <div class="mt-3" v-if="!isEmpty(isSectionCreated) && $can('point.list')">
                    <points></points>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
// import constitution from '../components/tags/constitutions.vue'
import searchByLaw from '../components/tags/searchByLaw.vue'
import articles from '../components/tags/articles.vue'
import sections from '../components/tags/sections.vue'
import points from '../components/tags/points.vue'
import createTags from '../components/tags/createTags.vue'
import { ref, onMounted, computed,watch,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const router = useRouter();
const route = useRoute();
const store = useStore();
const filterFromCountryVillage = ref(false);
const emitter = inject('emitter');
onMounted(() => {
  
});

const isSelectedTags = computed(() => {
    return store.state.tags.selectedTags;
});

const isConstitutionsCreated = computed(() => {
    return store.state.searchByLaw.is_constitution_select;
});
const isArticleCreated = computed(() => {
    return store.state.articles.is_article_select;
});
const isSectionCreated = computed(() => {
    return store.state.section.is_section_select;
});

function isEmpty(n){
    return !(!!n ? typeof n === 'object' ? Array.isArray(n) ? !!n.length : !!Object.keys(n).length : true : false);
}

emitter.on('refreshSearchList', (value) => {   // *Listen* for event
    console.log('refresh called')
});

// watch(()=>route.params.id, ()=> {
//     console.log(route.params.id)
//     if(route.params.id == undefined){
//         filterFromCountryVillage.value = false;
//     }
// });
</script>
<style scoped>
</style>
<template>
    <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Village</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Create</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
    <div class="select2 layout-px-spacing create_village_form">
        <div class="row layout-top-spacing">
            <div id="fs2Basic" class="col-lg-12">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading d-flex justify-content-between align-items-center">
                        <h4 style="font-size: 22px;"><span v-show="filterFromCountryVillage">Select Village</span></h4>
                        <button class="btn btn-primary m-1" @click="filterFromCountryVillage = !filterFromCountryVillage">
                        Select Village&nbsp; <vue-feather :type="!filterFromCountryVillage?'eye':'eye-off'" size="11"></vue-feather></button>
                    </div>
                    <div v-show="filterFromCountryVillage">
                        <Search-Village @filter-village="callToHideFilter"></Search-Village>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 layout-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                <div class="my-3 mb-0 ">
                                    <!-- <loading v-model:active="memberTableLoading" :is-full-page="false" color="#28a745" loader="spinner" :width="35" :z-index="9" /> -->
                                    <div class="refresh_table m-0">
                                        <div class="d-flex" v-if="$route.params.id">
                                            <h4>Update Village : </h4>
                                            <ul v-if="Object.keys($store.state.village.villageData).length != 0" class="list-group d-flex flex-row">
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <b>Country:&nbsp;&nbsp;</b>
                                                <span class="">{{ $store.state.village.villageData.country ? $store.state.village.villageData.country :
                                                    '...'
                                                }}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <b>Postal code:&nbsp;&nbsp;</b>
                                                <span class="">{{
                                                    $store.state.village.villageData.postal_code ? $store.state.village.villageData.postal_code : '...'
                                                }}</span>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                <b>Village:&nbsp;&nbsp;</b>
                                                <span class="">{{ $store.state.village.villageData.village ? $store.state.village.villageData.village :
                                                    '...'
                                                }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="d-flex" v-else>
                                            <h4>Add New Village</h4>
                                        </div>
                                        <div class="memberTableButton m-0">
                                            <button :disabled="Object.keys(getVillageDetails).length == 0" class="btn btn-warning"
                                                @click="handleReset()">Reset Data</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-body simple-pills tabs p-0">
                                    <div class="top_action">
                                        <ul class="nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                                            <li class="nav-item" v-if="$can('village.create')">
                                                <a class="nav-link active" id="pills-location-tab" data-bs-toggle="pill"
                                                    href="#pills-location" role="tab" aria-controls="pills-location"
                                                    aria-selected="true">Location</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" data-bs-toggle="pill" href="#pills-Demography"
                                                    role="button" aria-haspopup="true" aria-expanded="false">Demography</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" id="pills-History-tab" data-bs-toggle="pill"
                                                    href="#pills-History" role="tab" aria-controls="pills-History"
                                                    aria-selected="false">History</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" id="pills-Economy-tab" data-bs-toggle="pill"
                                                    href="#pills-Economy" role="tab" aria-controls="pills-Economy"
                                                    aria-selected="false">Economy</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" id="pills-Culture-tab" data-bs-toggle="pill"
                                                    href="#pills-Culture" role="tab" aria-controls="pills-Culture"
                                                    aria-selected="false">Culture</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" id="pills-Infrastructure-tab"
                                                    data-bs-toggle="pill" href="#pills-Infrastructure" role="tab"
                                                    aria-controls="pills-Infrastructure"
                                                    aria-selected="false">Infrastructure</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link disabled" id="pills-Challanges-tab" data-bs-toggle="pill"
                                                    href="#pills-Challanges" role="tab" aria-controls="pills-Challanges"
                                                    aria-selected="false">Challanges</a>
                                            </li>
                                        </ul>
                                        <ul class="nav nav-pills mb-3 mt-3">
                                            <li class="nav-item">
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="tab-content" id="pills-tabContent">
                                        <div v-if="$can('village.create')" class="tab-pane fade show active" id="pills-location" role="tabpanel"
                                            aria-labelledby="pills-location-tab">
                                            <span>
                                                <createLocation></createLocation>
                                            </span>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Demography" role="tabpanel"
                                            aria-labelledby="pills-Demography-tab">
                                            <div class="media">
                                                <div class="media-body">
                                                    Demography form
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-History" role="tabpanel"
                                            aria-labelledby="pills-History-tab">
                                            <p class="dropcap dc-outline-primary">
                                                History form
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Economy" role="tabpanel"
                                            aria-labelledby="pills-Economy-tab">
                                            <p class="dropcap dc-outline-primary">
                                                Economy form
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Culture" role="tabpanel"
                                            aria-labelledby="pills-Culture-tab">
                                            <p class="dropcap dc-outline-primary">
                                                Culture form
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Infrastructure" role="tabpanel"
                                            aria-labelledby="pills-Infrastructure-tab">
                                            <p class="dropcap dc-outline-primary">
                                                Infrastructure form
                                            </p>
                                        </div>
                                        <div class="tab-pane fade" id="pills-Challanges" role="tabpanel"
                                            aria-labelledby="pills-Challanges-tab">
                                            <p class="dropcap dc-outline-primary">
                                                Challanges form
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <singleVillageMemberList/>
                </div>
                <div class="mt-3">
                    <panchayatVillage v-if="getVillageDetails.id" />
                    <!-- <singleVillagePanchayatList /> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import SearchVillage from '../components/searchVillage.vue'
import singleVillageMemberList from '../components/singleVillageMemberList.vue'
// import singleVillagePanchayatList from '../components/singleVillagePanchayatList.vue'
import panchayatVillage from '../components/panchayatVillage.vue'
import createLocation from '../components/createLocation.vue';
import { ref, onMounted, computed,watch,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const router = useRouter();
const route = useRoute();
const store = useStore();
const filterFromCountryVillage = ref(false);
const hideOnSelectVillage = ref(true);
const reset = ref(false);
const emitter = inject('emitter');
onMounted(() => {
    // store.state.village.villageData = {};
    hideOnSelectVillage.val = true;
    store.commit(`IS_VILLAGE_PANCHYAT`, {});
    store.commit(`SET_VILLAGE_DETAILS`, {});
    store.commit(`RESET_PANCHAYAT_BODY`, []);
    store.commit(`SET_PANCHAYAT_TYPE`, 'Gram Panchayat'); // set panchayat type default if create village
    store.dispatch('getVilageMemberList', getVillageDetails.value.id)
});
const getVillageDetails = computed(() => {
    return store.state.village.villageData;
});
const villageMemberList = computed(() => {
    return store.state.panchayat.villageMemberList;
});
function handleReset(){
    store.commit(`SET_VILLAGE_DETAILS`, {});
    emitter.emit('resetSingleVillageFormData');
    hideOnSelectVillage.value = true;
    router.push({ name: 'create-village', params: {} });
    window.showAlertMessage('Data reset successfully.', 'success');
}
function callToHideFilter(val){
    filterFromCountryVillage.value = false;
    hideOnSelectVillage.value = false;
    store.dispatch('getVilageMemberList', getVillageDetails.value.id)
}
watch(()=>route.params.id, ()=> {
    console.log(route.params.id)
    if(route.params.id == undefined){
        filterFromCountryVillage.value = false;
    }
});
// function callToHideFilter(village) {
//     console.log(village)
//     new window.Swal({
//       title: 'Are you sure?',
//       html: "You want to create member of <b>"+village+"</b> village",
//       type: 'warning',
//       showCancelButton: true,
//       confirmButtonText: 'Yes! Sure',
//       cancelButtonText: 'No',
//       focusCancel: true,
//       padding: '2em',
//     }).then((result) => {
//       if (result.value) {
//         filterFromCountryVillage.value = false;
//         hideOnSelectVillage.value = false;
//         store.dispatch('getVilageMemberList', getVillageDetails.value.id)
//       }
//     });
//   };
</script>
<style scoped>.create_village_form .top_action {
    display: flex;
    justify-content: space-between;
}

/* .simple-pills .nav-pills .nav-link.active, .simple-pills .nav-pills .show > .nav-link {
    background-color: #2196f3;
    border-color: transparent;
} */
/* .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #3fb65a !important;
    box-shadow: 1px 2px 6px -1px rgb(47 183 78);
} */
.refresh_table h4{
    margin-right: 20px!important;
    margin-top: 5px!important;
    font-size: 20px!important;
}
</style>
<template>
    <!-- {{ initialData }} -->
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div class="panel p-3">
                <!-- {{ $route.params }} -->
                    <div class="row">
                        <div class="col-md-12 hide-show-location">
                            <div class="mb-0 custom_create_btn justify-content-between">
                                <div class="refresh_table mt-3 mb-4 mx-0" v-if="!$route.params.member_id">
                                    <h5 v-if="$route.query.country" class="mb-0">Attach village for country: <b>{{ $route.query.country }}</b></h5>
                                    <h5 v-else class="mb-0"><b>Step 2/3:- </b> Attach village for <b>{{ $route.query.name }}</b> member</h5>
                                </div>
                                <div class="refresh_table m-0" v-else>
                                    <h5 class="mb-0">Update village for country: <b>{{ $route.query.country }}</b></h5>
                                </div>
                                <div class="villageTableButton mx-0" v-if="$route.query.country">
                                    <router-link class="btn btn-danger" :to="{ name: 'member-multiple-locations',query:{'country':$route.query.country,'country_id':$route.query.country_id,'member_id':$route.query.member_id} }">Back</router-link>
                                    <!-- <button @click="router.go(-1)" class="btn btn-danger">Back</button> -->
                                </div>
                                <div class="villageTableButton mx-0" v-else>
                                    <router-link class="btn btn-danger" :to="{ name: 'create-member' }">Go To New Register</router-link>
                                    <!-- <button @click="router.go(-1)" class="btn btn-danger">Back</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form @submit="onSubmit" :key="componentKey" :initial-values="initialData"
                        :validation-schema="schemas" keep-values v-slot="{ handleSubmit, values }">
                        <div class="row align-center">
                            <div class="col-md-12" v-if="!$route.params.village_id">
                                <i class="search_state_pin">Select State | City by Pincode</i>
                                <hr>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="Country">Country <span class="required"></span></label>
                                    <Field id="Country" :disabled="$route.query.country" name="country_id" @change="callForState(values.country_id)" class="form-control" label="Select Country" as="select">
                                        <option :value="null" disabled selected>Select Country</option>
                                        <option v-for="item in CountryOptions" :value="item.country_id">{{ item.country }}</option>
                                    </Field>
                                    <vue-feather v-if="isCountry" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                    <ErrorMessage class="error_alert" name="country_id"/>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="!$route.params.village_id">
                                <div class="form-group">
                                    <label>Search Pincode</label>
                                    <div class="d-flex">
                                        <input type="number" :disabled="!values.country_id" v-model="searchPincode" class="form-control" style="border-radius: 6px 0px 0px 6px;">
                                        <button type="button" class="btn btn-dark" :disabled="!searchPincode" @click="searchPincodeFun(values.country_id)" style="border-radius: 0px 6px 6px 0px;">
                                            <vue-feather v-if="isPostalCodeResponse" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                            <mdicon name="magnify" v-else />
                                        </button>
                                    </div>
                                    <p v-if="isPincodeError" class="text-warning">{{ pincodeErrorMsg.includes('not')?pincodeErrorMsg:'' }}</p>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="!$route.params.village_id"></div>
                            <div class="col-md-12" v-if="!$route.params.village_id">
                                <i class="search_state_pin">Select State | City Manually</i>
                                <hr>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="State">State <span class="required"></span></label>
                                    <Field name="state_id" id="State" @change="callForCity(values.state_id)"
                                        :disabled="stateOptions.length == 0" class="form-control" label="Select State" as="select">
                                        <option :value="null" disabled selected>Select State</option>
                                        <option v-for="item in stateOptions" :value="item.state_id">{{ item.state }}</option>
                                    </Field>
                                    <vue-feather v-if="isState" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                    <ErrorMessage class="error_alert" name="state_id" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="city_id">City <span class="required"></span></label>
                                    <Field name="city_id" id="city_id" @change="callForPincode(values)" :disabled="cityOptions.length == 0" class="form-control"
                                        label="Select State" as="select">
                                        <option :value="null" disabled selected>Select City</option>
                                        <option v-for="item in cityOptions" :value="item.city_id">{{ item.city }}</option>
                                    </Field>
                                    <vue-feather v-if="isCity" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                    <ErrorMessage class="error_alert" name="city_id" />
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="State">Postal code <span class="required"></span></label>
                                    <Field name="postal_code" id="State" @change="callForVillage(values)" :disabled="pincodeOptions.length == 0" class="form-control" label="Select State" as="select">
                                        <option :value="null" disabled selected>Select Pincode</option>
                                        <option v-for="item in pincodeOptions" :value="item.postal_code">{{ item.postal_code }}</option>
                                    </Field>
                                    <vue-feather v-if="isPincode" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                    <ErrorMessage class="error_alert" name="postal_code"/>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <span class="d-flex justify-content-between">
                                        <label for="village">Village<span class="required"></span></label>
                                        <label class="form-check-label" v-if="!$route.params.member_id">
                                            <input v-model="customVillageCheck" type="checkbox" class="form-check-input" value=""> Create new village
                                        </label>
                                    </span>
                                    <Field v-if="!customVillageCheck && !$route.params.member_id" name="village" id="village" :disabled="villageOptions.length == 0 || customVillageCheck" class="form-control" label="Select State" as="select">
                                        <option :value="null" disabled selected>Select village</option>
                                        <option v-for="item in villageOptions" :value="item.village">{{ item.village }}</option>
                                    </Field>
                                    <vue-feather v-if="isVillage" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                    <Field v-if="customVillageCheck || $route.params.member_id" class="form-control" name="village"></Field>
                                    <ErrorMessage class="error_alert" name="village"/>
                                </div>
                            </div>
                            
                            <TextInput name="address_detail" :error="errors.address_detail" label="Address" type="text"
                                placeholder="" />
                            <TextInput name="location_mark" :error="errors.location_mark" label="Location Mark" type="text"
                                placeholder="" />
                            
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6">
                                <div class="action_btn">
                                    <!-- <router-link :to="{ name: 'village-list', params: { params: {} } }" type="button"
                                        class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                                    <span v-if="!$route.params.member_id">
                                        <button v-if="$can('member.location-create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Attach Village
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button v-if="$can('member.location-update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                            <span v-if="isLoading"
                                                class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                            Update Village
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- <pre>{{ values }}</pre> -->
                    </Form>
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <div class="alert alert-light-primary alert-dismissible border-0 mb-4" role="alert">
                                <strong>Village successfully created.</strong> Now you can add members into it.
                                <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                            </div>
                         </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import { ref, reactive, computed, onBeforeMount,onMounted, watch } from 'vue';
import '@/assets/sass/scrollspyNav.scss';
// import highlight from '@/components/plugins/highlight.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useMeta } from '@/composables/use-meta';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const router = useRouter();
const customVillageCheck = ref(false)
const $loading = useLoading({
    // options
});
useMeta({ title: 'Forms' });
const isLoading = ref(false);
const param_id = ref('');
const errors = ref({});
const componentKey = ref(0);

const CountryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);
const villageOptions = ref([]);

const isCountry = ref(false);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);
const isVillage = ref(false);

const searchPincode = ref('');
const pincodeErrorMsg = ref('');
const isPostalCodeResponse = ref(false);
const isPincodeError = ref(false);

const initialData = ref({
    country_id: null,
    state_id: null,
    city_id: null,
    postal_code: null,
    village: null,
    location_mark: null,
    address_detail: null,
    village_id:null
});
 
const schemas = yup.object().shape({
    country_id: yup.string().nullable().required('This field is required.'),
    state_id: yup.string().nullable().required('This field is required.'),
    city_id: yup.string().nullable().required('This field is required.'),
    postal_code: yup.string().nullable().required('This field is required.'),
    village: yup.string().nullable().required('This field is required.'),
});

const urlData = ref('');
const paramsData = ref('');
onBeforeMount(() => {
    const route = useRoute();
    urlData.value = route.query;
    console.log(route.params)
    paramsData.value = route.params
    if (Object.keys(route.params) != 0) {
        edit_member_village(route.params);
    } 
    else{
        callForState(route.query.country_id)
    }
    // if(route.query.country_id != undefined){
    // }
    isCountry.value = true;
    callForCountry();
    initialData.value.country_id = route.query.country_id;
});

const edit_member_village = async (data) => {
    const loader = $loading.show({
        loader: 'spinner',
        zIndex: 999,
        width:40,
    });
    store.dispatch('editMemberLocation', data).then((response) => {
        console.log(response)
        loader.hide();
        if (response.status == 200) {
            console.log(response.data)
            initialData.value = response.data.data;
            if (callForState(response.data.data.country_id)) {
                initialData.value.state_id = response.data.data.state_id;
            }
            if (callForCity(response.data.data.state_id)) {
                initialData.value.city_id = response.data.data.city_id;
            }
            if(callForPincode(response.data.data)){
                initialData.value.postal_code = response.data.data.postal_code;
            }
            // setTimeout(() => {
            //     componentKey.value += 1;
            // }, 1000);
        }
    });
};

function onSubmit(values) {
    isLoading.value = true;
    console.log(initialData.value)
    values['member_id'] = urlData.value.member_id;
    // values['village_id'] = initialData.value.village_id;
    if (paramsData.value.member_id) {
        values['member_id'] = paramsData.value.member_id;
        values['village_id'] = initialData.value.village_id;
        store.dispatch('updateMemberLocation', values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                handleReset();
                window.showAlertMessage(response.data.message, 'success');
                router.push({ path: '/member/multiple-locations', query:{'country':urlData.value.country,'country_id':urlData.value.country_id,'member_id':paramsData.value.member_id}});
            } else {
                if(response.data.errors != undefined){
                    errors.value = response.data.errors;
                }
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('saveMemberLocation', values).then((response) => {
            isLoading.value = false;
            console.log(response)
            if (response.status == 200) {
                handleReset()
                window.showAlertMessage(response.data.message, 'success');
                var checkMemberStatus = localStorage.getItem('memberCreateOrEditStatus');
                if(checkMemberStatus == 'edit'){
                    router.push({ path: '/member/multiple-locations', query:{'country':urlData.value.country,'country_id':urlData.value.country_id,'member_id':urlData.value.member_id}});
                } else{
                    setTimeout(()=>{
                        optionToCreateProfile(values);
                    },1000)
                }
            } else {
                if(response.data.errors != undefined){
                    errors.value = response.data.errors;
                }
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};

function optionToCreateProfile(villageData) {
    new window.Swal({
        title: '',
        html: "Do you want to create profile for <b>"+route.query.name+"</b> member? Press Yes, otherwise No",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusCancel: true,
        padding: '2em',
    }).then((result) => {
        if (result.value) {
            router.push({ path: '/member/profile',query:{'name':route.query.name,'member_id':urlData.value.member_id}});
        } else{
            router.push({ path: '/member/create'});
            // router.push({ path: '/member/multiple-locations', query:{'country':'India','country_id':villageData.country_id,'member_id':urlData.value.member_id}});
        }
    });
};

function searchPincodeFun(country_id){
    console.log(searchPincode.value)
    isPostalCodeResponse.value = true;
    store.dispatch(`getPincodeRelatedData`, {country:country_id,postal:searchPincode.value}).then((res) => {
        console.log(res.data)
        isPostalCodeResponse.value = false;
        if(res.data.message){
            isPincodeError.value = true;
            pincodeErrorMsg.value = res.data.message;
            setTimeout(()=>{
            isPincodeError.value = false;
            },3000)
        }
        if(res.status == 200){
            stateOptions.value = res.data.states;
            cityOptions.value = res.data.cities;
            villageOptions.value = res.data.villages;
            pincodeOptions.value = res.data.postalcodes;

            initialData.value.country_id = res.data.is_country.id;
            if(Object.keys(res.data.is_state).length !=0){
                initialData.value.state_id = res.data.is_state.id;
            }
            if(Object.keys(res.data.is_city).length !=0){
                initialData.value.city_id = res.data.is_city.id;
            }
            initialData.value.postal_code = res.data.is_postal_code;
            // selectVillage.value = res.data.is_village;
            componentKey.value += 1;
        }
    });
}
function callForCountry(){
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        CountryOptions.value = res.data.data;
    });
}

function callForState(country_id) {
    isState.value = true;
    store.dispatch(`getState`, country_id).then((res) => {
        if (res.status == 200) {
            isState.value = false;
            stateOptions.value = res.data.data;
            return true;
        }

    });
}
function callForCity(state_id) {
    isCity.value = true;
    store.dispatch(`getcities`, state_id).then((res) => {
        if (res.status == 200) {
            isCity.value = false;
            cityOptions.value = res.data.data;
            return true;
        }
    });
}

function callForVillage(data) {
    // selectVillage.value = {};
    console.log(data)
    isVillage.value = true;
    store.dispatch(`getVillageOfPincode`, data).then((res) => {
        console.log(res.data)
        villageOptions.value = res.data.data;
        isVillage.value = false;
    });
}
const pincodeData = ref({});
function callForPincode(data) {
    console.log(data)
    isPincode.value = true;
    pincodeData.value['country_id'] = data.country_id;
    pincodeData.value['state_id'] = data.state_id;
    pincodeData.value['city_id'] = data.city_id;
    store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
        console.log(res)
        isPincode.value = false;
        if(res.status == 200){
            pincodeOptions.value = res.data.data;
            // initialData.value.postal_code = null;
            // componentKey.value += 1;
            initialData.value.city_id = data.city_id;
        }
    });
}
function handleReset() {
    errors.value = [];
    initialData.value = {
        country_id: null,
        state_id: null,
        city_id: null,
        postal_code: null,
        village: null,
        address_detail: null,
    }
}
</script>
<style scoped>
.action_btn {
    display: flex;
    justify-content: flex-end;
}

.action_btn button {
    margin-left: 10px;
}

.form-part {
    margin: 0px 0px 20px
}

.form-part h6 {
    font-weight: bold;
}

.panel .panel-heading h4 {
    font-size: 21px;
}

.hide-show-location {
    text-align: right;
    margin-bottom: 10px;
}</style>
import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	villageData:{},
};
const getters = {
	
};
const mutations = {
	SET_VILLAGE_DETAILS(state,context){
		state.villageData = context;
	},
	
};
const actions = {
	// VillageList api
	async getVillageList({ commit, dispatch }, getData) {
		let api_url_country = '';
		if(getData.country_id){
			api_url_country +=`&country=${getData.country_id}`
		}
		let api_url_state = '';
		if(getData.state_id){
			api_url_state +=`&state=${getData.state_id}`
		}
		let api_url_city = '';
		if(getData.city_id){
			api_url_city +=`&city=${getData.city_id}`
		}
		let api_url_postal = '';
		if(getData.postal){
			api_url_postal +=`&postal=${getData.postal}`
		}
		let api_url_village = '';
		if(getData.v_id){
			api_url_village +=`&v_id=${getData.v_id}`
		}
		return await axios.get(`/dashboard/villages?search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}${api_url_country}${api_url_state}${api_url_city}${api_url_postal}${api_url_village}`).then(response => {
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async saveVillage({ commit, dispatch }, getData) {
		return await axios.post(`/dashboard/village`, getData).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	// async deleteVillage({ commit, dispatch }, village_id) {
	// 	return await axios.delete(`/dashboard/villages/delete`,{data:village_id}).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },dashboard/member/trash
	async deleteVillage({ commit, dispatch }, village_id) {
		return await axios.delete(`/dashboard/village/trash/${village_id.ids[0]}`).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async editVillage({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/village/edit/${getData.id}`).then(response => {
			console.log(response)
			commit('SET_VILLAGE_DETAILS',response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async updateVillage({ commit, dispatch }, getData) {
		return await axios.put(`/dashboard/village/update`, getData).then(response => {
			console.log(response)
			commit('SET_VILLAGE_DETAILS',response.data)
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async getSingleVillageMemberList({ commit, dispatch }, getData) {
		// if(getData.village_id){
		return await axios.get(`/dashboard/village/members?village_id=${getData.village_id}&search=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`).then(response => {
            return (response);
		}).catch(error => {
			return error.response;
		});
		// }
	},

	async getPanchayatTitleList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/panchayat/title/select`).then(response => {
            return (response);
		}).catch(error => {
			return error.response;
		});
	},
	async downloadVillageSampleFile({ commit, dispatch }) {
		return await axios.get(`/dashboard/download/village-sample`).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async exportVillageData({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/download/village-data`,{ 
			params: getData,
		 }).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async importVillage({ commit, dispatch }, getData) {
		console.log(getData)
		return await axios.post(`/dashboard/import/village-data`,getData,{
		  headers: {
			'Content-Type': 'multipart/form-data',
		  },
		}).then(response => {
		  return (response);
		}).catch(error => {
		  return error.response;
		});
	},
	async getPincodeRelatedData({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/postalcodes/search`,{
			params:getData
		}).then(response => {
			return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
};
export default {
	state,
	mutations,
	actions,
	getters,
};

import tagsList from '../../views/panchayat/tables/tagsList.vue';
import tags from '../../views/panchayat/forms/tags.vue';

export default [
	{
		path: '/tags/list',
		name: 'tags-list',
		component: tagsList,
		meta: { requiresAuth: true, requiredPermissions:'tag.list' }
	},
	{
		path: '/tags/create',
		name: 'create-tags',
		component: tags,
		meta: { requiresAuth: true, requiredPermissions:'tag.create' },
	},
	// {
	// 	path: '/village/list/edit/:id',
	// 	name: 'village-edit',
	// 	component: createVillage,
	// 	meta: { requiresAuth: true },
	// },
	// {
	// 	path: '/village/import',
	// 	name: 'importCsv-village',
	// 	component: importCsv,
	// 	meta: { requiresAuth: true },
	// },
];

import router from '@/router';
import axios from 'axios';
import store from '../index.js'
const state = {
	// is_constitution:[],
	is_constitution_select:{},
};
const getters = {
};
const mutations = {
	// IS_CONSTITUTION(state,context){
	// 	state.is_constitution = context;
	// },
	IS_CONSTITUTION_SELECTED(state,context){
		state.is_constitution_select = context;
	}
};
const actions = {
	async searchConstitutionList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/constitution/list`).then(response => {
			console.log(response)
			// commit('IS_CONSTITUTION',response.data.data)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async searchArticleList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/article/list`,{
			params:getData
        }).then(response => {
			console.log(response)
			// commit('IS_CONSTITUTION',response.data.data)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async searchSectionList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/section/list`,{
			params:getData
        }).then(response => {
			console.log(response)
			// commit('IS_CONSTITUTION',response.data.data)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	async searchPointList({ commit, dispatch }, getData) {
		return await axios.get(`/dashboard/point/list`,{
			params:getData
        }).then(response => {
			console.log(response)
			// commit('IS_CONSTITUTION',response.data.data)
            return (response);
		})
			.catch(error => {
				return error.response;
			});
	},
	// async getTagsDropdownListStore({ commit, dispatch }, getData) {
	// 	return await axios.get(`/dashboard/tag/list`,{
    //         params:getData
    //     }).then(response => {
    //         return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async saveConstitutionStore({ commit, dispatch }, getData) {
	// 	return await axios.post(`/dashboard/constitution/create`, getData).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async updateConstitutionStore({ commit, dispatch }, getData) {
	// 	return await axios.put(`/dashboard/constitution/update`, getData).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },

	// async deleteConstitutionStore({ commit, dispatch }, id) {
	// 	return await axios.delete(`/dashboard/constitution/trash/${id}`).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	// async editConstitutionStore({ commit, dispatch }, id) {
	// 	return await axios.get(`/dashboard/constitution/edit/${id}`).then(response => {
	// 		return (response);
	// 	})
	// 		.catch(error => {
	// 			return error.response;
	// 		});
	// },
	
};
export default {
	state,
	mutations,
	actions,
	getters,
};


<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Village</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>List</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
  <div style="padding: 0 20px 0 18px !important">
    <div class="panel-body" >
      <Transition name="fade">
        <searchPincode v-if="export_filter"></searchPincode>
      </Transition>
    </div>
    <div class="row layout-top-spacing">
      <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing pb-0">
        <div class="vl-parent panel br-6 p-0">
          <loading v-model:active="destinationLoading" :is-full-page="false" color="#515365" loader="spinner" :width="35"
            :z-index="9" />
          <div class="p-3 mb-0 custom_create_btn justify-content-between">
            <div class="refresh_table m-0">
              <h5 class="mb-0">Villages</h5>
              <a href="javascript:void(0);" @click="recall += 1">
                <vue-feather type="rotate-cw" size="16"></vue-feather>
              </a>
            </div>
            <div class="villageTableButton mx-0">
              <button type="button" v-if="isSelectVillageList" class="btn btn-danger"
                @click="delete_village(village_ids)">
                <vue-feather type="trash-2" size="18"></vue-feather>
              </button>
              <button type="button" class="btn btn-primary" @click="export_filter =! export_filter">
                Advance Filter & Export &nbsp;<vue-feather :type="!export_filter?'eye':'eye-off'" size="11"></vue-feather>
              </button>
              <router-link v-if="$can('village.import')" :to="{ name: 'importCsv-member',query:{type:'villageCSV'} }" class="m-1"><button type="button"
                  class="btn btn-primary">Import Village</button> </router-link>
              <router-link v-if="$can('village.create')" :to="{ name: 'create-village' }"><button type="button" class="btn btn-primary m-1">Create</button>
              </router-link>
            </div>
          </div>
          <div class="custom-table destination-table">
            <v-server-table :columns="columns" :options="villageTableOption" :key="recall"
              @select="(selectedAddRow = $event), checkAddRow()">
              <template #village="props">
                <span class="badge badge-light-success" v-if="props.row.village">{{ props.row.village }}</span>
                <span v-else>---</span>
              </template>
              <template #administrative_block="props">
                <span v-if="props.row.administrative_block">{{ props.row.administrative_block }}</span>
                <span v-else>---</span>
              </template>
              <template #panchayat="props">
                <span class="badge badge-light-success" v-if="props.row.panchayat">{{ props.row.panchayat }}</span>
                <span v-else>---</span>
              </template>
              <template #enable="props">
                <label class="switch s-icons s-outline s-outline-success mb-4 me-2"
                  @click="village_status_change(props.row.id, props.row.enable)">
                  <input type="checkbox" disabled :checked="true" />
                  <span class="slider round" />
                </label>
                <span v-if="props.row.enable" class="badge badge-success">{{ $t('Yes') }}</span>
                <span class="badge badge-danger" v-else>{{ $t('No') }}</span>
              </template>

              <template #location="props">
                <span class="d-block"><b>City:</b> {{ props.row.city }},</span>
                <span class="d-block"><b>State:</b> {{ props.row.state }},</span>
                <span class="d-block"><b>Country:</b> {{ props.row.country }}</span>
              </template>

              <template #action="props">
                <div class="">
                  <router-link v-if="$can('village.edit')" title="Edit" class="edit_button mx-1"
                    :to="{ name: 'village-edit', params: { id: props.row.id } }">
                    <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                  </router-link>
                  <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                  <a href="javascript:void(0);" v-if="!isSelectVillageList && $can('village.delete')" title="Delete" class="delete_button mx-1"
                    @click="delete_village([props.row.id])">
                    <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                  </a>
                  <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                </div>
              </template>
            </v-server-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import searchPincode from '../components/searchPincode.vue';
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, onBeforeUnmount,inject } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const store = useStore();
const selectedAddRow = ref([]);
const village_ids = ref([]);
const isSelectVillageList = ref(false)
const export_filter = ref(false)
useMeta({ title: 'Village' });
const { t } = useI18n();
const emitter = inject('emitter');
const columns = ref([
  'village',
  'members_count',
  'panchayat',
  'administrative_block',
  'location',
  'postal_code',
  "action"
]);
const recall = ref(0);
const destinationLoading = ref(false);
emitter.on('exportList', (data) => {
  store.dispatch('exportVillageData', data).then((response) => {
      console.log(response)
      if (response.status == 200) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "text/csv",
        });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "villagesData.csv";
        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the link element
      } else {
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
});

const filterData = ref({});
emitter.on('filterList', (data) => {
  filterData.value = data;
  recall.value += 1;
});

onBeforeUnmount(() => {
  emitter.off('exportList');
  emitter.off('filterList');
});
const villageTableOption = ref({
  headings: {
    location: 'Location',
    administrative_block: "Administrative Block",
    postal_code: 'Postal Code'
  },
  selectable: {
    mode: 'multiple', // or 'multiple'
    // only: function(row) {
    //   console.log(row)
    //   return true // any condition
    // },
    selectAllMode: 'page', // or 'page',
    programmatic: false
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 10,
  perPageValues: [10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['village', 'panchayat'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5, edge: true, show: true },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    destinationLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    const requestData = Object.assign({}, filterData.value, pagination);
    return store.dispatch(`getVillageList`, requestData).then((res) => {
      destinationLoading.value = false;
      return { data: res.data.data, count: res.data.total };
    });

  },
});
//  select multiple village
function checkAddRow() {
  village_ids.value = selectedAddRow.value.map((el) => {
    return el.id;
  });
  if (village_ids.value.length != 0) {
    isSelectVillageList.value = true;
  } else {
    isSelectVillageList.value = false;
  }
}
function delete_village(ids) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deleteVillage', { ids: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          isSelectVillageList.value = false;
          recall.value += 1;
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};
const village_status_change = (id, status, interface_name, role, data) => {
  new window.Swal({
    title: 'Are you sure?',
    html: t('You want to disable on interface') + ' <br><strong>' + interface_name + '</strong>',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff5f5f',
    confirmButtonText: 'Disable',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('ChangeStatus', id).then((response) => {
        if (response.status == 200) {
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};
</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .villageTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>   
  
import { subject } from "@casl/ability";
import axios from "axios";

const menus = [
    {
        title:'Dashboard',
        icon:'monitor-dashboard',
        permission:'dashboard_view',
        child:[
            {
                title:'Dashboard',
                route:'/',
                icon:'music',
                permission:'dashboard.home',
            },
            // {
            //     title:'Dashboard 2',
            //     route:'/index2',
            //     icon:'music',
            //     permission:'dashboard.index1',
            // },
        ]
    },
    {
        title:'Member',
        icon:'account-supervisor',
        permission:'member_view',
        child:[
            {
                title:'Create',
                route:'/member/create',
                icon:'music',
                permission:'member.create',
            },
            {
                title:'List',
                route:'/member/list',
                icon:'music',
                permission:'member.list',
            },
        ]
    },
    {
        title:'Village',
        icon:'home-group',
        permission:'village_view',
        child:[
            {
                title:'Create',
                route:'/village/create',
                icon:'music',
                permission:'village.create',
            },
            {
                title:'List',
                route:'/village/list',
                icon:'music',
                permission:'village.list',
            },
        ]
    },
    {
        title:'Panchayat',
        icon:'account-group-outline',
        permission:'panchayat_view',
        child:[
            {
                title:'Create',
                route:'/panchayat/create',
                icon:'music',
                permission:'panchayat.create',
            },
            {
                title:'List',
                route:'/panchayat/list',
                icon:'music',
                permission:'panchayat.list',
            },
        ]
    },
    {
        title:'Tags',
        icon:'tag-multiple-outline',
        permission:'tag_view',
        child:[
            {
                title:'Create',
                route:'/tags/create',
                icon:'music',
                permission:'tag.create',
            },
            {
                title:'List',
                route:'/tags/list',
                icon:'music',
                permission:'tag.list',
            },
        ]
    },
    // {
    //     title:'By Laws',
    //     icon:'book-open-variant',
    //     permission:'bylaws_view',
    //     child:[
            // {
            //     title:'Create',
            //     route:'/bylaws/create',
            //     icon:'music'
            // },
            // {
            //     title:'List',
            //     route:'/bylaws/list',
            //     icon:'music',
            //     permission:'bylaws.list',
            // },
    //     ]
    // },
    {
        title:'Settings',
        icon:'cog-outline',
        permission:'settings_view',
        child:[
            {
                title:'Roles',
                route:'/settings/roles',
                icon:'',
                permission:'user.list',
            }
        ]
    },
]
export default menus;

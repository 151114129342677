import memberList from '../../views/panchayat/tables/memberList.vue';
import createMember from '../../views/panchayat/forms/createMember.vue';
import importCsv from '../../views/panchayat/forms/importCsv.vue'

export default [
	{
		path: '/member/list',
		name: 'member-list',
		component: memberList,
		meta: { requiresAuth: true,requiredPermissions:'member.list' }
	},
	{
		path: '/member/create',
		// path: '/',
		name: 'create-member',
		component: createMember,
		meta: { requiresAuth: true,requiredPermissions:'member.create' },
	},
	{
		path: '/member/list/edit/:id',
		name: 'member-edit',
		component: createMember,
		meta: { requiresAuth: true },
	},
	{
		path: '/member/import',
		name: 'importCsv-member',
		component: importCsv,
		meta: { requiresAuth: true },
	},
];

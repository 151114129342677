<template>
  <div class="container">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Setting</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Location</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
    </div>
    <div class="layout-px-spacing">
        <location></location>
        <!-- <pre>{{ $store.state.settings.savedGlobalSettings}}</pre> -->
    </div>
</template>
<script setup>
import { ref, onMounted,onBeforeUnmount, computed,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import location from '../components/searchPincode.vue'

const store = useStore();

onMounted(() => {
  // store.commit('SET_GLOBAL_CONFIG_SETTING')

});

const emitter = inject('emitter');
emitter.on('setCountry', (data) => {
  console.log(data)
  store.commit('SET_GLOBAL_CONFIG_SETTING',{'data':data,'type':'country'})
});
emitter.on('setState', (data) => {
  console.log(data)
  store.commit('SET_GLOBAL_CONFIG_SETTING',{'data':data,'type':'state'})
});
emitter.on('setCity', (data) => {
  console.log(data)
  store.commit('SET_GLOBAL_CONFIG_SETTING',{'data':data,'type':'city'})
});
emitter.on('setPincode', (data) => {
  console.log(data)
  store.commit('SET_GLOBAL_CONFIG_SETTING',{'data':data,'type':'pincode'})
});
onBeforeUnmount(() => {
    emitter.off('setCountry');
    emitter.off('setState');
    emitter.off('setCity');
    emitter.off('setPincode');
});
</script>

<template>
  <div class="layout-spacing">
    <div class="vl-parent panel br-6 p-0">
      <div class="m-3 mb-0 custom_create_btn justify-content-between">
        <loading v-model:active="destinationLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
        <div class="refresh_table m-0">
          <a href="javascript:void(0);" @click="tableUpdate += 1">
            <vue-feather type="rotate-cw" size="16"></vue-feather>
          </a>
        </div>
        <div class="villageTableButton">
          <span>
            <button :disabled="ifZilaPanchayatCreated"
              @click="getPincodeOfCurrentCity(), $store.state.panchayatSamiti.panchayats_body = []" type="button"
              class="btn btn-primary m-1" data-bs-toggle="modal" data-bs-target="#villageSamitiModal">Create Zila Panchayat</button>
          </span>
        </div>
      </div>
      <div class="modal fade" id="villageSamitiModal" tabindex="-1" role="dialog"
        aria-labelledby="villageSamitiModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title" v-if="!param_id" id="villageSamitiModalLabel">Create Zila Panchayat</h5>
              <h5 class="modal-title" v-else id="villageSamitiModalLabel">Update Zila Panchayat</h5>
              <button type="button" id="panchayatModalClose" data-dismiss="modal" data-bs-dismiss="modal"
                aria-label="Close" class="btn-close"></button>
            </div>
            <div class="modal-body">
              <div class="addedMemberTitle">
                <form novalidate @submit.stop.prevent="addPanchayatBodyRow(panchayat_body_formdata)"
                  style="border-bottom:1px dashed #c2c2c2;padding-bottom: 10px;">
                  <div class="row align-center">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Pincode</label>
                        <multiselect v-model="panchayat_body_formdata.pincode" :options="pincodeOfCityOptions"
                          label="title" :searchable="true" @select="callPincodeForVillage" :preselect-first="true"
                          selected-label="" :loading="pincodeInputLoad" placeholder="Select Pincode" select-label="" deselect-label=""
                          :class="[addPanchayatBody ? (panchayat_body_formdata.pincode ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div class="invalid-feedback">Please select the pincode</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Village</label>
                        <multiselect v-model="panchayat_body_formdata.village" :options="villageOfPincodeOptions"
                          label="village" :searchable="true" @select="callVillageForMember" :preselect-first="true"
                          selected-label="" :loading="villageInputLoad" :disabled="villageOfPincodeOptions.length == 0" placeholder="Select Village" select-label="" deselect-label=""
                          :class="[addPanchayatBody ? (panchayat_body_formdata.village ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div class="invalid-feedback">Please select the village</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Member</label>
                        <multiselect v-model="panchayat_body_formdata.member" :options="memberOptions" label="name"
                          :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Member"
                          select-label="" deselect-label="" :loading="memberInputLoad" :disabled="memberOptions.length == 0"
                          :class="[addPanchayatBody ? (panchayat_body_formdata.member ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div v-if="checkMemberInList" class="text-danger">Member Not Found.</div>
                        <div class="invalid-feedback">Please select the member</div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="">Title</label>
                        <multiselect v-model="panchayat_body_formdata.title" :options="panchayatTitleOption" label="title"
                          :searchable="true" :preselect-first="true" selected-label="" placeholder="Select Title"
                          select-label="" deselect-label="" :disabled="panchayatTitleOption.length == 0"
                          :class="[addPanchayatBody ? (panchayat_body_formdata.title ? '' : 'is-invalid') : '']">
                        </multiselect>
                        <div class="invalid-feedback">Please select the title</div>
                      </div>
                    </div>
                    <div class="col-md-4 mt-auto">
                      <div class="form-group">
                        <label for=""></label>
                        <button type="submit" class="btn btn-primary my-2">Add</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-4">
                      <table class="table b-table table-hover table-bordered mb-0 mt-3">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Member</th>
                            <th scope="col">Village</th>
                            <th scope="col">Title</th>
                            <th scope="col" class="text-center">Remove</th>
                          </tr>
                        </thead>
                        <tbody v-show="$store.state.panchayatSamiti.panchayats_body.length > 0">
                          <tr v-for="(item, index) in  $store.state.panchayatSamiti.panchayats_body" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ item.member_name?item.member_name:'---' }}</td>
                            <td>{{ item.village?item.village:'---' }}</td>
                            <td>{{ item.title?item.title:'---' }}</td>
                            <td class="text-center">
                              <vue-feather @click="removePanchayatBodyRow(index)" type="trash-2" size="18"
                                stroke="red"></vue-feather>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
              <Form @submit="onSubmit" :key="componentKey" :initial-values="initialData" :validation-schema="schemas"
                keep-values v-slot="{ handleSubmit, values }">
                <div class="row align-center">
                  <TextInput :error="errors.period_start" name="period_start" label="Period start" type="date" placeholder="" />
                  <TextInput :error="errors.period_end" name="period_end" label="Period end" type="date" placeholder="" />
                  <SelectInput :error="errors.status" name="status" :options="statusOption" label="Select Status" />
                  <TextInput :error="errors.formation_description" name="formation_description" cols="12" label="Formation Description" placeholder="" />
                </div>

                <div class="row mt-2">
                  <div class="col-md-6">
                    <!-- <router-link  :to="{ name: 'member-list', params: { params: {} } }" type="button" class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                  </div>
                  <div class="col-md-6">
                    <div class="next_prev">
                      <span v-if="!param_id">
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Save
                        </button>
                      </span>
                      <span v-else>
                        <button :disabled="isLoading" type="submit" class="btn btn-primary">
                          <span v-if="isLoading"
                            class="spinner-border text-white me-2 align-self-center loader-sm">Loading...</span>
                          Update
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-table destination-table">
        <v-server-table :columns="columns" :options="panchayatList" :key="tableUpdate"
          @select="(selectedAddRow = $event), checkAddRow()">
          <template #village="props">
            <span class="badge badge-light-success" v-if="props.row.village">
              {{ props.row.village }}</span>
            <span v-else>---</span>
          </template>
          <template #status="props">
            <span class="badge badge-light-success" v-if="props.row.status == 'active'">Active</span>
            <span v-else class="badge badge-light-danger">Inactive</span>
          </template>
          <template #child_row="props" slot="tableHeader">
            <div class="panel-body p-0">
              <div class="">
                <table class="table b-table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in  props.row.zila_panchayat_members" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.member_name }}</td>
                      <td>{{ item.title }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template #action="props">
            <div class="">
              <router-link v-if="$can('panchayat.edit')" title="Edit" data-bs-toggle="modal" data-bs-target="#villageSamitiModal"
                @click="editPanchayatSamiti(props.row.id)" class="edit_button mx-1" to="" tag="button">
                <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
              </router-link>
              <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
              <a href="javascript:void(0);" v-if="$can('panchayat.delete')" title="Delete" class="delete_button mx-1"
                @click="delete_panchayat([props.row.id])">
                <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
              </a>
              <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
            </div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import '@/assets/sass/scrollspyNav.scss';
import '@/assets/sass/components/tabs-accordian/custom-tabs.scss';
import { onMounted, ref, onBeforeMount, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
const store = useStore();
const route = useRoute();
const selectedAddRow = ref([]);
const isLoading = ref(false);
const villageInputLoad = ref(false);
const pincodeInputLoad = ref(false);
const memberInputLoad = ref(false);
const addPanchayatBody = ref(false);
const ifZilaPanchayatCreated = ref(false);

const pincodeOfCityOptions = ref([]);
const villageOfPincodeOptions = ref([]);
const memberOptions = ref([]);
const checkMemberInList = ref(false);
const componentKey = ref([]);
const errors = ref({});
useMeta({ title: 'Village' });
const { t } = useI18n();
const emitter = inject('emitter');
emitter.on('refreshZilaPanchayatList', () => {
  tableUpdate.value += 1;
});
import { useLoading } from 'vue-loading-overlay';
const $loading = useLoading({
  // options
});
const columns = ref([
  'city', 'panchayat_type',
  'period_start',
  'period_end',
  'status',
  "action"
]);
const tableUpdate = ref(0);
const destinationLoading = ref(false);
const panchayatList = ref({
  headings: {
    'panchayat_type': 'Panchayat Type',
    'period_start': 'Period Start',
    'period_end': 'Period End',
  },
  columnsClasses: { actions: 'actions text-center' },
  perPage: 5,
  perPageValues: [5, 10, 25, 50, 100],
  skin: 'table',
  columnsClasses: { actions: 'actions text-center' },
  sortable: ['name', 'email'],
  sortIcon: {
    base: 'sort-icon-none',
    up: 'sort-icon-asc',
    down: 'sort-icon-desc',
  },
  pagination: { nav: 'scroll', chunk: 5 },
  texts: {
    count: t('Showing') + ' {from} ' + t('to') + ' {to} ' + t('of') + ' {count}',
    filter: '',
    filterPlaceholder: 'Search...',
    limit: 'Results:',
  },
  resizableColumns: false,
  async requestFunction(data) {
    destinationLoading.value = true;
    const pagination = data;
    const direction = data.ascending == 1 ? 'asc' : 'desc';
    data.direction = direction;
    data.orderBy = data.orderBy ? data.orderBy : 'id';
    pagination['city_id'] = store.state.zilaPanchayat.country_state_city.city_id;
    // if(store.state.village.villageData.id != undefined){
    return store.dispatch(`getZilaPanchayatOfCity`, pagination).then((res) => {
      destinationLoading.value = false;
      console.log(res)
      if (res.data.data.length != 0) {
        ifZilaPanchayatCreated.value = true;
      } else {
        ifZilaPanchayatCreated.value = false;
      }
      return { data: res.data.data, count: res.data.total };
    });
    // }

  },
});
const is_village_panchayat = computed(() => {
  return store.state.panchayat.is_village_panchayat;
});
function delete_panchayat(ids) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.dispatch('deletePanchayatSamiti', { ids: Object.values(ids) }).then((response) => {
        if (response.status == 200) {
          handleReset();
          window.showAlertMessage(response.data.message, 'success');
        } else {
          window.showAlertMessage(response.data.message, 'warning');
        }
      });
    }
  });
};
//// ======================= add panchayat ===============///
const panchayatTitleOption = ref([]);

const statusOption = ref([
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'Hold', value: 'hold' },
  { name: 'Expired', value: 'expired' }
]);

const initialData = ref({
  edit_id: route.params.id,
  period_start: null,
  period_end: null,
  status:null,
  formation_description: null,
})

const schemas = yup.object().shape({
  period_start: yup.string().nullable().required('This field is required.'),
  period_end: yup.string().nullable().required('This field is required.'),
  status: yup.string().nullable().required('This field is required.'),
  formation_description: yup.string().nullable().required('This field is required.'),
});
const getZilaPanchayatData = computed(() => {
  return store.state.zilaPanchayat.cityDetail;
});
const getCountryStateCity = computed(() => {
  return store.state.zilaPanchayat.country_state_city;
});

onBeforeMount(() => {
  // store.commit(`SET_VILLAGE_DETAILS`, {});
  // store.commit('ADD_PANCHAYATS_BODY', []);
  store.dispatch(`getPanchayatTitleList`).then((res) => {
    console.log(res.data)
    panchayatTitleOption.value = res.data.data;
  });
});
const panchayat_body_formdata = ref({
  pincode: '',
  village: '',
  member: '',
  title: ''
});

function getPincodeOfCurrentCity() {
  pincodeInputLoad.value = true;
  store.commit('ADD_PANCHAYATS_BODY');
  store.dispatch(`getPincodeOfSelectCity`, getCountryStateCity.value).then((res) => {
    pincodeInputLoad.value = false;
    pincodeOfCityOptions.value = res.data.data.map((code)=>{
      return {title:code.postal_code,value:code.postal_code}
    });
  });
}
function callPincodeForVillage(data) {
  villageInputLoad.value = true;
  panchayat_body_formdata.value = {
    pincode: '',
    village: '',
    member: '',
    title: ''
  };
  store.dispatch(`getVillageOfSelectPincode`, data.value).then((res) => {
    villageInputLoad.value = false;
    villageOfPincodeOptions.value = res.data.data;
  });
}

function callVillageForMember(data) {
  memberInputLoad.value = true;
  store.dispatch(`getMemberOfSelectVillageForZilapanchayat`, data.village_id).then((res) => {
    memberInputLoad.value = false;
    console.log(res.data.data)
    if(res.data.data.length == 0){
      checkMemberInList.value = true;
    } else {
      checkMemberInList.value = false;
      memberOptions.value = res.data.data;
    }
  });
}

function addPanchayatBodyRow(row) {
  console.log(row)
  if (row.pincode == '' || row.village == '' || row.member == '' || row.title == '') {
    addPanchayatBody.value = true;
    return false;
  } else {
    var newRow = {
      'pincode':row.pincode.value,
      'village': row.village.village,
      'village_id': row.village.village_id,
      'member_name': row.member.name,
      'member_id': row.member.member_id,
      'title': row.title.title,
      'panchayat_title_id': row.title.panchayat_title_id
    };
  var checkStatus = store.state.panchayatSamiti.panchayats_body.map((item) => {
      if(item.member_name == newRow.member_name){  // if need || (item.title == newRow.title)
        return true;
      } return false;
    }).includes(true)
    if(checkStatus){
      window.showAlertMessage('"'+newRow.member_name+'" member already added', 'warning');
      return false;
    }
    store.commit('ADD_PANCHAYATS_BODY', newRow);
    panchayat_body_formdata.value = {
      pincode: '',
      village: '',
      member: '',
      title: ''
    };
    addPanchayatBody.value = false;
  }
}
function removePanchayatBodyRow(id) {
  new window.Swal({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      store.commit('REMOVE_PANCHAYATS_BODY', id);
    }
  });
};
const param_id = ref('');
function editPanchayatSamiti(panchayat_samiti_id) {
  getPincodeOfCurrentCity();
  param_id.value = panchayat_samiti_id;
  const loader = $loading.show({
    loader: 'spinner',
    zIndex: 999,
    width:40
  });
  store.dispatch('editPanchayatSamitiRow', { id: panchayat_samiti_id }).then((response) => {
    console.log(response)
    if (response.status == 200) {
      initialData.value = response.data;
      setTimeout(() => {
        componentKey.value += 1;
      }, 100);
      loader.hide();
    } else {
      loader.hide();
    }
  });
};
// ================= Add / Update panchayat =======================
function onSubmit(values) {
  if (store.state.panchayatSamiti.panchayats_body.length == 0) {
    alert('Please fill panchayat body form')
    return false
  } else {
    values['panchayat_body'] = store.state.panchayatSamiti.panchayats_body;
  }
  console.log(store.state.zilaPanchayat.country_state_city)
  console.log(values['panchayat_body'])
  isLoading.value = true;
  values['panchayat_type'] = store.state.panchayat.panchayat_type;
  values['formation_type'] = "app-admins";  // Hidden data
  console.log(values)
  let formData = { ...values, ...store.state.zilaPanchayat.country_state_city };
  if (param_id.value) {
    formData['id'] = param_id.value;
    store.dispatch('updateVillagePanchayat', formData).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  } else {
    store.dispatch('saveVillagePanchayat', formData).then((response) => {
      isLoading.value = false;
      if (response.status == 200) {
        handleReset();
        window.showAlertMessage(response.data.message, 'success');
      } else {
        errors.value = response.data.errors;
        window.showAlertMessage(response.data.message, 'warning');
      }
    });
  }
}
function handleReset() {
  console.log('handle')
  errors.value = [];
  componentKey.value += 1;
  tableUpdate.value += 1;
  param_id.value = '';
  initialData.value = {};
  document.getElementById('panchayatModalClose').click();
}
</script>
<style>
.add-btn1 {
  margin-left: 20px;
  margin-top: 20px;
}

.panel .villageTableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 15px;
}

.villageTableButton span {
  text-align: right;
}

.switch.s-icons {
  width: 40px;
  height: unset;
}

.switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.dark .switch.s-outline .slider {
  border: 2px solid #ff5f5f;
}

.layout-top-spacing .switch.s-outline .slider:before {
  border: 2px solid #ff5f5f !important;
  background-color: #ff5f5f !important;
}

.switch.s-outline-success input:checked+.slider:before {
  border: 2px solid #1abc9c !important;
  background-color: #1abc9c !important;
}</style>   

<template>
    <div class="row layout-top-spacing">
        <div id="fs2Basic" class="col-lg-12">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading">
                    <h4>Select City</h4>
                </div>
                <div class="panel-body select2">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Country</label>
                                <multiselect v-model="selectCountry" :options="countryOptions" label="country"
                                    :searchable="true" :preselect-first="true" selected-label=""
                                    placeholder="Search..." :loading="isCountry" select-label="" deselect-label=""
                                    @select="callForState($event)"></multiselect>
                                <!-- <vue-feather v-if="isCountry" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather> -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>State</label>
                                <multiselect v-model="selectState" :disabled="stateOptions.length == 0"
                                    :options="stateOptions" label="state" :searchable="true" :preselect-first="true"
                                    selected-label="" placeholder="Search..." select-label="" deselect-label=""
                                    @select="callForCity($event)" :loading="isState"></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>City</label>
                            <multiselect v-model="selectCity" :disabled="cityOptions.length == 0" :options="cityOptions"
                                label="city" :searchable="true" :preselect-first="true" selected-label=""
                                placeholder="Search..." select-label="" :loading="isCity" deselect-label=""></multiselect>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label>Pincode</label>
                                <multiselect v-model="selectPincode" :disabled="pincodeOptions.length == 0" :options="pincodeOptions" 
                                    label="postal_code" :searchable="true" :preselect-first="true" selected-label=""
                                    :loading="isPincode" select-label="" deselect-label=""></multiselect>
                            </div>
                        </div> -->
                        <div class="col-xl-4 col-md-4 col-sm-4 col-4 my-auto">
                            <label for=""></label>
                            <button tyle="button" :disabled="Object.keys(selectCity).length == 0" @click="selectCityEvent" class="btn btn-primary mt-3"> SELECT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const store = useStore();
const selectCountry = ref({});
const selectState = ref({});
const selectCity = ref({});
const selectPincode = ref(null)
const router = useRouter();
const route = useRoute();
const countryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);

const isCountry = ref(false);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);

onMounted(() => {
    console.log(route.path)
    isCountry.value = true;
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        countryOptions.value = res.data.data;
    });
    globalSettings();
});
const preSelectLocationData = computed(()=>{
    return store.state.settings.savedGlobalSettings;
});
function globalSettings(){
    console.log(preSelectLocationData.value.pincode)
    if(Object.keys(preSelectLocationData.value.country).length != 0){
        selectCountry.value = preSelectLocationData.value.country.country_id;
        stateOptions.value = preSelectLocationData.value.country.stateData;
    }
    if(Object.keys(preSelectLocationData.value.state).length != 0){
        selectState.value = preSelectLocationData.value.state.state_id;
        cityOptions.value = preSelectLocationData.value.state.cityData;
    }
    if(Object.keys(preSelectLocationData.value.city).length != 0){
        selectCity.value = preSelectLocationData.value.city.city_id;
        // pincodeOptions.value = preSelectLocationData.value.city.pincodeData;
    }
    // if(Object.keys(preSelectLocationData.value.pincode).length != 0){
    //     selectPincode.value = preSelectLocationData.value.pincode.pincode_id;
    // }
};
function callForState(data) {
    isState.value = true;
    store.dispatch(`getState`, data.country_id).then((res) => {
        stateOptions.value = res.data.data;
        isState.value = false;
    });
}
function callForCity(data) {
    isCity.value = true;
    store.dispatch(`getcities`, data.state_id).then((res) => {
        cityOptions.value = res.data.data;
        isCity.value = false;
    });
}
// function callForPincode(data) {
    //     isPincode.value = true;
    //     pincodeData.value['country_id'] = selectCountry.value.country_id;
    //     pincodeData.value['state_id'] = selectState.value.state_id;
    //     pincodeData.value['city_id'] = data.city_id;
    //     store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
    //         pincodeOptions.value = res.data.data;
    //         isPincode.value = false;
    //     });
    // }
const emit = defineEmits(['filterZilaParishad'])

const country_state_city_id = ref({});
const emitter = inject('emitter'); // Inject `emitter`
function selectCityEvent() {
    console.log(selectCity.value)
    emit('filterZilaParishad');  // close search component
    country_state_city_id.value = {
        'country_id' : selectCountry.value.country_id,
        'state_id' : selectState.value.state_id,
        'city_id' : selectCity.value.city_id
    }
    store.commit(`COUNTRY_STATE_CITY_ID`, country_state_city_id.value);   // commit country state
    store.commit(`CITY_DETAIL`, selectCity.value);
    window.showAlertMessage('City selected successfully.', 'success');
    emitter.emit('refreshZilaPanchayatList');
}
</script>
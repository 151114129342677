<template>
    <div class="row layout-top-spacing">
        <div id="fs2Basic" class="col-lg-12">
            <div class="statbox panel box box-shadow">
                <div class="panel-heading">
                    <h4 v-if="route.path.split('/')[2] == 'location'">Global Location Settings</h4>
                    <h4 v-else-if="route.name == 'member-list' || route.name == 'village-list' || route.name == 'panchayat-list'">Filter <span v-if="$can('member.export') || $can('village.export') || $can('panchayat.export')">& Export Data</span></h4>
                    <h4 v-else-if="route.name == 'settings-roles'">Roles Filter</h4>
                    <h4 v-else>Select Pincode</h4>
                </div>
                <div class="panel-body select2">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Country</label>
                                <multiselect v-model="selectCountry" :clear-on-select="true" :options="countryOptions" label="country"
                                    :searchable="true" :preselect-first="true" selected-label=""
                                    placeholder="Search..." :loading="isCountry" select-label="" deselect-label=""
                                    @select="callForState($event)"></multiselect>
                                <!-- <vue-feather v-if="isCountry" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather> -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>State</label>
                                <multiselect v-model="selectState" :disabled="stateOptions.length == 0"
                                    :options="stateOptions" label="state" :searchable="true" :preselect-first="true"
                                    selected-label="" placeholder="Search..." select-label="" deselect-label=""
                                    @select="callForCity($event)" :loading="isState"></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>City</label>
                            <multiselect v-model="selectCity" :disabled="cityOptions.length == 0" :options="cityOptions"
                                label="city" :searchable="true" :preselect-first="true" selected-label=""
                                placeholder="Search..." select-label="" :loading="isCity" deselect-label=""
                                @select="callForPincode($event)"></multiselect>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pincode</label>
                                <multiselect v-model="selectPincode" :disabled="pincodeOptions.length == 0" :options="pincodeOptions" 
                                    label="postal_code" :searchable="true" :preselect-first="true" selected-label=""
                                    @select="callForVillage($event)" :loading="isPincode" select-label="" deselect-label=""></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="route.name == 'member-list' || route.name == 'settings-roles' || route.name == 'panchayat-list'">
                            <div class="form-group">
                                <label>Village</label>
                                <multiselect v-model="selectvillage" :disabled="villageOptions.length == 0" :options="villageOptions" 
                                    label="village" :searchable="true" :preselect-first="true" selected-label=""
                                    :loading="isVillage" select-label="" deselect-label=""></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="route.name == 'settings-roles'">
                            <div class="form-group">
                                <label>Roles</label>
                                <multiselect v-model="selectRoles" :multiple="true" :disabled="rolesOptions.length == 0" track-by="role" :options="rolesOptions"
                                    label="role" :searchable="true" :preselect-first="true" selected-label=""
                                    placeholder="" :loading="isRoles" select-label="" deselect-label="">
                                </multiselect>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="route.name == 'settings-roles'">
                            <div class="form-group">
                                <label>Panchayat Type</label>
                                <multiselect v-model="selectPanchType" track-by="title" :options="panchTypeOptions"
                                    label="title" :searchable="true" :preselect-first="true" selected-label=""
                                    placeholder="" select-label="" deselect-label="">
                                </multiselect>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-4 col-sm-4 col-4 my-auto">
                            <label for=""></label>
                            <div v-if="route.path.split('/')[2] == 'location'" class="">
                                <button tyle="button" :disabled="!Object.keys(selectCountry).length != 0" @click="saveGlobalSettings" class="btn btn-primary m-1"> SELECT</button>
                                <button tyle="button" :disabled="!Object.keys(preSelectLocationData.country).length != 0" @click="resetGlobalSettings" class="btn btn-danger m-1"> RESET</button>
                            </div>
                            <div v-else-if="route.name == 'member-list' || route.name == 'village-list' || route.name == 'panchayat-list'">
                                <button tyle="button" :disabled="Object.keys(selectCountry).length == 0" @click="exportFilterEvent('filter')" class="btn btn-primary m-1">FILTER</button>
                                <button tyle="button" v-if="$can('member.export') || $can('village.export') || $can('panchayat.export')" :disabled="Object.keys(selectState).length == 0" @click="exportFilterEvent('export')" class="btn btn-primary m-1">EXPORT</button>
                                <button tyle="button" :disabled="Object.keys(selectCountry).length == 0" @click="exportFilterEvent('reset')" class="btn btn-danger m-1">RESET</button>
                            </div>
                            <div v-else-if="route.name == 'settings-roles'">
                                <button tyle="button" :disabled="Object.keys(selectCountry).length == 0 && Object.keys(selectPanchType).length == 0 && Object.keys(selectRoles).length == 0" @click="exportFilterEvent('roles_filter')" class="btn btn-primary m-1">FILTER</button>
                                <button tyle="button" :disabled="Object.keys(selectCountry).length == 0 && Object.keys(selectPanchType).length == 0 && Object.keys(selectRoles).length == 0" @click="exportFilterEvent('reset')" class="btn btn-danger m-1">RESET</button>
                            </div>
                            <button tyle="button" v-else :disabled="Object.keys(selectPincode).length == 0" @click="selectPincodeEvent" class="btn btn-primary mt-3"> SELECT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed,inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { object } from 'yup';
const store = useStore();
const selectCountry = ref({});
const selectState = ref({});
const selectCity = ref({});
const selectPincode = ref({})
const selectvillage = ref({})
const selectRoles = ref([])
const selectPanchType = ref({})

const router = useRouter();
const route = useRoute();
const countryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);
const villageOptions = ref([]);
const rolesOptions = ref([]);
const panchTypeOptions = ref([
    { title:'All' , value: 0 },    
    { title:'Gram Panchayat' , value: 1 },    
    { title:'Samiti Panchayat' , value: 2 }, 
    { title:'ZilaParishad Panchayat' , value:3 },
    { title:'Regional Panchayat' , value:4 },
    { title:'State Panchayat' , value: 5 }
]);
const isCountry = ref(false);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);
const isVillage = ref(false);
const isRoles = ref(false);

const emit = defineEmits(['filterSamiti','setCountry','setState','setCity','setPincode'])
const emitter = inject('emitter'); // Inject `emitter`

onMounted(() => {
    console.log(route)
    isCountry.value = true;
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        countryOptions.value = res.data.data;
        if(route.path.split('/')[2] == 'location'){
            // emitter.emit('setCountry',{countryData:countryOptions.value});
        }
    });
    if(route.path.split('/')[2] == 'location'){
    }
    isRoles.value = true;
    store.dispatch('getRoles').then((res) => {
        console.log(res)
        isRoles.value = false;
        rolesOptions.value = res.data.data;
    });
    globalSettings();  // call for setup location
});

const preSelectLocationData = computed(()=>{
    return store.state.settings.savedGlobalSettings;
});

function globalSettings(){
    console.log(preSelectLocationData.value.pincode)
    if(Object.keys(preSelectLocationData.value.country).length != 0){
        selectCountry.value = preSelectLocationData.value.country.country_id;
        stateOptions.value = preSelectLocationData.value.country.stateData;
    }
    if(Object.keys(preSelectLocationData.value.state).length != 0){
        selectState.value = preSelectLocationData.value.state.state_id;
        cityOptions.value = preSelectLocationData.value.state.cityData;
    }
    if(Object.keys(preSelectLocationData.value.city).length != 0){
        selectCity.value = preSelectLocationData.value.city.city_id;
        pincodeOptions.value = preSelectLocationData.value.city.pincodeData;
    }
    if(Object.keys(preSelectLocationData.value.pincode).length != 0){
        selectPincode.value = preSelectLocationData.value.pincode.pincode_id;
    }
};

function callForState(data) {
    console.log(data)
    isState.value = true;
    selectState.value = {}
    selectCity.value = {};
    selectPincode.value = {};
    selectvillage.value = {};
    store.dispatch(`getState`, data.country_id).then((res) => {
        stateOptions.value = res.data.data;
        isState.value = false;
        if(route.path.split('/')[2] == 'location'){
            emitter.emit('setCountry',{country_id:data,stateData:stateOptions.value});
        }
    });
}
function callForCity(data) {
    isCity.value = true;
    selectCity.value = {};
    selectPincode.value = {};
    selectvillage.value = {};
    store.dispatch(`getcities`, data.state_id).then((res) => {
        cityOptions.value = res.data.data;
        isCity.value = false;
        if(route.path.split('/')[2] == 'location'){
            emitter.emit('setState',{state_id:data,cityData:cityOptions.value});
        }
    });
}
const pincodeData = ref({});
function callForPincode(data) {
    isPincode.value = true;
    selectPincode.value = {};
    selectvillage.value = {};
    pincodeData.value['country_id'] = selectCountry.value.country_id;
    pincodeData.value['state_id'] = selectState.value.state_id;
    pincodeData.value['city_id'] = data.city_id;
    store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
        pincodeOptions.value = res.data.data;
        isPincode.value = false;
        if(route.path.split('/')[2] == 'location'){
            emitter.emit('setCity',{city_id:data,pincodeData:pincodeOptions.value});
        }
    });
}


function callForVillage(data) {
    console.log(route)
    if(route.path.split('/')[2] == 'location'){
        emitter.emit('setPincode',{pincode_id:data});
    }
    if(route.name == 'member-list' || route.name == 'settings-roles' || route.name == 'panchayat-list'){
        pincodeVillageData(data);
    }
}

function pincodeVillageData(data) {
    console.log(data)
    selectvillage.value = {};
    isVillage.value = true;
    store.dispatch(`getVillageOfPincode`, data).then((res) => {
        console.log(res.data)
        villageOptions.value = res.data.data;
        isVillage.value = false;
    });
}

function selectPincodeEvent() {
    console.log(selectPincode.value)
    emit('filterSamiti');  // close search component
    pincodeData.value['postal_code'] = selectPincode.value.postal_code;
    store.commit(`PINCODE_COUNTRY_STATE_CITY`, pincodeData.value);   // commit country state city pincode
    store.commit(`POSTAL_CODE`, selectPincode.value);   // for punchayat create
    window.showAlertMessage('Pincode selected successfully.', 'success');
    emitter.emit('refreshPanchayatSamitiList');
    // if(route.path != '/panchayat/create'){
    //     router.push({ name: 'village-edit', params: { id: selectPincode.value } }); 
    // }
}
function saveGlobalSettings(){
    console.log('saved')
    store.commit('SAVE_GLOBAL_CONFIG_SETTING');
    window.showAlertMessage('Globally settings saved successfully.', 'success');
}
function resetGlobalSettings(){
    new window.Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Reset',
      confirmButtonColor: '#e7515a',
      focusCancel: true,
      padding: '2em',
    }).then((result) => {
      if (result.value) {
            store.commit('RESET_GLOBAL_CONFIG_SETTING');
            selectCountry.value = {};
            selectState.value = {};
            selectCity.value = {};
            selectPincode.value = {};
            window.showAlertMessage('Globally settings reset successfully.', 'success');
      }
    });
}

const exportKeysData = ref({})
function exportFilterEvent(type) {
    console.log('fff',selectCity.value)
    if(Object.keys(selectCountry.value).length != 0){
        exportKeysData.value['country_id'] = selectCountry.value.country_id;
    } else {
        exportKeysData.value['country_id'] = "";
    }
    if(Object.keys(selectState.value).length != 0){
        exportKeysData.value['state_id'] = selectState.value.state_id;
    } else {
        exportKeysData.value['state_id'] = "";
    }
    if(Object.keys(selectCity.value).length != 0){
        exportKeysData.value['city_id'] = selectCity.value.city_id;
    } else {
        exportKeysData.value['city_id'] = "";
    }
    if(Object.keys(selectPincode.value).length != 0){
        exportKeysData.value['postal'] = selectPincode.value.postal_code;
    } else {
        exportKeysData.value['postal'] = "";
    }
    if(Object.keys(selectvillage.value).length != 0){
        exportKeysData.value['v_id'] = selectvillage.value.id;
    } else {
        exportKeysData.value['v_id'] = "";
    }
    if(Object.keys(selectRoles.value).length != 0){
        exportKeysData.value['roles'] = selectRoles.value.map((obj)=>{
            return obj.roleId;
        });
    } else {
        exportKeysData.value['roles'] = [];
    }
    if(Object.keys(selectPanchType.value).length != 0){
        exportKeysData.value['panch_type'] = selectPanchType.value.value;
    } else {
        exportKeysData.value['panch_type'] = '';
    }
    if(type == 'export'){
        delete exportKeysData.value['roles'];
        emitter.emit('exportList',exportKeysData.value);
    }
    if(type == 'filter'){
        delete exportKeysData.value['roles'];
        emitter.emit('filterList',exportKeysData.value);
    }
    if(type == 'roles_filter'){
        console.log('exportKeysData',exportKeysData.value)
        emitter.emit('rolesfilter',exportKeysData.value);
    }
    if(type == 'reset'){
        exportKeysData.value = {
            country_id : "",
            state_id : "",
            city_id : "",
            postal : "",
            v_id : "",
            roles : [],
            panch_type : ""
        }
        selectCountry.value = {};
        selectState.value = {};
        selectCity.value = {};
        selectPincode.value = {};
        selectvillage.value = {};
        selectRoles.value = [];
        selectPanchType.value = {};
        emitter.emit('filterList',exportKeysData.value);
        if(route.name == 'settings-roles'){
            console.log('exportKeysData',exportKeysData.value)
            emitter.emit('rolesfilter',exportKeysData.value);
        }
    }
}
</script>
<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Service</a></li>
                                <li class="breadcrumb-item"><a href="javascript:;">Education</a></li>
                                <li class="breadcrumb-item"><a href="javascript:;">Education 1</a></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>
        <div class="row layout-top-spacing">
            <div id="basic" class="col-lg-12 layout-spacing">
                <div class="panel lyc">
                    <div class="panel-body">
                        <h4>Form</h4>
                        <div class="row">
                            <div class="col-md-12">
                                <Form @submit="onSubmit" :initial-values="initialData" :validation-schema="schemas" keep-values
                                    v-slot="{ handleSubmit, values }">
                                    <div class="row align-center">
                                        <TextInput name="name" required="required" label="Name" type="text" :error="errors.name" placeholder="" />
                                        <TextInput name="father_name" required="required" :error="errors.father_name" label="Father Name" type="text" placeholder="" />
                                        <TextInput name="mother_name" required="required" :error="errors.mother_name" label="Mother Name" type="text" placeholder="" />
                                        <TextInput name="age" required="required" :error="errors.age"  label="Age" type="text" placeholder="" />
                                        <TextInput name="mobile" required="required" :error="errors.mobile"  label="Mobile" type="text" placeholder="" />
                                        <TextInput name="qualification" required="required" :error="errors.qualification"  label="Qualification" type="text" placeholder="" />
                
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="action_btn">
                                                <span v-if="!$route.params.id">
                                                    <button  :disabled="isLoading" type="submit" class="btn btn-primary">
                                                        <span v-if="isLoading"
                                                            class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                                        Save
                                                    </button>
                                                </span>
                                                <span v-else>
                                                    <button :disabled="isLoading" type="submit" class="btn btn-primary">
                                                        <span v-if="isLoading"
                                                            class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                                        Update
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <pre>{{ values }}</pre> -->
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import '@/assets/sass/scrollspyNav.scss';
import TextInput from '../components/TextInput.vue'

import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
useMeta({ title: 'Request Service' });

import { useRoute, useRouter } from 'vue-router';

const store = useStore();
const route = useRoute();
const isLoading = ref(false);
const errors = ref({});

const queryParam = route.query;
onMounted(() => {
});
const initialData = ref({
    name: null,
    father_name: null,
    mother_name: null,
    age: null,
    qualification: null,
    mobile: null,
})

const schemas = yup.object().shape({
    name: yup.string().nullable().required('This field is required.'),
    father_name: yup.string().nullable().required('This field is required.'),
    mother_name: yup.string().nullable().required('This field is required.'),
    age: yup.string().nullable().required('This field is required.'),
    qualification: yup.string().nullable().required('This field is required.'),
    mobile: yup.string().nullable().required('This field is required.'),
});
function onSubmit(values) {
    isLoading.value = true;
    console.log(param_id.value)
    if (param_id.value) {
        values['id'] = param_id.value;
        store.dispatch('updateVillage', values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                handleReset();
                window.showAlertMessage(response.data.message, 'success');
                // router.push({ path: '/village/list' });
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('saveVillage', values).then((response) => {
            isLoading.value = false;
            console.log(response)
            if (response.status == 200) {
                handleReset()
                window.showAlertMessage(response.data.message, 'success');
                initialData.value = response.data.data;
                saveVillageDetails(response.data.data);
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};
</script>
<style>
.request_services{
    margin:20px 0px;
}
.request_services ul li{
    margin: 10px 0px;
    font-size: 16px;
    list-style: circle;
}
</style>
  
<template>
    <!-- {{ initialData }} -->
    <div class="panel-body p-0 mb-3">
        <div class="row" v-if="$route.params.id">
            <div class="col-md-12 hide-show-location">
                <!-- <button @click="toggleUpdate" class="btn btn-primary">Form &nbsp; <vue-feather :type="!UpdateShow?'eye':'eye-off'" size="11"></vue-feather> </button> -->
                <!-- <router-link class="btn btn-outline-primary mx-1" to="/village/create" tag="button">
                    <vue-feather type="plus" size="18"></vue-feather> Create
                </router-link> -->
            </div>
        </div>
        <Form v-show="UpdateShow" @submit="onSubmit" :key="componentKey" :initial-values="initialData" :validation-schema="schemas" keep-values
            v-slot="{ handleSubmit, values }">
            <div class="row align-center">
                <div class="col-md-12" v-if="!$route.params.id">
                    <i class="search_state_pin">Select State | City by Pincode</i>
                    <hr>
                </div>
                <div class="col-md-4">
                   <div class="form-group">
                    <label for="Country">Country <span class="required"></span></label>
                    <Field id="Country" name="country_id" @change="callForState(values.country_id)" class="form-control" label="Select Country" as="select">
                        <option :value="null" disabled selected>Select Country</option>
                        <option v-for="item in CountryOptions" :value="item.country_id">{{ item.country }}</option>
                    </Field>
                    <vue-feather v-if="isCountry" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                    <ErrorMessage class="error_alert" name="country_id"/>
                   </div>
                </div>
                <div class="col-md-4" v-if="!$route.params.id">
                    <div class="form-group">
                        <label style="margin-top:3px;">Search Pincode</label>
                        <div class="d-flex">
                            <input type="number" :disabled="!values.country_id" v-model="searchPincode" class="form-control" style="border-radius: 6px 0px 0px 6px;">
                            <button type="button" class="btn btn-dark" :disabled="!searchPincode" @click="searchPincodeFun(values.country_id)" style="border-radius: 0px 6px 6px 0px;">
                                <vue-feather v-if="isPostalCodeResponse" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                                <mdicon name="magnify" v-else />
                            </button>
                        </div>
                        <p v-if="isPincodeError" class="text-warning">{{ pincodeErrorMsg.includes('not')?pincodeErrorMsg:'' }}</p>
                    </div>
                </div>
                <div class="col-md-4" v-if="!$route.params.id"></div>
                <div class="col-md-12" v-if="!$route.params.id">
                    <i class="search_state_pin">Select State | City Manually</i>
                    <hr>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="State">State <span class="required"></span></label>
                    <Field name="state_id" id="State" @change="callForCity(values.state_id)" :disabled="stateOptions.length == 0" class="form-control" label="Select State" as="select">
                        <option :value="null" disabled selected>Select State</option>
                        <option v-for="item in stateOptions" :value="item.state_id">{{ item.state }}</option>
                    </Field>
                    <vue-feather v-if="isState" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                    <ErrorMessage class="error_alert" name="state_id"/>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="city_id">City <span class="required"></span></label>
                    <Field name="city_id" id="city_id" @change="callForPincode(values)" :disabled="cityOptions.length == 0" class="form-control" label="Select State" as="select">
                        <option :value="null" disabled selected>Select City</option>
                        <option v-for="item in cityOptions" :value="item.city_id">{{ item.city }}</option>
                    </Field>
                    <vue-feather v-if="isCity" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                    <ErrorMessage class="error_alert" name="city_id"/>
                </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="State">Postal code <span class="required"></span></label>
                        <Field name="postal_code"  id="State" :disabled="pincodeOptions.length == 0" class="form-control" label="Select State" as="select">
                            <option :value="null" disabled selected>Select Pincode</option>
                            <option v-for="item in pincodeOptions" :value="item.postal_code">{{ item.postal_code }}</option>
                        </Field>
                        <vue-feather v-if="isPincode" type="rotate-cw" animation="spin" animation-speed="fast"></vue-feather>
                        <ErrorMessage class="error_alert" name="postal_code"/>
                    </div>
                </div>
                
                <!-- <TextInput name="postal_code" required="required" label="Postal Code" type="number" :error="errors.postal_code"
                    placeholder="" /> -->
                <TextInput name="village" required="required" label="Village" type="text" :error="errors.village" placeholder="" />
                <TextInput name="address_detail" :error="errors.address_detail" label="Address" type="text"
                    placeholder="" />
                <TextInput name="location_mark" required="" :error="errors.location_mark" label="Location Mark" type="text"
                    placeholder="" />

                <TextInput name="administrative_block" v-show="param_id" :error="errors.administrative_block" label="Administrative Block"
                    type="text" placeholder="" />
                <TextInput name="sub_administrative_block" v-show="param_id" :error="errors.sub_administrative_block"
                    label="Sub Administrative Block" type="text" placeholder="" />
                <TextInput name="longitude" required="" v-show="param_id" label="Longitude" type="number"
                    :error="errors.longitude" placeholder="" />
                <TextInput name="latitude" required="" v-show="param_id" label="Latitude" type="number"
                    :error="errors.latitude" placeholder="" />
                <TextInput name="branch_name" required="" v-show="param_id" label="Branch Name" type="text"
                    :error="errors.branch_name" placeholder="" />
                <TextInput name="branch_type" required="" v-show="param_id" label="Branch Type" type="text"
                    :error="errors.branch_type" placeholder="" />
            </div>
            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <div class="action_btn">
                        <!-- <router-link :to="{ name: 'village-list', params: { params: {} } }" type="button"
                            class="btn btn-danger">{{ $t('Back') }}</router-link> -->
                        <span v-if="!$route.params.id">
                            <button v-if="$can('village.create')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                <span v-if="isLoading"
                                    class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                Add Village
                            </button>
                        </span>
                        <span v-else>
                            <button v-if="$can('village.update')" :disabled="isLoading" type="submit" class="btn btn-primary">
                                <span v-if="isLoading"
                                    class="spinner-border text-white mx-2 align-self-center loader-sm">Loading...</span>
                                Update Village
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <!-- <pre>{{ values }}</pre> -->
        </Form>
        <!-- <div class="row">
            <div class="col-md-6">
                <div class="alert alert-light-primary alert-dismissible border-0 mb-4" role="alert">
                    <strong>Village successfully created.</strong> Now you can add members into it.
                    <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">×</button>
                </div>
            </div>
        </div> -->
    </div>
</template>
  
<script setup>
import TextInput from '../components/TextInput.vue'
import SelectInput from '../components/SelectInput.vue'
import { ref, reactive, computed, onBeforeMount, watch ,inject,onBeforeUnmount} from 'vue';
import '@/assets/sass/scrollspyNav.scss';
// import highlight from '@/components/plugins/highlight.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { useMeta } from '@/composables/use-meta';
import * as yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const router = useRouter();
const UpdateShow = ref(true)
const $loading = useLoading({
    // options
});
useMeta({ title: 'Forms' });
const isLoading = ref(false);
const param_id = ref('');
const errors = ref({});
const componentKey = ref(0);

const CountryOptions = ref([]);
const stateOptions = ref([]);
const cityOptions = ref([]);
const pincodeOptions = ref([]);

const isCountry = ref(false);
const isState = ref(false);
const isCity = ref(false);
const isPincode = ref(false);

const searchPincode = ref('');
const pincodeErrorMsg = ref('')
const isPostalCodeResponse = ref(false);
const isPincodeError = ref(false);

const emitter = inject('emitter');   // Inject `emitter`
emitter.on('resetSingleVillageFormData', () => {   // *Listen* for event
    stateOptions.value = [];
    cityOptions.value = [];
    pincodeOptions.value = [];
});

onBeforeUnmount(() => {
    emitter.off('resetSingleVillageFormData');
});

const initialData = ref({
    village: null,
    administrative_block: null,
    address_detail: null,
    sub_administrative_block: null,
    location_mark: null,
    postal_code: null,
    country_id: null,
    state_id: null,
    city_id: null,
    city: null,
    longitude:null,
    latitude:null,
    branch_name:null,
    branch_type:null,
})

const schemas = yup.object().shape({
    country_id: yup.string().nullable().required('This field is required.'),
    state_id: yup.string().nullable().required('This field is required.'),
    city_id: yup.string().nullable().required('This field is required.'),
    postal_code: yup.string().nullable().required('This field is required.'),
    village: yup.string().nullable().required('This field is required.'),
});


onBeforeMount(() => {
    const route = useRoute();
    const queryParam = route.params.id;
    if (queryParam !== undefined) {
        param_id.value = queryParam;
        get_village(queryParam);
    }
    isCountry.value = true;
    callForCountry();
});
watch(()=>route.params.id, ()=> {
    console.log(route.params.id)    
    if(route.params.id == undefined){
        store.commit(`SET_VILLAGE_DETAILS`, {});
        param_id.value = '';
        initialData.value = {};
        componentKey.value += 1;
    }else{
        param_id.value = route.params.id;
        get_village(route.params.id);
    }
});
function toggleUpdate(){
    UpdateShow.value = !UpdateShow.value
}
const pincodeData = ref({});

function searchPincodeFun(country_id){
    console.log(searchPincode.value)
    isPostalCodeResponse.value = true;
    store.dispatch(`getPincodeRelatedData`, {country:country_id,postal:searchPincode.value}).then((res) => {
        console.log(res.data)
        isPostalCodeResponse.value = false;
        if(res.data.message){
            isPincodeError.value = true;
            pincodeErrorMsg.value = res.data.message;
            setTimeout(()=>{
            isPincodeError.value = false;
            },3000)
        }
        if(res.status == 200){
            stateOptions.value = res.data.states;
            cityOptions.value = res.data.cities;
            pincodeOptions.value = res.data.postalcodes;

            if(Object.keys(res.data.is_state).length !=0){
                initialData.value.state_id = res.data.is_state.id;
            }
            if(Object.keys(res.data.is_city).length !=0){
                initialData.value.city_id = res.data.is_city.id;
            }
            initialData.value.postal_code = res.data.is_postal_code;
            // selectVillage.value = res.data.is_village;
            componentKey.value += 1;
        }
    });
}
const get_village = async (id) => {
    const loader = $loading.show({
        loader: 'spinner',
        zIndex: 999,
        width:40
    });
    store.dispatch('editVillage', { id: id }).then((response) => {
        loader.hide();
        if (response.status == 200) {
            console.log(response.data.city_id)
            initialData.value = response.data;
            if(callForState(response.data.country_id)){
                initialData.value.state_id = response.data.state_id;
            }
            if(callForCity(response.data.state_id)){
                initialData.value.city_id = response.data.city_id;
            }
            if(callForPincode(response.data)){
                initialData.value.postal_code = response.data.postal_code;
            }
            setTimeout(() => {
                componentKey.value += 1;
            }, 100);
        } 
    });
};

function onSubmit(values) {
    isLoading.value = true;
    console.log(param_id.value)
    if (param_id.value) {
        values['id'] = param_id.value;
        store.dispatch('updateVillage', values).then((response) => {
            isLoading.value = false;
            if (response.status == 200) {
                handleReset();
                window.showAlertMessage(response.data.message, 'success');
                // router.push({ path: '/village/list' });
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    } else {
        store.dispatch('saveVillage', values).then((response) => {
            isLoading.value = false;
            console.log(response)
            if (response.status == 200) {
                handleReset()
                window.showAlertMessage(response.data.message, 'success');
                initialData.value = response.data.data;
                saveVillageDetails(response.data.data);
            } else {
                errors.value = response.data.errors;
                window.showAlertMessage(response.data.message, 'warning');
            }
        });
    }
};

function saveVillageDetails(data) {
    new window.Swal({
    // title: 'Are you sure?',
    html: "Village <b>"+data.village+"</b> for postalcode <b>"+data.postal_code+"</b> and country <b>"+data.country+"</b> created successfully.For adding members, and creating Gram Panchayat press Yes, otherwise No",
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    focusCancel: true,
    padding: '2em',
    }).then((result) => {
        if (result.value) {
            store.commit('SET_VILLAGE_DETAILS',data);
            router.push({ name: 'village-edit', params: { id: data.id } });
            console.log('created')
        }else{
            store.commit('SET_VILLAGE_DETAILS',{});
            initialData.value = {};
            componentKey.value += 1;
            console.log('noCreated')
        }
    });
};

function callForCountry(){
    store.dispatch('getCountries').then((res) => {
        isCountry.value = false;
        CountryOptions.value = res.data.data;
    });
}
function callForState(country_id) {    // country input
    isState.value = true;
    store.dispatch(`getState`, country_id).then((res) => {
        isState.value = false;
        if(res.status == 200){
            // cityOptions.value = [];
            // pincodeOptions.value = [];
            stateOptions.value = res.data.data;

            // initialData.value.state_id = null;
            // initialData.value.city_id = null;
            // initialData.value.postal_code = null;
            // initialData.value.village = null;
            componentKey.value += 1;
            initialData.value.country_id = country_id;
            return true;
        }
        
    });
}
function callForCity(state_id) {   // state input
    isCity.value = true;
    store.dispatch(`getcities`, state_id).then((res) => {
        isCity.value = false;
        if(res.status == 200){
            cityOptions.value = res.data.data;
            // pincodeOptions.value = [];

            // initialData.value.postal_code = null;
            // initialData.value.village = null;
            // componentKey.value += 1;
            // initialData.value.state_id = state_id;
            return true;
        }
    });
}
function callForPincode(data) {   // city input
    console.log(data)
    isPincode.value = true;
    pincodeData.value['country_id'] = data.country_id;
    pincodeData.value['state_id'] = data.state_id;
    pincodeData.value['city_id'] = data.city_id;
    store.dispatch(`getSearchPincode`, pincodeData.value).then((res) => {
        console.log(res)
        isPincode.value = false;
        if(res.status == 200){
            pincodeOptions.value = res.data.data;
            // initialData.value.postal_code = null;
            // componentKey.value += 1;
            initialData.value.city_id = data.city_id;
        }
    });
}

function handleReset(){
    errors.value = [];
}
</script>
<style scoped>
.action_btn {
    display: flex;
    justify-content: flex-end;
}

.action_btn button {
    margin-left: 10px;
}

.form-part {
    margin: 0px 0px 20px
}

.form-part h6 {
    font-weight: bold;
}

.panel .panel-heading h4 {
    font-size: 21px;
}
.hide-show-location{
    text-align: right;
    margin-bottom: 10px;
}
</style>
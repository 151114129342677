<template>
    <div class="container vl-parent" >
        <div class="row layout-top-spacing">
            <loading v-model:active="latestMemberTableLoading" :is-full-page="false" loader="spinner" :width="35" :z-index="9" />
            <div id="tableSimple" class="col-lg-12 col-12 layout-spacing">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                                <h4>Latest Members</h4>
                            </div>
                        </div>
                    </div>
                    <div class="panel-body" id="my-div">
                        <div class="table-responsive">
                            <table role="table" aria-busy="false" aria-colcount="5" class="table table-bordered" :append-to="'#my-div'" id="__BVID__415">
                                <thead role="rowgroup">
                                    <tr role="row">
                                        <th>Name</th>
                                        <th>Roles</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Gender</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup" v-if="getLatestMember.length != 0">
                                    <tr v-for="item in getLatestMember" :key="item.name" role="row">
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <span v-if="item.roles.length != 0" v-for="role in  item.roles" :key="role.id">
                                                <span class="badge badge-light-success" style="margin:1px;">{{ capitalizeString(role.name) }}</span>
                                            </span>
                                            <span v-else>N/A</span>
                                        </td>
                                        <td>{{ item.email }}</td>
                                        <td>{{ item.mobile }}</td>
                                        <td v-if="item.gender == 'M'">Male</td>
                                        <td v-else>Female</td>
                                        <td class="text-center">
                                            <div class="action_button">
                                              <a v-if="checkSuperAdminRole" href="javascript:void(0);" title="Switch user" class="delete_button m-2"
                                                @click="switch_user(item)">
                                                <vue-feather v-if="switchUser[item.email]" type="rotate-cw" animation="spin" style="color:green" animation-speed="fast"></vue-feather>
                                                <mdicon name="account-switch" v-else style="color:green"/>
                                              </a>
                                                <router-link v-if="item.villages.length == 0" title="Attach Village" class="edit_button m-2" :to="{ name: 'create-member-multiple-location',query:{'member_id':item.id,'name':item.name} }">
                                                    <vue-feather type="map-pin" size="18" stroke="green"></vue-feather>
                                                </router-link>
                                                <!-- <a v-if="$can('uid.list')" data-bs-toggle="modal" @click="openUIDModel(item)" data-bs-target="#addArticleModal"
                                                    href="javascript:void(0);" title="UID" class="delete_button m-2">
                                                    <vue-feather type="sidebar" size="18" stroke="orange"></vue-feather>
                                                </a> -->
                                                <router-link v-if="$can('member.edit')" title="Edit" class="edit_button mx-1" :to="{ name: 'member-edit', params: { id: item.id, target:'self' } }">
                                                    <vue-feather type="edit" size="18" stroke="orange"></vue-feather>
                                                </router-link>
                                                <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                                                <a v-if="$can('member.delete')" href="javascript:void(0);" title="Delete" class="delete_button m-2" @click="delete_member([item.id])">
                                                    <vue-feather type="trash-2" size="18" stroke="red"></vue-feather>
                                                </a>
                                                <vue-feather v-else type="slash" size="18" class="mx-1" stroke="gray"></vue-feather>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else role="rowgroup">
                                    <tr role="row" style="text-align: center;">
                                        <td colspan="6">No matching records</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="addArticleModal" tabindex="-1" role="dialog" aria-labelledby="addArticleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl vl-parent" role="document">
              <div class="modal-content">
                <div class="modal-header border-bottom-0">
                  <h5 class="modal-title" id="addArticleModalLabel">Update UID</h5>
                  <button type="button" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                    id="articleModalCloseEvent" class="btn-close"></button>
                </div>
                <div class="modal-body">
                  <div>
                    <uid></uid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script setup>
    import uid from './uid.vue'
    import { onMounted, ref ,inject,onBeforeUnmount,computed} from 'vue';
    import '@/assets/sass/tables/table-basic.scss';
    import { useStore } from 'vuex';
    const store = useStore();
    const latestMemberTableLoading = ref(false);
    const getLatestMember = ref([]);
    const emitter = inject('emitter');   // Inject `emitter`
    const pagination = ref({
        "query": "",
        "limit": 5,
        "ascending": 1,
        "page": 1,
        "byColumn": 0,
        "direction": "desc",
        "orderBy": "id"
    });
    onMounted(() => {
        getLatest();
    });
    const checkSuperAdminRole = computed(()=>{
       if(store.state.auth.meDetails.roles.includes('super-admin') || store.state.auth.meDetails.roles.includes('admin')){
        return true;
      } else false;
    });
    // function openUIDModel(data) {
    //     store.commit('UID_DATA', data);
    // }
    emitter.on('memberCreated', (value) => {   // *Listen* for event
        getLatest();
      });
    onBeforeUnmount(() => {
      emitter.off('memberCreated');
    });
    function getLatest(){
        latestMemberTableLoading.value=true;
        store.dispatch(`getMemberList`, pagination.value).then((res) => {
            if(res.status == 200){
                getLatestMember.value= res.data.data;
                latestMemberTableLoading.value=false;
            }
      });
    }
    function delete_member(ids) {
    new window.Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      focusCancel: true,
      padding: '2em',
    }).then((result) => {
      if (result.value) {
        store.dispatch('deleteMember', {id:Object.values(ids)}).then((response) => {
          if (response.status == 200) {
            getLatest();
            window.showAlertMessage(response.data.message, 'success');
          } else {
            window.showAlertMessage(response.data.message, 'warning');
          }
        });
      }
    });
  };
  const switchUser = ref({});
  function switch_user(item) {
    var roles = item.roles.map((role)=>{
      return role.role;
    });
  new window.Swal({
    title: '',
    html: `Do you want to switch user? with ${(roles.length == 1)?'role':'roles'} <b>${roles}</b>`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Switch',
    focusCancel: true,
    padding: '2em',
  }).then((result) => {
    if (result.value) {
      switchUser.value[item.email] = true;
      store.dispatch('switchUserApi', { email:item.email }).then((response) => {
          console.log(response)
          switchUser.value[item.email] = false;
          window.showAlertMessage(response.data.message, 'success');
      }).catch((err)=>{
        switchUser.value[item.email] = false;
        window.showAlertMessage(err.response.data.message, 'warning');
      });
    }
  });
};
</script>
